import React from 'react';

import { paginate } from '../../helpers';
import { overviewLeftArrIcon, overviewRightArrIcon } from '../../helpers/IconList';

function Pagenation({ limit, total, page, change }: any) {
  // console.log('page', limit, total);
  if (limit >= total) return null;

  function localChange(c: any) {
    window.scrollTo(0, 0);
    change(c);
  }

  const all = paginate(total, page, limit, 5);
  const { startPage, endPage, currentPage, pages } = all; // totalPages, 
  const firstPageFlat = page === startPage;
  const lastPageFLat = page === endPage;
  return (
    <div className="overvieew-pagination">
      <ul>
        
        {!firstPageFlat && (
          <li title={`page ${startPage}`} className="leftCurve">
            <button onClick={() => {
              const prev = currentPage - 1;
              localChange(prev)
            }}>
              <img src={`${overviewLeftArrIcon}`} alt="prev" />
            </button>
          </li>
        )}
        {pages.map(p => (
          <li key={p} className={p === currentPage ? "a" : ''}>
            <button onClick={() => localChange(p)}>{p}</button>
          </li>
        ))}
        {!lastPageFLat && (
          <li title={`page ${endPage}`} className="rightCurve">
            <button onClick={() => {
              const next = currentPage + 1;
              localChange(next)
            }}>
              <img src={`${overviewRightArrIcon}`} alt="next" />
            </button>
          </li>
        )}
        {/* <li title={`page ${totalPages}`}>
          <button onClick={() => change(totalPages)}>&raquo;&raquo;</button>
        </li> */}
      </ul>
    </div>
  );
}
export default Pagenation;