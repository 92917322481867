import React from 'react';
import { LogosProp } from './shape';

const FlatLogo = ({
  _id,
  draw_value,
  element_id,
  height,
  width,
  x_pos,
  y_pos,
  visible,
  source,
  slideType
}: LogosProp) => {
  const style = {
    height,
    width,
    left: x_pos,
    top: y_pos
  };
  
  const style2 = {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  };

  /**
   * ! Discussion with develoopment team (2021/02/03)
   * ! -------------------------------------------
   * ! draw_value first if not source value is implement for image
   */
  const finalSoure = draw_value || source;
  return (
      <div className={`slider-bottom-logo absolute`} style={style}>
      {visible ? <img src={`${finalSoure}`} style={style2} alt={element_id} /> : null}
    </div>
  )
}
export default FlatLogo;