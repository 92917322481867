import React from 'react';
import SingleSelect from "./SingleSelect";

import { SingleSelectType } from '../../type';
import './singleselect.css';

const drawSingleSelect = (
  {
    label, name, val, sorts, change, direction = '', changedirection,
    stylename = '',
    language = ""
  }: SingleSelectType
) => (
    <SingleSelect
    stylename={stylename}
      label={label}
      name={name}
      val={val}
      lists={sorts}
      change={change}
      direction={direction}
      changedirection={changedirection}
      language={{ ...language}}
    />
  );
export default drawSingleSelect;