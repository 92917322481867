import React, { useContext } from 'react';
import lookContext from '../../context/LookContext';
import settingContext from '../../context/SettingContext';
import languageContext from '../../context/languageContext';
import Logo from './Logo';

interface LogoPanelProps {
  colorpick: (obj: any) => void;
  onPresentationLogoChange1?: any;
  onCustomerLogoChange1?: any;
}
const LogoPanel = function LogoBackground({
  colorpick,
  onPresentationLogoChange1,
  onCustomerLogoChange1,
}:LogoPanelProps) {
  const { presentationLogo, editorLogo }: any = useContext(lookContext);
  const language: any = useContext(languageContext);
  const {
    end_customer_logo,
    end_customer_logo_background,
    end_customer_logo_check,
    presentation_logo,
    presentation_logo_background,
    presentation_logo_check
  }:any = useContext(settingContext);

  // console.log('end_customer_logo', end_customer_logo);
  // console.log('presentation_logo', presentation_logo);

  return (
    <div className="setting__element">
      <div className="setting__element__wrap">
        {end_customer_logo.visible && (
          <Logo
            key={editorLogo.name}
            label={language.end_customer_logo_check}
            pass={editorLogo.name}
            color={editorLogo.background_color}
            checkStatus={editorLogo.visible}
            colorpick={colorpick}
            onLogoChange={(obj: any) => onCustomerLogoChange1(obj)}
            display={end_customer_logo_check}
            background={{ ...end_customer_logo_background, label: language.background_color}}
          />
        )}
        {presentation_logo.visible && (
          <Logo
            key={presentationLogo.name}
            label={language.presentation_logo_check}
            pass={presentationLogo.name}
            color={presentationLogo.background_color}
            checkStatus={presentationLogo.visible}
            colorpick={colorpick}
            onLogoChange={(obj: any) => onPresentationLogoChange1(obj)}
            display={presentation_logo_check}
            background={{...presentation_logo_background, label: language.background_color}}
          />
        )}
        
      </div>
    </div>
  );
};
export default LogoPanel;