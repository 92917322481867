import React from 'react'
import './toggleButton.css'

interface ToggleButtonType {
    flag: boolean;
    set: (flag: boolean) => void;
    fontStyle: any;
}
const ToggleButton = ({
    flag,
    set,
    fontStyle
}: ToggleButtonType) => {
    return (
        <div className='flex edittogglewrap'>
            <div className={flag ? 'edittoggle on' : 'edittoggle off'} role='button' onClick={() => set(!flag)}>
                <span></span>
            </div>
            <p className='p-edittoggle' style={fontStyle}>
                Include (delivery) charges in total price overview
            </p>
        </div>
    )
}
export default ToggleButton