import React, { Fragment, useContext, useEffect, useState } from 'react';
import lookAndFeelContext from '../../context/LookAndFeelContext';
import settingContext from '../../context/SettingContext';
import languageContext from '../../context/languageContext';
// import FontFunFunction from '../../helpers/FontFunFunction';

interface LookAndFeelProps {
  onLookAndFeelChange?: any;
  colorpick: (obj: any) => void;
}
interface fontmap {
  _id: any;
  font_family: string;
}
export default function LookAndFeel(props: LookAndFeelProps) {
  const { colorpick, onLookAndFeelChange } = props;
  const { templateColor, lookFeel, brandColor }: any = useContext(lookAndFeelContext); // fonts, baseUrl, 
  const language: any = useContext(languageContext);

  const { font_color, template_color } = lookFeel; // font_name,
  const [selected, setSelected] = useState(templateColor);
  useEffect(() => {
    setSelected(template_color);
  }, [template_color])
  const {
    template_color: temp_color,
    brand_color,
    font,
    pick_own_color = { visible: true }
  }: any = useContext(settingContext);

  const [brandList] = useState(brandColor);

  // const fontChange = (e: any) => {
  //   const { target: { value } } = e;
  //   const fontObj = fonts.find((f: any) => f.font_family === value)
  //   FontFunFunction({ ...fontObj, baseUrl }).then((response: any) => {
  //     if (response)
  //       onLookAndFeelChange({ type: 'font_name', value });
  //   });
  // }

  const colorChange = (value: any) => {
    onLookAndFeelChange({
      type: 'template_color',
      value
    });
    setSelected(value);
    // onLookAndFeelChange();
  };
  const pickColor = () => {
    colorpick({ color: template_color, name: 'look_and_feel' });
  };

  const pickColor2 = () => {
    colorpick({ color: font_color, name: 'font_color' });
  };

  const style = {
    background: `${template_color}`
  };
  const style2 = {
    background: `${font_color}`
  }

  return (
    <div className="setting__element lookandfeel">

      <div className="setting__element__wrap">
        {temp_color.visible && (
          <Fragment>
            <h3 className="setting__element__title">{language.template_color}</h3>
            <p>{language.template_color_description}</p>
            <section className="flex setting__element__color__list">
              {templateColor && templateColor.map(({ hex_value }: any) => {
                const matchCSS = selected === hex_value ? 'setting__element__color__bg selected' : 'setting__element__color__bg';
                return (
                  <span className={`${matchCSS}`} key={hex_value} style={{ background: hex_value }} onClick={() => colorChange(hex_value)} />
                )
              })}
            </section>
          </Fragment>
        )}

        {brand_color.visible && (
          <Fragment>
            <p>{language.brand_color_description}</p>
            <section className="flex setting__element__color__list">
              {brandList.map(({ hex_value }: any) => {
                const matchCSS = selected === hex_value ? 'setting__element__color__bg selected' : 'setting__element__color__bg';
                return (
                  <span className={`${matchCSS}`} key={hex_value} style={{ background: hex_value }} onClick={() => colorChange(hex_value)} />
                )
              })}
            </section>
          </Fragment>
        )}

        {pick_own_color.visible && (
          <div className="flex setting__element__color" onClick={pickColor}>
            <span className="setting__element__colorpicker" style={style}></span>
            <span style={{ flex: 1 }}>{language.pick_own_color}</span>
          </div>
        )}

        {font.visible && (
          <Fragment>
            {/* <h3 className="setting__element__title setting__divider">{language.font}</h3> */}
            {/* <div className="fontSelection__warp"> */}
              {/* 
                xd link
                font selection is removed
               */}
              {/* <select name="fontSelection" id="fontSelection" value={font_name} onChange={fontChange}>
                {fonts.length && fonts.map(({ _id, font_family }: fontmap) => (
                  <option value={font_family} key={_id}>{font_family}</option>
                ))}
              </select> */}
            <div className='setting__divider'>
              <h3 className="setting__element__title">{language.template_font_colour}</h3>
            </div>
            <div className="flex setting__element__color" onClick={pickColor2}>
              <span className="setting__element__colorpicker" style={style2}></span>
              <span style={{ flex: 1 }}>{"Pick font color"}</span>
            </div>
            {/* </div> */}
          </Fragment>
        )}

        {/* <div className="flex setting__element__color setting__divider pt25" onClick={pickColor}>
          <span className="setting__element__colorpicker" style={style}></span>{language.slide_background}
        </div> */}
      </div>
    </div>
  );
}
