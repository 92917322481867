import React, { useState, useEffect, useContext } from "react";
import languageContext from "../../context/languageContext";

interface TextLineProps {
    text: string;
    align: any;
    noWrapstyle?: any;
    textlengt?: any;
    fontsize?: any;
    slideType?:any;
}
const Textline = ({ text, align, noWrapstyle, textlengt, fontsize = {}, slideType }: TextLineProps) => {
    //translation for write_your_text_here
    const { write_your_text_here }: any = useContext(languageContext);
    const subTextStyle = { color: "#D3D3D3" }
    const [localtext, setlocaltext] = useState(text)
    useEffect(() => {setlocaltext(text)}, [text])
    const style = {
        textAlign: align ? align : "left",
        fontSize: "inherit",
        fontFamily: "inherit",
        color: "inherit",
        ...noWrapstyle,
        
    };

    const ell = localtext && localtext.length >= textlengt;
    const computelastdot = (str: string = '') => {
        const newText = str.substr(0, textlengt);
        const index = newText.lastIndexOf('.') + 1;
        if (index) {
            const finaltext = newText.slice(0, index);
            return finaltext;
        }
        return newText;
        // return str.substr(0, textlengt)
    }

    const newText = localtext
        ? localtext.split("\n").map((str, key) => (
            <span key={key}>
                {ell ? computelastdot(str) : str}
                {/* {ell ? '...' : ''} */}
            </span>
        ))
        : "";

    return (
        <React.Fragment>
            <p style={newText ? style : write_your_text_here ? { ...style, ...subTextStyle } : style}>{newText? newText : slideType==="image_slide"? write_your_text_here : ""}</p>
        </React.Fragment>
    );
};
export default Textline;
