import React, { useState, useContext, useEffect } from 'react';
import { cloneDeep } from 'lodash'
import lookAndFeelContext from '../../context/LookAndFeelContext';
import languageContext from '../../context/languageContext';

export default function SlideElement(props: any) {
    const { element, onElementChangge }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);

    const [localElement, setLocalElement] = useState(element);

    useEffect(() => {
        const newList = cloneDeep(element);
        setLocalElement(newList);
        return () => {
        }
    }, [element])

    return (
        <div className="setting__element slideElement">
            <div className="setting__element__wrap">
                <h4 className="slideElement__h4">{language.choose_elements}</h4>
                {localElement && Array.isArray(localElement) && localElement.map(({ _id, label, value, element_id }:any) => {
                    let languageTranslated = language[element_id] || label;
                    // Note: Fixed 
                    // issue with ecq slide DATE and global element Date checkbox
                    if (element_id === 'date_label')
                        languageTranslated = language['date_label_ecq'];
                    return (
                        <div key={_id} className="checkbox__wrapper">
                            <label
                                className="flex setting__element__logo check__container"
                                onMouseUp={() => {
                                    onElementChangge({
                                        _id, label, value
                                    });
                                }}
                                role="button"
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={value}
                                />
                                <span className="checkmark"></span>
                                {languageTranslated}
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}