import React, { useState, useEffect } from 'react'

const TipDisplay = ({ tiplist = [], language }: any) => {

    // console.log(language);
    const [currentTip, setCurrentTip] = useState('');

    useEffect(() => {
        // setTips(tiplist);
        
        if (tiplist.length > 0 && currentTip === '') {
            const text = language[tiplist[0].key] || '...';
            setCurrentTip(text);
        }

        const updateCurrentTip = () => {
            const randomIndex = Math.floor(Math.random() * tiplist.length);
            // console.log(tiplist[randomIndex].key)
            // console.log(language[tiplist[randomIndex].keyaa])
            const text = language ? language[tiplist[randomIndex].key] || '...' : '...';
            setCurrentTip(text);
        }

        const interval = setInterval(updateCurrentTip, 10000);

        return () => clearInterval(interval);
        
    }, [tiplist, language]);

    return <>{currentTip}</>
}

export default TipDisplay;

