import React, { useState, useEffect } from "react";
import {
    hexToRgb,
    rgbToCMYK,
    cmykToHex
} from '../../helpers';

const ColorPickerWrapper = ({ loccolor, onChangeComplete, children }: any) => {
    const [cc, setcc] = useState(0);
    const [mm, setmm] = useState(0);
    const [yy, setyy] = useState(0);
    const [kk, setkk] = useState(0);
    const [active,setactive] = useState(false);

    useEffect(() => {
        const CalcRGB = hexToRgb(loccolor);
        const [r, g, b] = CalcRGB;
        const { C, M, Y, K } = rgbToCMYK(r, g, b);
        if (!active) {
            setcc(C);
            setmm(M);
            setyy(Y);
            setkk(K);
        }
        
    }, [loccolor, active])

    const setInput = (setter:any) => (e:any) => {
        setactive(true);
        const { value, name } = e.target;
        let val: number = value * 1;
        
        if (val <= 100 && val >= 0) {
            let hex:any = '';
            switch (name) {
                case 'cc': {
                    hex = cmykToHex(val, mm, yy, kk)
                    setter(val);
                    break;
                }
                case 'mm': {
                    hex = cmykToHex(cc, val, yy, kk)
                    onChangeComplete({ hex })
                    setter(val);
                    break;
                }
                case 'yy': {
                    hex = cmykToHex(cc, mm, val, kk)
                    onChangeComplete({ hex })
                    setter(val);
                    break;
                }
                case 'kk': {
                    hex = cmykToHex(cc, mm, yy, val)
                    onChangeComplete({ hex })
                    setter(val);
                    break;
                }
                default:
                    setter(val);
                    break;
            }
            console.log(hex, { name, val, value });
        }
    };

    const handleBlur = () => {
        setactive(false);
    };

    return (
        <div className="cmykrelative">
            {children}
            <div className="fixcmyk">
                <div className="pos-relative">
                    <input name="cc" type="text" value={cc} onChange={setInput(setcc)} onBlur={handleBlur} />
                    <span>C</span>
                </div>
                <div className="pos-relative">
                    <input name="mm" type="text" value={mm} onChange={setInput(setmm)} onBlur={handleBlur} />
                    <span>M</span>
                </div>
                <div className="pos-relative">
                    <input name="yy" type="text" value={yy} onChange={setInput(setyy)} onBlur={handleBlur} />
                    <span>Y</span>
                </div>
                <div className="pos-relative">
                    <input name="kk" type="text" value={kk} onChange={setInput(setkk)} onBlur={handleBlur} />
                    <span>K</span>
                </div>
            </div>
        </div>
    )
}
export default ColorPickerWrapper;