import React, { useState, useEffect, useContext } from 'react';
import boardContext from '../../context/BoardContext';
// import templateContext from '../../context/LayoutContext';
import languageContext from '../../context/languageContext';
import TextLine from './TextLine';

import { TextonlyProps } from './shape';

const TextEditable = (props: TextonlyProps) => {
    let { _id, element_id, onSlideUpdate, draw_value, color, visible, font_size, height, width, x_pos, y_pos, text_align, font_weight, margin=0, default_value, productTempType } = props;
    let { allow_translation = true } = props;
    const checkAllowTranslationBool = props.hasOwnProperty('allow_translation');
    const translate: any = useContext(languageContext);

    let shortTextlengt = 85;
    const longTextlengt = 367; // 370 // 500; // 419;
    const [mode, setMode] = useState(false);
    const [textValue, setTextValue] = useState('');
    const context: any = useContext(boardContext);
    // const { templateId, template}: any = useContext(templateContext);
    // console.log(templateId, template)
    // template.map((tt:any) => {
    //     if (tt._id === templateId) {
    //         switch (tt.template_type) {
    //             case '4_products':
    //             case '1_product':
    //                 shortTextlengt = 85;
    //                 break;
    //             case '2_products':
    //                 shortTextlengt = 130;
    //                 break;
    //             case 'photo_layout':
    //                 shortTextlengt = 115;
    //                 break;
    //         }
    //     }
    //     return null;
    // })

    let excludePhotolayout: any = productTempType || '';
    // if (template.length) {
    //     excludePhotolayout = template.filter((x: any) => x._id === templateId)
    //     excludePhotolayout = excludePhotolayout[0]?.template_type || '';
    //     console.info(template, templateId);
    // }
    const translationvalue = translate[`${element_id}`] || draw_value;

    const calculateNetPriceWithMargin = React.useCallback(() => {
        /* function body */
        if (!allow_translation) {
            setTextValue(draw_value);
        } else if(draw_value && margin && default_value){
            const updatedNetMargin = (default_value / (1 - margin / 100)).toFixed(2).toString();
            const priceWithCurrency = formatPriceWithCurrency(draw_value,updatedNetMargin)
            setTextValue(priceWithCurrency);
        }else{
            setTextValue(draw_value)

        }
    }, [allow_translation, default_value, margin, draw_value]);


    useEffect(() => {
        const checkLongDescText = element_id === 'long_description_text';
        if(element_id==="price_text" && margin && default_value){
            calculateNetPriceWithMargin();
        } else if (
            checkLongDescText ||
            element_id === 'short_description_text' ||
            element_id === 'product_name'
        ) {
            const limit = checkLongDescText ? longTextlengt : shortTextlengt;
            const lognTextCheck = draw_value.length < limit
                ? draw_value
                : draw_value.substr(0, limit);
            if (checkLongDescText) {
                setTextValue(draw_value);
            } else {
                setTextValue(lognTextCheck);
            }
        } else {
            if (checkAllowTranslationBool && allow_translation) {
                setTextValue(translationvalue);
            } else {
                setTextValue(draw_value);
            }
        }
    }, [
        shortTextlengt,
        draw_value, element_id, allow_translation, translationvalue,
        checkAllowTranslationBool,
        calculateNetPriceWithMargin, default_value, margin
    ])

    useEffect(() => {
        if(element_id==="price_text"){
            calculateNetPriceWithMargin();
        }
    }, [element_id, calculateNetPriceWithMargin])

    function formatPriceWithCurrency(draw_value:any,updatedAmount:any){
        //If draw value is in ',' seperated amount then updating updatedAmount to include ','
        if (draw_value.includes(",")) {
            updatedAmount = updatedAmount.replace(".", ",");
        }
        const getAmount = draw_value.replace(/[^0-9.,]/g, '');
        return draw_value.replace(getAmount, updatedAmount);
    }

    // font_name,
    let fontFamily = '';
    let globalFontColor = '';
    let tempFontColor = '';
    if (context.presentationFont) {
        fontFamily = context.presentationFont;
        globalFontColor = context.look.font_color || '';
        tempFontColor = context.look.template_color || '';
    }

    if (!visible) return null;

    const doubleClick = () => {
        // textClick();
        setMode(true);
        // setTextValue(draw_value);
        if (draw_value !== null) {
            // const lognTextCheck = draw_value.length < longTextlengt
            //     ? draw_value
            //     : draw_value.substr(0, longTextlengt);
            // setTextValue(lognTextCheck);
            if (checkAllowTranslationBool && allow_translation) {
                const translationvalue = translate[`${element_id}`] || draw_value;
                setTextValue(translationvalue);
            } else {
                if (element_id === "price_text" && margin && default_value) {
                    calculateNetPriceWithMargin();
                } else {
                    setTextValue(draw_value);
                }
            }
        } else {
            setTextValue("");
        }
        setTimeout(function () {
            const ele = document.getElementById('texttext');
            ele?.focus();
        }, 200)
    }

    function onLeave() {
        if (draw_value !== textValue) {
            if (props.hasOwnProperty('allow_translation')) {
                onSlideUpdate([
                    { id: _id, type: 'draw_value', value: textValue },
                    { id: _id, type: 'allow_translation', value: false }
                ]);
            } else {
                if (element_id === "price_text") {
                    onSlideUpdate([
                        { id: _id, type: 'draw_value', value: textValue },
                        { id: _id, type: 'allow_translation', value: false }
                    ]);
                } else {
                    onSlideUpdate({ id: _id, type: 'draw_value', value: textValue });
                }
            }
        }
        setMode(false);
    }
    const focushere = (e: any) => {
        if (draw_value !== null) {
            e.target.selectionStart = draw_value.length;
            e.target.selectionEnd = `${draw_value.length}`;
        }
    }
    const handleChange = (e: any) => {
        const { target: { value } } = e;

        if (element_id === 'sku_info_text' && value.length < 10) {
            setTextValue(value);
        } else if (element_id === 'short_description_text' && value.length < shortTextlengt) {
            setTextValue(value)
            // } else if (element_id === 'long_description_text' && value.length < longTextlengt) {
        } else if (element_id === 'product_name' && value.length < shortTextlengt) {
            setTextValue(value)
            // } else if (element_id === 'long_description_text' && value.length < longTextlengt) {
        } else if (element_id === 'long_description_text') {
            setTextValue(value);
        } else if (element_id === 'price_text' && value.length < 75) {
            // change above length from 30 to 75
            setTextValue(value);
        }
        if (
            element_id !== 'price_text' &&
            element_id !== 'sku_info_text' &&
            element_id !== 'short_description_text' &&
            element_id !== 'long_description_text' &&
            element_id !== 'product_name'
        ) {
            setTextValue(value);
        }
    };

    let fontWeight = 400;
    if (font_weight && font_weight === "bold")
        fontWeight = 700;

    const flagcheck = element_id === 'long_description_text' || element_id === 'price_text' || element_id === 'sku_info_text';

    let style:any = {
        height,
        width,
        fontFamily, // TODO:: disucssed for now not to use this
        fontSize: `${font_size}px`,
        fontWeight: element_id === "sku_info_text" ? "400" : element_id === 'product_name' ? "400" : "",
        color: flagcheck ? globalFontColor : color,
        left: x_pos,
        top: y_pos,
        text_align: text_align,
    };
    let style2 = {
        fontWeight
    };
    const flagEle =
        // element_id === 'length_label' ||

        element_id === 'product_detail_title' ||
        element_id === 'available_colors_title' ||
        element_id === 'short_description_text' ||
        element_id === 'product_name' || 
        element_id === 'article_label' ||
        element_id === 'color_label' ||
        element_id === 'method_label' || 
        element_id === 'location_label' ||
        element_id === 'size_label' ||
        element_id === 'pms_label' ||
        element_id === 'date_label'
    if (flagEle) {
        style.color = tempFontColor;
    }

    const flagVal = 
        element_id === 'article_value' ||
        element_id === 'color_value' ||
        element_id === 'method_value' ||
        element_id === 'location_value' ||
        element_id === 'size_value' ||
        element_id === 'pms_value' ||
        element_id === 'date_value'
    if(flagVal){
        style.color = globalFontColor;
    }

    const skuInfoTxtFlag = element_id === "sku_info_text";
    // const shortDescTxtFlag = element_id === "short_description_text";
    const priceTxtFlag = element_id === "price_text";
    // || priceTxtFlag
    const editDom = skuInfoTxtFlag
        ? (
            <input
                type="text"
                name="textarea"
                id="texttext"
                value={textValue === null ? '' : textValue}
                style={{ ...style, width: '100%', height: '20px' }}
                onBlur={onLeave}
                onFocus={focushere}
                onChange={handleChange}
            />
        )
        : (
            <textarea
                name="textarea"
                id="texttext"
                value={textValue === null ? '' : textValue}
                style={{ ...style2, width: '100%', minHeight: '5%' }}
                onBlur={onLeave}
                onFocus={focushere}
                onChange={handleChange}
            />
        );
    // console.log(style);
    /**
     * *
     * *
     */
    if (priceTxtFlag) {
        const ff = excludePhotolayout === 'photo_layout';
        if (draw_value === null || draw_value === '') {
            style.width = ff ? '49%' : '88%';

            if (ff) style.top = '90.7%';
            style.left = ff ? '4.562%' : '10%';
        }
    }

    if (element_id === 'price_label') {
        if (
            props.price_value === null ||
            props.price_value === ''
        )
            return null;
    }

    let newfontchangecss = '';
    if (
        element_id === 'sku_info_text'
    ) {
        newfontchangecss = 'font-gotham-medium'
    }
    else if (
        element_id === 'product_name' ||
        element_id === 'available_colors_title' ||
        element_id === 'product_detail_title' ||
        element_id === 'short_description_text'
    ) {
        newfontchangecss = 'font-gotham-black'
    }
    if(element_id === 'date_value' || element_id === 'date_label'){
        newfontchangecss = 'font-gotham-book'
    } else if (element_id === 'diameter_label' ||
        element_id === 'weight_label' ||
        element_id === 'country_of_origin_label' ||
        element_id === 'brand_label' ||
        element_id === 'length_label' ||
        element_id === 'width_label' ||
        element_id === 'height_label' ||
        element_id === 'material_label' ||
        element_id === 'article_label' ||
        element_id === 'color_label' ||
        element_id === 'method_label' ||
        element_id === 'location_label' ||
        element_id === 'size_label' ||
        element_id === 'pms_label' || 
        element_id === 'date_label' 
    ) {
        newfontchangecss = 'font-gotham-medium'
    }
    else if (element_id === 'diameter_value' ||
        element_id === 'weight_value' ||
        element_id === 'country_of_origin_value' ||
        element_id === 'brand_value' ||
        element_id === 'length_value' ||
        element_id === 'width_value' ||
        element_id === 'height_value' ||
        element_id === 'material_value' ||
        element_id === 'article_value' ||
        element_id === 'color_value' ||
        element_id === 'method_value' ||
        element_id === 'location_value' ||
        element_id === 'size_value' ||
        element_id === 'pms_value' 
    ){
        newfontchangecss = 'font-gotham-book'
    }
    else if (element_id === 'price_label') {
        newfontchangecss = 'font-gotham-medium';
    }
    else if (element_id === 'bottom_price_per_product_value_text') {
        newfontchangecss = 'font-gotham-book';
    }

    if (
        element_id === 'date_label' ||
        element_id === 'price_text' ||
        element_id === 'bottom_total_label'
    ) {
        // console.log(element_id);
        newfontchangecss = 'font-gotham-medium'
    }

    const checklimit = element_id === 'long_description_text' ? longTextlengt : shortTextlengt;
    return (
        <div className={`titletext textEditable absolute ${newfontchangecss}`} style={style}>
            {!mode ? (
                <div className="flex text Wrap" id="textContainer" style={{ flexDirection: 'column', flex: 1 }} onClick={doubleClick}>
                    <TextLine text={textValue} align={text_align} textlengt={checklimit} />
                </div>
            ) : editDom}
        </div>
    )
}
export default TextEditable;