import React, { useContext, useState, useEffect } from 'react'
import { isArray } from 'lodash';

import boardContext from '../../context/BoardContext';
// import lookAndFeelContext from '../../context/LookAndFeelContext';

import LayoutStyle from './atom/PT_SVG';
import PTTextarea from './atom/PT_Textarea';
import PTInput from './atom/PT_Input';
import PTMargin from './atom/PT_Margin';
import PTImage from './atom/PT_Image';
import DebtorLogo from './component/DebtorLogo';

// import { TextEditable } from '../Shape'

function group_elements(elements: any) {
    let grouping: any = {};
    let availableColors: any = {
        title: null,
        colors: [],
    }

    let lableObj = [
        'short_description_text',
        'long_description_text',
        'sku_info_text',
        'product_highlight',
        'product_image'
    ]
    let products: any = {};
    elements.map((e: any) => {
        if (grouping[e.group] === undefined) {
            // if (e.group === 'product_image') {
            //     products[e.group] = e;
            // } else
            if (e.group === 'available_colors') {
                //                 console.log(e);
                if (e['element_id'] === "available_colors_title") {
                    availableColors.title = e;
                } else {
                    availableColors.colors.push(e);
                }
            } else {
                if (lableObj.includes(e.element_id))
                    grouping[e.group] = e;
                else
                    grouping[e.group] = [e]
            }

        } else {
            if (isArray(grouping[e.group])) {
                grouping[e.group].push(e);
            } else {
                grouping[e.group] = e;
            }
        }
        return null;
    })

    return {
        grouping,
        availableColors,
        products,
    }
}

export default function PillowSlide(props: any) {

    const { element, design, editorLogo, look, presentationLogo }: any = useContext(boardContext);
    // const { element }: any = useContext(lookAndFeelContext);
    // console.log(presentationLogo);
    const { brandLogoURL = '', elements, product_artwork_design, image_type, onSlideUpdate } = props; // product_template_type
    let { grouping, availableColors } = group_elements(elements); // products
    const { font_color } = look;
    const [flags, setFlags] = useState<any>({});

    const shortTextlengt = 85;
    const longTextlengt = 367; // 370 // 500; // 419;

    useEffect(() => {
        let f: any = {};
        design.map((d: any) => {
            f[d.element_id] = d.value;
            return null;
        })
        setFlags(f);
        // console.log(f);

        return () => {
            setFlags({});
        }
    }, [design])


    if (element.length === 0) return null;

    // console.log({ grouping })
    // console.log(availableColors)
    // console.log({ flags })

    function detailFormating(details: any[], element_id: string) {
        const result = details.filter((detail: any) => detail.element_id !== "product_detail")
            .map((detail: any) => {
                const newSvg = (detail.default_value).replace("{{fillColor}}", `'${font_color}'`);
                return (
                    <div key={detail._id} className="flex m-b_10 align-center w-half">

                        <div dangerouslySetInnerHTML={{ __html: newSvg }} />
                        <div
                            className='ml5 line-height_0 text-semi-bold_small fz_10 '
                            style={{
                                wordBreak: "break-all"
                            }}
                        >
                            <PTInput
                                cssStyle="text-semi-bold_small fz_10"
                                style={{ color: font_color }}
                                element={detail}
                                onSlideUpdate={onSlideUpdate}
                                fallbackText={true}
                            />
                        </div>
                    </div>
                )
            })
        return result;
    }

    return (
        <div className={'pillow fle'}>
            {image_type === 'mood' &&
                <div className='absolute flex justify-end overflow-hidden textalign-right' style={{
                    height: '525px',
                    width: '928px',
                }}>
                    <div style={{ width: '644px' }}>
                        <img src={product_artwork_design} style={{ height: '100%' }} alt="" />
                    </div>
                    {/* <img src={grouping.product_image.draw_value} style={{ height: '100%' }} alt="" /> */}
                </div>
            }


            <div className='absolute flex wfull'>
                <div className={'flex1 justify-end textalign-right'} style={{
                    height: '525px',
                }}>
                    {image_type !== "mood" &&
                        <>
                            <div className={'absolute flex justify-center align-center w-full'} style={{
                                height: 'inherit',
                                opacity: '0.4'
                            }}>
                                {element.product_highlight.value.status ?
                                    element.product_highlight?.applied === 'square'
                                        ? <LayoutStyle.squery fill={element.colors.value[element.style.applied].main?.applied} />
                                        : null
                                    : null
                                }
                            </div>
                        </>
                    }

                </div>
            </div>

            <div className='absolute h-full'>
                {element.style?.applied === 'style_1'
                    ? <LayoutStyle.Style1
                        fill={element.colors?.value[element.style.applied].main?.applied}
                        accent1={
                            element.colors?.value[element.style.applied].accent_1?.status
                                ? element.colors?.value[element.style.applied].accent_1?.applied
                                : element.colors?.value[element.style.applied].main?.applied
                        }
                        accent2={
                            element.colors?.value[element.style.applied].accent_2?.status
                                ? element.colors?.value[element.style.applied].accent_2?.applied
                                : element.colors?.value[element.style.applied].main?.applied}
                    />
                    : element.style?.applied === 'style_2'
                        ? <LayoutStyle.Style2
                            fill={element.colors?.value[element.style.applied].main?.applied}
                            accent1={
                                element.colors.value[element.style.applied].accent_1?.status
                                    ? element.colors.value[element.style.applied].accent_1?.applied
                                    : element.colors.value[element.style.applied].main?.applied
                            }
                            accent2={
                                element.colors?.value[element.style.applied].accent_2?.status
                                    ? element.colors?.value[element.style.applied].accent_2?.applied
                                    : element.colors?.value[element.style.applied].main?.applied}
                        />
                        : <LayoutStyle.Style3
                            fill={element.colors?.value[element.style.applied].main?.applied}
                            accent1={
                                element.colors.value[element.style.applied].accent_1?.status
                                    ? element.colors.value[element.style.applied].accent_1?.applied
                                    : element.colors.value[element.style.applied].main?.applied
                            }
                            accent2={
                                element.colors?.value[element.style.applied].accent_2?.status
                                    ? element.colors?.value[element.style.applied].accent_2?.applied
                                    : element.colors?.value[element.style.applied].main?.applied}
                        />
                }
            </div>

            <div className='absolute flex wfull'>
                <div className={'flex1 w350 p-t_20 p-r_20 p-b_20 p-l_30'}>

                    {/* SHORT DESCRIPTION */}
                    <div className='short_description_text flex align -center m-b_10' style={{ color: font_color, minHeight: '42px' }}>
                        {
                            grouping["short_description"] &&
                            <PTTextarea
                                cssStyle="text-bold_default"
                                style={{ color: font_color, height: '42px', overflow: 'hidden' }}
                                editheight={42}
                                element={grouping["short_description"]}
                                onSlideUpdate={onSlideUpdate}
                                limit={shortTextlengt}
                            />
                        }
                    </div>

                    <div className='flex justify-space-between' style={{
                        height: '32px',
                        color: font_color
                    }}>
                        <div className='flex1 flex align-center' style={{ color: font_color }}>
                            {flags && flags["product_sku"] && grouping["sku_info"] &&
                                <PTInput
                                    cssStyle="text-semi-bold_medium"
                                    style={{ color: font_color }}
                                    element={grouping["sku_info"]}
                                    onSlideUpdate={onSlideUpdate}
                                />}
                        </div>
                        {flags && flags["green_points"] && (
                            <div className='flex1 flex justify-end'>

                                <div className='green-point-cc flex flex-row text-bold_default p-t_5 p-r_10 p-b_5 p-l_5'>
                                    <div className='green-number fz9px m-r_5 flex-1 text-uppercase flex align-center justify-center'>
                                        66
                                    </div>
                                    {/* 
                                */}
                                    <span className='green-title fz9px flex align-center text-uppercase text-bold_default'>
                                        Green Point
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* LONG DESCRIPTION */}
                    <div className='long_description_text flex align-c enter m-b_20 m-t_20' style={{ color: font_color }}>
                        {
                            grouping["long_description"] &&
                            <PTTextarea
                                cssStyle="text-regular_small"
                                style={{ color: font_color }}
                                editheight={128}
                                element={grouping["long_description"]}
                                onSlideUpdate={onSlideUpdate}
                                limit={longTextlengt}
                            />
                        }
                    </div>

                    {/* AVAILABLE COLORS */}
                    <div className=''>
                        <div className='available-color text-uppercase m-b_10' style={{ color: font_color }}>
                            {availableColors && availableColors.titie !== null && <PTInput
                                cssStyle="text-bold_small"
                                style={{ color: font_color }}
                                element={availableColors.title}
                                onSlideUpdate={onSlideUpdate}
                            />}
                        </div>
                        <div className='available_color_image m-b_15 p-t_10 p-r_10'>
                            {availableColors && availableColors.title !== null && availableColors.colors.map((color: any) => <PTImage key={color._id} value={color.draw_value} />)}
                        </div>
                    </div>

                    {/* DETAIL SECTION */}
                    <div className='flex flex-wrap'>
                        {detailFormating(grouping['product_detail'], "dimensions")}
                    </div>
                </div>

                <div className={'flex1 justify-end textalign-right'} style={{
                    height: '525px',
                }}>
                    {image_type !== "mood" &&
                        <>
                            <div className={'absolute flex justify-center align-center'} style={{
                                height: 'inherit',
                                width: '578px',
                                opacity: '0.4'
                            }}>
                                {element.product_highlight.value.status ?
                                    element.product_highlight?.applied === 'round'
                                        ? <LayoutStyle.circle fill={element.colors.value[element.style.applied].main?.applied} />
                                        : element.product_highlight?.applied === 'diamond'
                                            ? <LayoutStyle.diamon fill={element.colors.value[element.style.applied].main?.applied} />
                                            : null
                                    : null
                                }
                            </div>
                            <div className={'relative flex justify-center align-center h-full'}>
                                {grouping.product_image &&
                                    <img src={product_artwork_design} alt="product" style={{ maxWidth: '440px', maxHeight: '440px' }} />
                                }
                            </div>
                        </>
                    }
                </div>
            </div>

            <div className="absolute flex wfull flex flex-column position-right justify-sp ace-between align-end" style={{
                height: '525px',
                width: '575px',
            }}>
                <div>
                    {flags.product_debtor_logo && (
                        <DebtorLogo
                            brandLogo={brandLogoURL}
                            value={presentationLogo}
                            style={{
                                backgroundColor: editorLogo.background_color
                            }}
                            elements={grouping.end_customer_logo || []}
                            // cssStyle={{}}
                            onSlideUpdate={onSlideUpdate}
                        />
                    )}
                </div>

                {flags.product_price && grouping.price_text &&
                    grouping.price_text[1].default_value === null ? '' : (
                    <div className='flex py px' style={{
                        color: '#000' || font_color,
                        background: 'rgba(255,255,255,0.5)',
                        borderRadius: '4px 0 0',
                        width: '280px',
                        height: '40px',
                        position: 'absolute',
                        bottom: '-3px'
                    }}>
                        <span className='pl10' style={{
                            color: '#000' || font_color,
                            width: '180px',
                            // outline: '1px solid blue'
                        }}>
                            {
                                grouping.price_text &&
                                grouping.price_text[0] &&
                                <PTInput cssStyle="text-semi-bold_default" element={grouping.price_text[0]} onSlideUpdate={onSlideUpdate} />
                            }
                        </span>
                        <span className='pl10' style={{
                            color: '#000' || font_color,
                            minWidth: '70px',
                        }}>
                            {
                                grouping.price_text &&
                                grouping.price_text[1] &&
                                <PTMargin
                                    cssStyle="text-semi-bold_default"
                                    style={{
                                        width: '80px',
                                    }}
                                    element={grouping.price_text[1]}
                                    onSlideUpdate={onSlideUpdate}
                                />
                            }
                        </span>
                    </div>
                )}

            </div>
        </div>
    )
}