import axios from 'axios';
import urls from './';

import { getStoreViewFromURL } from '../helpers';

const global = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
};

function messageConsole(msg: any) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        console.log(`CC: ${msg}`);
    }
}

/*
 * GET access for Application
 * @param base - API address
 * @param clientId - User / client ID
 * @param params - Extra key
 * @param presentation_type - Type of presentiaion
 */
export function getAccessKey(
    base: string,
    clientId: string,
    extras: any,
    presentation_type: string
) {
    const urll = `${base}/${urls.accesskey}`;
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id: clientId,
            presentation_type,
        },
        params: {
            grant_type: 'access_key',
            auth: 'basic',
            extras,
        },
    }).then((response: any) => {
        const {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime = '',
        } = response.data;

        return {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features, //: fmenu,
            presentationType,
            expirytime,
        };
    });
}

export function regenerateAccessKey(
    base: string,
    clientId: string,
    params: any,
    presentation_type: string,
    auth: string,
    extra: string
) {
    const urll = `${base}/${urls.regenerateAccesskey}`;
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id: clientId,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
            presentation_type,
        },
        params: {
            grant_type: 'access_key',
            auth: 'basic',
            extras: params,
        },
    }).then((response: any) => {
        const {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime = '',
        } = response.data;

        return {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime,
        };
    });
}

// ## PRESENTATIN TYPE LIST
export function getPresentationList(base: string, clientId: string) {
    const storeview = getStoreViewFromURL();
    const urll = `${base}/${urls.getGetAll}`;
    return axios(urll, {
        method: 'GET',
        headers: { ...global, client_id: clientId },
        params: { storeview },
    }).then((response: any) => {
        const { message, data, translation } = response.data;
        messageConsole(message);
        return { status: response.status, data, translation };
    });
}

// ## OVERVIEW PAGE
export function getUserPresentations(
    base: string,
    client_id: any,
    extra: any,
    auth: any,
    presentation_type: any,
    page: any,
    limit: any,
    sort: any,
    sortby: any,
    old_customer_id: any
) {
    const urll = `${base}/${urls.getUserPresentations}`;
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            page,
            limit,
            sort,
            sortby,
            presentation_type,
            old_customer_id,
        },
    }).then((response: any) => {
        const {
            message,
            data: { total, presentations },
        } = response.data;
        messageConsole(message);
        return { status: response.status, total, presentations }; // response.json();
    });
}

export function getRemovePresentation(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    presentation: any
) {
    const urll = `${base}/${urls.removePresentation}`;
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { presentation },
    }).then((response: any) => {
        const {
            data: { message, success },
            status,
        } = response;
        messageConsole(message);
        return { status, success };
    });
}

// ## PRESENTATION DETAIL
export function getUserPresentationDetail(
    base: any,
    presentation: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.getUserPresentationDetail}`;
    const storeview = getStoreViewFromURL();
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { presentation, storeview },
    }).then((response: any) => {
        const {
            data,
            status = 200,
            message,
            productReferenceData,
            productReferenceDataDefault,
            translation,
        } = response.data; // dummyflag ? dummyJson : response.data;  
        messageConsole(message);

        let product_slide_elements: any = {};
        data.product_slide_elements.forEach((pse: any) => {
            if (pse.element_id === 'style') {
                pse['applied'] = "style_1"
                pse.value.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            } else if (pse.element_id === 'product_highlight') {
                pse['applied'] = "round"
                pse.value.highlight.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            }
            product_slide_elements[pse.element_id] = { ...pse };
            return null;
        });
        /**
         * end_customer_logo: 1
         * exported_format: 0
         * presentation_background: 1
         * presentation_display_name: 1
         * presentation_font: 0
         * presentation_format: 0
         * presentation_logo: 1
         * presentation_original_name: 0
         * slides: 1
         */
        // console.log(data.product_slide_elements, product_slide_elements)
        return "pillow" === response.data.data.type ? {
            status,
            productReferenceData,
            prodDefault: productReferenceDataDefault,
            translation,
            ...data,
            product_slide_elements,
        } : {
            status,
            productReferenceData,
            prodDefault: productReferenceDataDefault,
            translation,
            ...data,
        };
    });
}

// ## TITLE
export function postUpdateTitle(
    base: any,
    data: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postUpdateTitle}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## SLIDE VISIBILITY CHANGE
export function postChangeSlideVisibility(
    base: any,
    id: any,
    presentationId: any,
    visible: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postChangeSlideVisibility}?slide=${id}&presentation_id=${presentationId}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: { visible },
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);

        return { status };
    });
}

// ## SLIDE REMOVE
export function postDeleteRemoveSLide(
    base: any,
    id: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.deleteRemoveSlide}?slide=${id}&presentation_id=${presentationId}`;
    return axios(urll, {
        method: 'DELETE',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## SLIDE ADD
export function postAddImageSlide(
    base: any,
    data: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postAddImageSlide}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const {
            status,
            data: { message, data },
        } = response;
        messageConsole(message);
        return { status, data };
    });
}

// ## PRESENTATION LOGO UPDATE
export function postSavePresentationLogoInfo(
    base: any,
    data: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postSavePresentationLogoInfo}?presentation=${presentationId}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## CUSTOMER LOGO UPDATE
export function postSaveCustomerLogoInfo(
    base: any,
    data: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postSaveCustomerLogoInfo}?presentation=${presentationId}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## LOOK AND FEEL SETTING
export function postSaveLookAndFeel(
    base: any,
    data: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postLookFeel}?presentation=${presentationId}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## SLIDE PROPERTY UPDATE
export function postUpdateSlideProperty(
    base: any,
    data: any,
    slide: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.postUpdateSlideProperty}?slide=${slide}`;
    return axios(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const {
            status,
            data: { message },
        } = response;
        messageConsole(message);
        return { status };
    });
}

// ## BACKGROUND IMAGES
export function getBackgroundImages(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    param?: any,
    contact_id?: any
) {
    const urll = `${base}/${urls.getBackgroundImages}`;
    const headerParams: any = {};
    if (param) {
        headerParams.path = param;
    }
    if (contact_id) {
        headerParams.contact_id = contact_id;
    }
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: headerParams,
        // params: param
        //     ? {
        //           path: param,
        //       }
        //     : "",
    }).then((response: any) => {
        const {
            data: { message, data },
            status,
        } = response;
        messageConsole(message);

        return { status, data };
    });
}

/**
 *
 * @param client_id - Client id
 * @param extra -
 * @param auth
 */
export function getDefaultTheme(
    base: any,
    client_id: any,
    extra: any,
    auth: any
) {
    const urll = `${base}/${urls.getDefaultTheme}`;
    return axios(urll, {
        method: 'GET',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
    }).then((response: any) => {
        // console.log(response.data);
        const { data, status } = response.data;
        const {
            slides,
            presentation_background,
            presentation_font,
            presentation_format,
        } = data;
        return {
            status,
            slides,
            presentation_background,
            presentation_font,
            presentation_format,
        };
    });
}

export function saveDefaultTheme(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.saveDefaultTheme}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        console.log(response.data);
        const {
            data: { success, message },
            status,
        } = response;
        return { status, success, message };
    });
}

export function postLoadDefaultTheme(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const storeview = getStoreViewFromURL();
    const urll = `${base}/${urls.postLoadDefaultTheme}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { storeview },
        data,
    }).then((response: any) => {
        const {
            data,
            status = 200,
            message,
            productReferenceData,
            productReferenceDataDefault,
            translation,
        } = response.data; // dummyflag ? dummyJson : response.data; 
        // console.log(JSON.stringify(response));
        messageConsole(message);
        let product_slide_elements: any = {};
        data.product_slide_elements.forEach((pse: any) => {
            if (pse.element_id === 'style') {
                pse['applied'] = "style_1"
                pse.value.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            } else if (pse.element_id === 'product_highlight') {
                pse['applied'] = "round"
                pse.value.highlight.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            }
            product_slide_elements[pse.element_id] = { ...pse };
            return null;
        });
        return {
            status,
            productReferenceData,
            prodDefault: productReferenceDataDefault,
            translation,
            ...data,
            product_slide_elements,
        };
    });
}

export function postEcqupdate(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postEcqUpdate}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message, data: resp, productReferenceData } = data;
        messageConsole(message);
        return { status, resp, productReferenceData };
    });
}

export function getProductTemplate(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.getProductTemplate}`;
    return axios(urll, {
        method: 'get',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            ...data,
        },
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, data: data.data };
    });
}

export function postLoadProductTemplate(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postLoadProductTemplate}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const { status, data: d1 } = response;
        const { message, data } = d1;
        const { product_slide_elements, product_slides } = data;
        messageConsole(message);
        return {
            status,
            data: product_slides,
            elements: product_slide_elements,
        };
    });
}

export function postProductElementInfo(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postProductElementInfo}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status };
    });
}

export function postProductDesignInfo(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postProductDesignInfo}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status };
    });
}

export function postPDF(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    request: any,
    download: boolean = true
) {
    const urll = `${base}/${urls.postPDF}`;
    const responseType: any = download ? 'arraybuffer' : '';

    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
            Accept: 'application/pdf',
        },
        responseType,
        data: request,
    }).then((response: any) => {
        const fileName = `${request.presentation}.pdf` || 'file.pdf';
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE11 and the legacy version Edge support
            let blob = new Blob([response.data], { type: 'text/html' });
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // other browsers
            if (download) {
                let bl = new Blob([response.data], { type: 'text/html' }),
                    a = document.createElement('a');
                a.href = URL.createObjectURL(bl);
                a.download = fileName;
                a.hidden = true;
                document.body.appendChild(a);
                a.click();
            } else {
                return response.data;
            }
        }

        console.log('PDF request success');
        return true;
    });
}

export function postPTT(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    request: any
) {
    const urll = `${base}/${urls.postPTT}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        // responseType: 'arraybuffer',
        responseType: 'blob',
        data: request,
    }).then((response: any) => {
        const fileName = `${request._id}.ppt` || 'file.ppt';
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE11 and the legacy version Edge support
            let blob = new Blob([response.data], { type: 'text/html' });
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // other browsers
            let bl = new Blob([response.data], { type: 'text/html' }),
                a = document.createElement('a');
            a.href = URL.createObjectURL(bl);
            a.download = fileName;
            a.hidden = true;
            document.body.appendChild(a);
            a.click();
        }

        console.log('PDF request success');
        return true;
    });
}

export function postRearrange(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postReArrange}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status };
    });
}

export function postReset(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postReset}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, data: data.data };
    });
}

export function getThemes(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    presentation_type: string
) {
    const urll = `${base}/${urls.getThemes}`;
    return axios(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            presentation_type,
        },
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, data: data.data };
    });
}

export function postSwitchTheme(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postSwitchTheme}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, ...data.data };
    });
}

export function getglobalSetting(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    // data = { presentation_type, theme_id }
    const urll = `${base}/${urls.getGlobalSetting}`;
    return axios(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            ...data,
        },
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, data: data.data };
    });
}

export function postEmailShare(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) {
    const urll = `${base}/${urls.postEmailShare}`;
    return axios(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status };
    });
}

export function postImageUpload(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any,
    contact_id: any
) {
    const urll = `${base}/${urls.postImageUpload}`;
    let formData = new FormData();
    formData.append('images', data.images);
    return axios
        .post(urll, formData, {
            params: {
                subdir: 'Your Images',
                contact_id: contact_id,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                client_id,
                'x-extra-token': `${extra}`,
                authorization: `Bearer ${auth}`,
            },
            data,
        })
        .then((response: any) => {
            const { status, data } = response;
            const { message, files = [] } = data;
            messageConsole(message);
            return { status, background: files };
        });
}

export function postPresentationUpdate(
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any,
    presentationId: any
) {
    const urll = `${base}/${urls.postPresentationUpdate}/${presentationId}`;
    return axios(urll, {
        method: 'put',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status };
    });
}

/**
 * * Tips for new changes will appear in local menu
 * * Tips will keep changing in 10000 ms
 * @param base 
 * @param client_id 
 * @param extra 
 * @param auth 
 * @returns 
 */
export function getAllTips(base: any,
    client_id: any,
    extra: any,
    auth: any) {
    const urll = `${base}/${urls.getAllTips}`;
    return axios(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            type: 'pillow',
        }
    }).then((response: any) => {
        const { status, data } = response;
        const { message } = data;
        messageConsole(message);
        return { status, data: data.data };
    });
}
