import React  from 'react';
import { threedbox } from '../../helpers/IconList';

const ThreeDModel = (props: any) => {
    const { width, height, x_pos, y_pos, draw_value } = props;
    const style = {
        height,
        width,
        left: x_pos,
        top: y_pos
    };


    return (
        <div style={style} className="absolute threeDmodelwrap">
            <a
                className='threeDanchor'
                target='_blank'
                rel="noopener noreferrer"
                href={draw_value}
                title="3D Model Link"
            >
                <img src={threedbox} alt={draw_value} />
            </a>
        </div>
    )
};
export default ThreeDModel;
