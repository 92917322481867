import React, {
    useEffect,
    useLayoutEffect,
    useState,
    useCallback,
} from "react";
import loadFonts from "../../helpers/FontFunFunction";

import {
    catcchErrorHandling,
    backgroundSortFolderAndImage,
    ecqFormatting,
    currentChangeAndLocalMenu
} from "../../helpers";
import Title from "../../component/Title";
import Story from "../../component/Story";
import Board from "../../component/Board";
import ErrorBox from "../../component/ErrorBox";

// import TitleSlide from "../../component/PillowSlide/TitleSlide";
import PillowSlide from "../../component/PillowSlide";
import ContactSlide from "../../component/PillowSlide/ContactSlide";

import LocalMenu from "../../component/LocalMenu";
import Panel from "../../component/Panel";

import ColorPicker from "../../component/ColorPicker/ColorPicker";
import BackgroundImage from "../../component/BackgroundImage";
import BackgroundSelect from "../../component/BackgroundImage/BackgroundSelect";
import Editable from "../../component/Editable";
import EmailShare from "../../component/EmailShare";

import { PresentationPropsType } from "./typePresentation";

import lookandFeelContext from "../../context/LookAndFeelContext";
import lookContext from "../../context/LookContext";
import settingContext from "../../context/SettingContext";
import boardContext from "../../context/BoardContext";
import EditEcqContext from "../../context/EditEcqContext";
import ThemeContext from '../../context/ThemeContext';
import languageContext from '../../context/languageContext';
import LayoutContext from "../../context/LayoutContext";

import {
    getUserPresentationDetail,
    postChangeSlideVisibility,
    postDeleteRemoveSLide,
    postAddImageSlide,
    postSavePresentationLogoInfo,
    postSaveCustomerLogoInfo,
    postSaveLookAndFeel,
    postUpdateSlideProperty,
    getBackgroundImages,
    // getDefaultTheme,
    saveDefaultTheme,
    postLoadDefaultTheme,
    postUpdateTitle,
    postEcqupdate,
    getProductTemplate,
    postLoadProductTemplate,
    postProductElementInfo,
    postProductDesignInfo,
    postPDF,
    postReset,
    postPTT,
    getThemes,
    postSwitchTheme,
    getglobalSetting, postPresentationUpdate,
    // postEmailShare,
    postImageUpload,
    getAllTips
} from "../../api/calls";
import "../container.css";

const Presentation = (props: PresentationPropsType) => {
    const [firstload, setfirstload] = useState(false);
    const [thumbload, setthumbload] = useState(false);
    const { display, setDisplayOverview } = props;
    const { baseUrl, clientID, validationToken, type } = props;
    const [typeName, setTypeName] = useState('');
    const [extraKey] = useState(props.extraKey);
    const [accesskey, setaccesskey] = useState(props.accesskey);
    const [fonts, setfonts] = useState(props.fonts);
    const timer: number = 3000;
    // * LOADER DISPLAY
    const [isLoading, setLoading] = useState(0);
    const [renderCount, setrenderCount] = useState(0);
    // * BREAD
    const [bread, setbread] = useState([]);
    // * ECQ
    const [ecqView, setECQView] = useState(false);
    const [ecqEditData, setECQeditaData] = useState([]);
    const [ecqDefaultData, setECQDefaultData] = useState([]);
    // * THEMES
    const [themes, setthemes] = useState([]);
    const [activetheme, setactivetheme] = useState('');
    // * SETTING DISPLAY
    const [localsettingcopy, setlocalsettingcopy] = useState(props.setting || {})
    const [settingView, setSettingView] = useState(false);
    const [colorpickview, setColorpickview] = useState(false);
    const [backgroundView, setBackgroundview] = useState(false);
    const [backgroundUpload, setBackgroundUpload] = useState(false);
    const [backgroundImageList, setBackgroundImageList] = useState([]);
    const [, setLocalmenu] = useState(false);
    const [localEdit, setLocalEdit] = useState<any>({});
    const [look, setLook] = useState({});
    const [template, setTemplate] = useState([]);
    const [element, setElement] = useState<any>([]);
    // WIP Pillow
    const [design, setDesign] = useState([]);
    const [margin, setMargin] = useState(0);
    const [current, setCurrent] = useState<any>(null);
    const [presentationFont, setPresentationFont] = useState("");
    const [presentationBg, setPresentationBg] = useState("");
    const [presentationTitle, setPresentationTitle] = useState("");
    const [presentationLogo, setPresentationLogo] = useState({});
    const [editorLogo, setEditorLogo] = useState({});
    const [bgActive, setBgActive] = useState({});
    const [presentationId, setPresentationId] = useState<null | number>(null);
    //* hook add to know the template seletec
    const [templateId, setTemplateId] = useState("");
    const [activeSlide, setActiveSlide] = useState<null | number>(null);
    const [error, setError] = useState("error message goes here");
    const [sslides, setSlides] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [pdfCreating, setPdfCreating] = useState(false);
    const [referencedid, setReferencedid] = useState(null);
    const [presentationoriginalname, setPresentationoriginalname] = useState("");
    const [preentationformat, setPresentationformat] = useState({});
    const [emailshareflag, setemailShareFlag] = useState(false);
    const [pdfcopylink, setpdfcopylink] = useState('');
    const [language, setlanguage] = useState({});
    const [contactID, setContactID] = useState('');
    const [imageSlide2, setImageSlide2] = useState<any>(null);
    const [tips, setTips] = useState<any | null>(null);

    const pdferrormsg = "PDF can't be created.";

    const changeCurrent = useCallback((target: any, flag: any = false) => {
        currentChangeAndLocalMenu({ target, localEdit, setCurrent, setLocalEdit });
    }, [localEdit])

    useEffect(() => {
        setaccesskey(props.accesskey);
    }, [props.accesskey]);
    useEffect(() => {
        thumbGenerator(false, false, 500);
    }, [renderCount]);

    const fetchData = useCallback(
        (presentationID: any, access: any) => {
            setLoading(1);
            const acc = access || accesskey;
            const accessToken: any = validationToken(type);
            getUserPresentationDetail(
                baseUrl,
                presentationID,
                clientID,
                extraKey,
                acc
            )
                .then(
                    (response: any) => {
                        const {
                            status,
                            slides,
                            productReferenceData,
                            prodDefault,
                            translation,
                            ...resp
                        } = response;
                        if (status >= 400) {
                            setError("Bad response from server");
                            return null;
                        }
                        setlanguage(translation);
                        const {
                            presentation_background = "#fff",
                            presentation_display_name,
                            presentation_logo,
                            end_customer_logo,
                            look_and_feel,
                            _id,
                            product_template_id,
                            product_slide_elements = [],
                            product_slide_design = [],
                            reference_id,
                            presentation_original_name,
                            presentation_format,
                            theme_id = '',
                            presentation_type_id,
                            margin,
                            contact_id
                        } = resp;

                        if (resp.type) {
                            setTypeName(resp.type);
                        }

                        setactivetheme(theme_id);
                        setContactID(contact_id)

                        setReferencedid(reference_id);
                        setPresentationoriginalname(presentation_original_name);
                        setPresentationformat(presentation_format);

                        setElement(product_slide_elements);
                        setDesign(product_slide_design);

                        setTemplateId(product_template_id);
                        setPresentationId(_id);
                        if (look_and_feel && look_and_feel.font_name) {
                            setPresentationFont(look_and_feel.font_name);
                        }
                        setPresentationBg(`#${presentation_background}`);
                        setPresentationTitle(presentation_display_name);
                        setPresentationLogo({
                            ...presentation_logo,
                            name: "Show Debtor logo",
                        });
                        setEditorLogo({
                            ...end_customer_logo,
                            name: "Show End customer logo",
                        });
                        setLook(look_and_feel);

                        /**
                         * ? NOTE
                         * * CREATING NEW SLIDES AFTER FETCH
                         * * 1. END CUSTOMER LOGO BLINKS IN PRODUCT_SLIDE WITH PHOTO_LAYOUT
                         * * 2. UN USED ELEMENTS REMOVE
                         */


                        const nnss = slides.map((ss: any) => {
                            // console.log(ss);
                            const { type, product_template_type, elements } = ss;
                            if (
                                product_template_type === "photo_layout" &&
                                type === "product_slide"
                            ) {
                                let newele: any = [];
                                elements.map((ele: any) => {
                                    if (
                                        ele.element_id === 'end_customer_logo' ||
                                        ele.element_id === 'end_customer_logo_background'
                                    ) {
                                        return null;
                                    }
                                    newele.push(ele);
                                    return null;
                                })
                                return {
                                    ...ss,
                                    elements: newele
                                }
                            }
                            return ss;
                        })
                        // setSlides(slides);
                        setSlides(nnss);
                        setMargin(margin);

                        // ! data for reset
                        if (prodDefault && prodDefault.length) {
                            const ecqDataManipulate = ecqFormatting(
                                prodDefault
                            );
                            setECQDefaultData(ecqDataManipulate);
                        }
                        // ! data for display
                        if (
                            productReferenceData &&
                            productReferenceData.length
                        ) {
                            const newResetEcqData = ecqFormatting(
                                productReferenceData
                            );
                            setECQeditaData(newResetEcqData);
                            /**
                             * * reference data of product for catalog product slide
                             * * to display product image options in pop up
                             */
                            setProductImages(productReferenceData);
                        }

                        if (slides && slides.length > 0) {
                            setActiveSlide(0);
                            changeCurrent(slides[0]);
                            // setCurrent(slides[0]);
                        }

                        accessToken.then(({ accesskey }: any) => {
                            getglobalSetting(baseUrl, clientID, extraKey, accesskey, {
                                theme_id,
                                presentation_type: presentation_type_id,
                            })
                                .then(({ status, data }) => {
                                    if (status >= 200 && status < 300) {
                                        const { global_menu = undefined, fonts = [] } = data;
                                        const setting: any = {};
                                        global_menu && global_menu.map((res: any) => {
                                            const { name } = res;
                                            setting[name] = res;
                                            return null;
                                        });
                                        setfonts(fonts);
                                        setlocalsettingcopy(setting)
                                    }
                                })
                                .catch((err: any) => {
                                    setLoading(0);
                                    const msg = catcchErrorHandling(err);
                                    setError(msg);
                                });

                        });
                        setLoading(0);
                    }
                )
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        },
        [baseUrl, clientID, extraKey, accesskey, type, changeCurrent, validationToken]
    );

    useEffect(() => {
        if (!firstload) {
            const { presentationID, extra, type, old_customer_id } = props;

            const accessToken: any = validationToken(type);
            if (presentationID) {
                accessToken.then(({ accesskey }: any) => {
                    setPresentationId(presentationID);
                    fetchData(presentationID, accesskey);
                });
            } else {
                const {
                    decorator_logo,
                    end_customer_logo = "",
                    presentation_title: title,
                    products,
                    reference_id,
                    contact,
                    theme,
                    storeview,
                    margin,
                    email,
                    price_format = '',
                    logocolor = [],
                    // presentation_logo
                } = extra;
                const obj = {
                    presentation_type: type,
                    end_customer_logo,
                    presentation_logo: decorator_logo,
                    title,
                    products,
                    reference_id,
                    contact,
                    theme,
                    storeview,
                    margin,
                    email,
                    old_customer_id,
                    price_format,
                    logocolor
                };
                // fetchLoadDefault(obj);
                // console.log('extra', extra)
                // console.log('obj', obj)
                setLoading(1);
                accessToken.then(({ accesskey }: any) => {

                    postLoadDefaultTheme(
                        baseUrl,
                        clientID,
                        extraKey,
                        accesskey,
                        obj
                    )
                        .then(
                            ({
                                status,
                                slides,
                                productReferenceData,
                                prodDefault,
                                translation,
                                ...resp
                            }: any) => {
                                if (status >= 400) {
                                    setError("Bad response from server");
                                    return null;
                                }
                                setlanguage(translation);
                                if (resp.presentation_type_title) document.title = resp.presentation_type_title;
                                const {
                                    presentation_background,
                                    presentation_display_name,
                                    presentation_logo,
                                    end_customer_logo,
                                    look_and_feel,
                                    _id,
                                    product_template_id,
                                    product_slide_elements = [],
                                    product_slide_design = [],
                                    reference_id,
                                    presentation_original_name,
                                    presentation_format,
                                    theme_id = '',
                                    margin,
                                    contact_id,
                                } = resp;
                                // console.log(resp);
                                if (resp.type) {
                                    setTypeName(resp.type);
                                }

                                setactivetheme(theme_id);
                                setContactID(contact_id)

                                setReferencedid(reference_id);
                                setPresentationoriginalname(
                                    presentation_original_name
                                );
                                setPresentationformat(presentation_format);
                                // PILLOW
                                setElement(product_slide_elements);
                                setDesign(product_slide_design);

                                setTemplateId(product_template_id);
                                setPresentationId(_id);
                                if (look_and_feel && look_and_feel.font_name) {
                                    setPresentationFont(
                                        look_and_feel.font_name
                                    );
                                }
                                setMargin(margin)
                                setPresentationBg(
                                    `#${presentation_background}`
                                );
                                setPresentationTitle(presentation_display_name);
                                setPresentationLogo({
                                    ...presentation_logo,
                                    name: "Show Debtor logo",
                                });
                                setEditorLogo({
                                    ...end_customer_logo,
                                    name: "Show End customer logo",
                                });
                                setLook(look_and_feel);
                                setSlides(slides);

                                /**
                                 * * slide in array
                                 * * reset data should be in array
                                 */
                                // ! data for reset
                                if (prodDefault && prodDefault.length) {
                                    const ecqDataManipulate = ecqFormatting(
                                        prodDefault
                                    );
                                    setECQDefaultData(ecqDataManipulate);
                                }
                                // ! data for display
                                if (
                                    productReferenceData &&
                                    productReferenceData.length
                                ) {
                                    const newResetEcqData = ecqFormatting(
                                        productReferenceData
                                    );
                                    setECQeditaData(newResetEcqData);
                                    /**
                                     * * reference data of product for catalog product slide
                                     * * to display product image options in pop up
                                     */
                                    setProductImages(productReferenceData);
                                }

                                if (slides && slides.length > 0) {
                                    setActiveSlide(0);
                                    changeCurrent(slides[0]);
                                }
                                setLoading(0);
                            }
                        )
                        .catch((err: any) => {
                            setLoading(0);
                            const msg = catcchErrorHandling(err);
                            setError(msg);
                        });
                });
            }
            // fetchProductTemplate(type);

            setfirstload(true);
        }
    }, [
        props,
        baseUrl,
        clientID,
        extraKey,
        fetchData,
        accesskey,
        firstload,
        validationToken,
        changeCurrent
    ]);

    useEffect(() => {
        if (activetheme !== '') {
            const { type } = props;
            const accessToken: any = validationToken(type);

            accessToken.then(({ accesskey }: any) => {
                getProductTemplate(
                    baseUrl,
                    clientID,
                    extraKey,
                    accesskey,
                    { presentation_type: type, theme_id: activetheme })
                    .then(({ status, data }: any) => {
                        if (status === 200) {
                            setTemplate(data);
                        }
                    })
                    .catch((err: any) => {
                        const msg = catcchErrorHandling(err);
                        setError(msg);
                    });
            });
        }
    }, [
        props,
        activetheme,
        baseUrl,
        clientID,
        extraKey,
        validationToken
    ])

    useEffect(() => {
        if (typeName === "pillow" && tips === null) {
            const accessToken: any = validationToken(props.type);
            setLoading(1);
            accessToken.then(({ accesskey }: any) => {
                getAllTips(baseUrl, clientID, extraKey, accesskey)
                    .then(({ status, data = [] }: any) => {
                        if (status === 200) {
                            setTips(data);
                        }
                        setLoading(0);
                    })
                    .catch((err: any) => {
                        setLoading(0);
                        const msg = catcchErrorHandling(err);
                        setError(msg);
                    });
            });
        }
    }, [typeName, baseUrl, clientID, extraKey, accesskey, tips, props.type, validationToken])


    // accesskey, props, fetchProductTemplate, fetchLoadDefault, fetchData
    function fetchSlideVisibleChange(id: string, visible: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postChangeSlideVisibility(
                baseUrl,
                id,
                presentationId,
                visible,
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status }: any) => {
                    if (status === 200) {
                        const nn: any = sslides.map((ss: any) => {
                            if (id === ss._id) {
                                return { ...ss, visible: visible };
                            }
                            return ss;
                        });
                        setSlides(nn);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchDeleteSlideRemove(id: string) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postDeleteRemoveSLide(baseUrl, id, presentationId, clientID, extraKey, accesskey)
                .then(({ status }: any) => {
                    if (status === 200) {
                        const nn: any = sslides.filter((ss: any) => {
                            if (id !== ss._id) {
                                return ss;
                            }
                            return null;
                        });
                        setSlides(nn);
                        setrenderCount(renderCount + 1);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchAddSlide(anydata: any) {

        const data: any = { ...anydata, theme: activetheme || '' };
        const accessToken: any = validationToken(props.type);
        setLoading(1);
        accessToken.then(({ accesskey }: any) => {
            postAddImageSlide(baseUrl, data, clientID, extraKey, accesskey)
                .then(({ status, data }: any) => {
                    if (status === 200) {
                        if (data) {
                            let addedSlideList: any = [];
                            addedSlideList = [...sslides];
                            const idx = activeSlide ? activeSlide + 1 : 1;
                            addedSlideList.splice(idx, 0, data);
                            setSlides(addedSlideList);
                            setActiveSlide(idx);
                            // changeCurrent(addedSlideList[idx]);
                            // console.log(addedSlideList[idx]);
                            if (idx) {
                                localMenuSlideEditValueUpdate(addedSlideList[idx], 'background_image')
                            }
                            setCurrent(addedSlideList[idx]);
                            thumbGenerator(false, false, 100);
                        }
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function localMenuSlideEditValueUpdate(slide: any, element_id: any) {
        slide.elements.map((as: any) => {
            if (as.element_id === element_id) {
                const localEditUpdate = {
                    _id: as._id,
                    element_id: "background_image",
                    group: "background",
                    slideType: "image_slide"
                }
                setLocalEdit(localEditUpdate)
            }
            return null;
        });
    }

    function fetchPresentationLogoUpdate(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postSavePresentationLogoInfo(
                baseUrl,
                data,
                presentationId,
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status }: any) => {
                    if (status === 200) {
                        setPresentationLogo({
                            ...presentationLogo,
                            [data.type]: data.value,
                        });

                        // athumbGenerator(activeSlide, 1000);
                        setrenderCount(renderCount + 1);
                        // thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchCustomerLogoUpdate(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postSaveCustomerLogoInfo(
                baseUrl,
                data,
                presentationId,
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status }: any) => {
                    if (status === 200) {
                        setEditorLogo({
                            ...editorLogo,
                            [data.type]: data.value,
                        });
                        thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchLookAndFeelUpdate(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postSaveLookAndFeel(
                baseUrl,
                data,
                presentationId,
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status }: any) => {
                    if (status === 200) {
                        setLook({ ...look, [data.type]: data.value });
                        if (data.type === "font_name") {
                            setPresentationFont(data.value);
                        }
                        setrenderCount(renderCount + 1);
                        // thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchSlidePropertyUpdate(data: any) {
        if (current === null) return;
        let retain = data.retain || true;
        let newData: any = [];
        if (data.type === "visible") {
            current.elements.map((e: any) => {
                if (e.group === data.group) {
                    newData.push({
                        id: e._id,
                        type: data.type,
                        value: data.value,
                        retain
                    });
                    return { ...e, [data.type]: data.value };
                }
                return e;
            });
        } else if (data.group === "background") {
            current.elements
                .filter((e: any) => e.group === data.group)
                .map((e: any) => {
                    if (data.type === "fill") {
                        if (data.type in e) {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain
                            });
                        } else {
                            newData.push({
                                id: e._id,
                                type: "visible",
                                value: false,
                                retain
                            });
                        }
                        return null;
                    } else {
                        if (data.type in e) {
                            if (e._id === data.id) {
                                newData.push({
                                    id: e._id,
                                    type: "draw_value",
                                    value: data.value,
                                    retain
                                });
                                newData.push({
                                    id: e._id,
                                    type: "visible",
                                    value: true,
                                    retain
                                });
                            }
                        }
                        return null;
                    }
                });
        } else if (
            data.group === "presentation_logo" ||
            data.group === "end_customer_logo"
        ) {
            current.elements
                .filter((e: any) => e.group === data.group)
                .map((e: any) => {
                    if (data.type === "fill") {
                        if (data.type in e) {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain
                            });
                        } else {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain
                            });
                        }
                    }
                    return null;
                });
        } else {
            newData = data;
        }

        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postUpdateSlideProperty(
                baseUrl,
                newData,
                current._id,
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status }: any) => {
                    if (status === 200) {
                        const computeData = Array.isArray(newData)
                            ? newData
                            : [newData];

                        const elements = current.elements.map((e: any) => {
                            let nn: any = {};
                            let flag = false;
                            computeData.map((f: any) => {
                                if (f.id === e._id) {
                                    nn = { ...e, ...nn, [f.type]: f.value };
                                    flag = true;
                                }
                                return null;
                            });
                            if (flag) return nn;
                            return e;
                        });

                        const newslide: any = sslides.map((s: any) => {
                            if (s._id === current._id) {
                                return { ...s, p: 1, elements };
                            }
                            return s;
                        });
                        setSlides(newslide);
                        // changeCurrent({ ...current, elements });
                        setCurrent({ ...current, elements });
                        athumbGenerator(activeSlide, 500);
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
        // thumbGenerator(false, false, 100);
        // setrenderCount(renderCount + 1);
    }

    function fetchBackgroundImages(data: any = {}, flag: boolean = true) {
        setLoading(1);
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            getBackgroundImages(baseUrl, clientID, extraKey, accesskey, data, contactID)
                .then(({ status, data }: any) => {
                    if (status === 200) {
                        setBackgroundview(flag);

                        const obj: any = backgroundSortFolderAndImage(data)
                        setBackgroundImageList(obj);
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchUpdateTitle(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postUpdateTitle(baseUrl, data, clientID, extraKey, accesskey)
                .then(({ status }: any) => {
                    if (status === 200) {
                        setPresentationTitle(data.title);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchPostTemplateChange(data: any) {
        setLoading(1);
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postLoadProductTemplate(
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                data
            )
                .then(({ status, data, elements }: any) => {
                    if (status === 200) {
                        let updateElement: any = [];
                        data.map((d: any) => {
                            updateElement[d._id] = d;
                            return null;
                        });
                        const newSlide: any = sslides.map((ss: any) => {
                            if (updateElement[ss._id]) {
                                return updateElement[ss._id];
                            }
                            return ss;
                        });
                        setElement(elements);
                        setSlides(newSlide);
                        if (newSlide && newSlide.length) {
                            // changeCurrent(newSlide[0]);
                            setCurrent(newSlide[0]);
                        }
                        setrenderCount(renderCount + 1);
                        // thumbGenerator(false, false, 100);
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchpostProductElementInfo(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postProductElementInfo(baseUrl, clientID, extraKey, accesskey, data)
                .then(({ status }: any) => {
                    if (status === 200) {
                        if (data.pillow) {
                            if (data.element_change.element_id === 'colors') {
                                setElement({
                                    ...element,
                                    colors: {
                                        ...element.colors,
                                        value: data.element_change.value
                                    }
                                });
                            }
                            if (data.element_change.element_id === 'style') {
                                setElement({
                                    ...element,
                                    style: {
                                        ...element.style,
                                        applied: data.element_change.applied,
                                        value: data.element_change.value
                                    }
                                });
                            }
                            if (data.element_change.element_id === 'product_highlight') {
                                if (data.element_change.name === 'status') {
                                    setElement({
                                        ...element,
                                        product_highlight: {
                                            ...element.product_highlight,
                                            status: data.value
                                        }
                                    })
                                } else {
                                    setElement({
                                        ...element,
                                        product_highlight: {
                                            ...element.product_highlight,
                                            applied: data.element_change.applied,
                                            value: data.element_change.value
                                        }
                                    });
                                }
                            }
                            // setElement({ ...element, style: data.element_change })
                        } else {
                            const newELement: any = element.map((ele: any) => {
                                if (ele._id === data.id) {
                                    return { ...ele, value: data.value };
                                }
                                return ele;
                            });
                            setElement(newELement);
                        }
                        thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchpostProductDesignInfo(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postProductDesignInfo(baseUrl, clientID, extraKey, accesskey, data)
                .then(({ status }: any) => {
                    if (status === 200) {
                        const result: any = design.map((element: any) => {
                            if (element._id === data.id) {
                                return { ...element, value: data.value };
                            }
                            return element;
                        })
                        setDesign(result);
                        thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchEcqupdate(data: any) {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postEcqupdate(baseUrl, clientID, extraKey, accesskey, data)
                .then(({ status, resp, productReferenceData: PRD }: any) => {
                    if (status === 200) {
                        const newSlides: any = sslides.map((ss: any) => {
                            if (ss._id === data.slide) {
                                ss.elements = resp;
                                return ss;
                            }
                            return ss;
                        });
                        setSlides(newSlides);
                        // changeCurrent(newSlides[activeSlide || 0]);
                        setCurrent(newSlides[activeSlide || 0]);
                        if (PRD.products && PRD.products.length) {
                            const newResetEcqData = ecqFormatting(PRD.products);
                            setECQeditaData(newResetEcqData);
                            setProductImages(PRD.products);
                        }
                        athumbGenerator(activeSlide, 500);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchResetApi(data: any) {
        data.theme = activetheme
        setLoading(1);
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            postReset(baseUrl, clientID, extraKey, accesskey, data)
                .then(({ status, data }: any) => {
                    if (status >= 200 && status < 300) {
                        setLoading(0);
                        if (data.length) {
                            if (current) {
                                const elements: any = [];
                                current.elements.map((c: any) => {
                                    let m = false;
                                    data.map((d: any) => {
                                        if (d._id === c._id) {
                                            m = true;
                                            elements.push({ ...c, ...d });
                                        }
                                        return null;
                                    });
                                    if (!m) elements.push(c);
                                    return null;
                                });
                                const newSLides: any = sslides.map((s: any) => {
                                    if (current._id === s._id) {
                                        return { ...s, elements };
                                    }
                                    return s;
                                });
                                setSlides(newSLides);
                                athumbGenerator(activeSlide, 500);
                                // changeCurrent({ ...current, elements });
                                setCurrent({ ...current, elements });
                            }
                        }
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    }

    function fetchThemes() {
        const { type } = props;
        const accessToken: any = validationToken(type);
        accessToken.then(({ accesskey }: any) => {
            getThemes(baseUrl, clientID, extraKey, accesskey, type)
                .then(({ status, data }: any) => {
                    if (status >= 200 && status < 300) {
                        setthemes(data)
                    }
                })
                .catch((err: any) => {
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        })
    }

    function fetchThemeUpdate(theme_id: any) {
        const { type } = props;
        let layoutType: any = 0;
        template.map(({ _id, template_type }: any) => {
            if (_id === templateId) layoutType = template_type;
            return null;
        })
        const accessToken: any = validationToken(type);
        setLoading(1);
        const data = {
            presentation_type: type,
            presentation_id: presentationId,
            theme_id,
            template_type: layoutType,
            image_slide_2_order_no: imageSlide2
        };
        accessToken.then(({ accesskey }: any) => {
            postSwitchTheme(baseUrl, clientID, extraKey, accesskey, data)
                .then(({ status, slides, ...resp }) => {
                    if (status >= 200 && status < 300) {
                        const {
                            presentation_background,
                            presentation_display_name,
                            presentation_logo,
                            end_customer_logo,
                            look_and_feel,
                            _id,
                            product_template_id,
                            product_slide_elements = [],
                            reference_id,
                            presentation_original_name,
                            presentation_format,
                        } = resp;

                        setReferencedid(reference_id);
                        setPresentationoriginalname(
                            presentation_original_name
                        );
                        setPresentationformat(presentation_format);

                        setElement(product_slide_elements);
                        setTemplateId(product_template_id);
                        setPresentationId(_id);
                        if (look_and_feel && look_and_feel.font_name) {
                            setPresentationFont(
                                look_and_feel.font_name
                            );
                        }
                        setPresentationBg(
                            `#${presentation_background}`
                        );
                        setPresentationTitle(presentation_display_name);
                        setPresentationLogo({
                            ...presentation_logo,
                            name: "Show Debtor logo",
                        });
                        setEditorLogo({
                            ...end_customer_logo,
                            name: "Show End customer logo",
                        });
                        setLook(look_and_feel);
                        setImageSlide2({ order_no: null, flag: false });
                        setSlides(slides);
                        thumbGenerator(false, false, 100);
                        if (activeSlide !== null) {
                            // changeCurrent(slides[activeSlide]);
                            localMenuSlideEditValueUpdate(slides[activeSlide], 'background_image')
                            setCurrent(slides[activeSlide]);
                        }
                        // setthumbload(true);
                        setLoading(0);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });

            getglobalSetting(baseUrl, clientID, extraKey, accesskey, {
                theme_id,
                presentation_type: type,
            })
                .then(({ status, data }) => {
                    if (status >= 200 && status < 300) {
                        const { global_menu = undefined, fonts = [] } = data;
                        const setting: any = {};
                        global_menu && global_menu.map((res: any) => {
                            const { name } = res;
                            setting[name] = res;
                            return null;
                        });
                        setfonts(fonts);
                        setlocalsettingcopy(setting)
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });

        })
    }

    async function fetchPresentationUpdate(data: any) {
        try {
            const { accesskey }: any = await validationToken(props.type);
            const { status }: any = await postPresentationUpdate(baseUrl, clientID, extraKey, accesskey, data, presentationId);
            if (status === 200) {
                setMargin(data.margin);
                return { status }
            }
        } catch (e) {
            const msg = catcchErrorHandling(e);
            setError(msg);
        }
    }

    useEffect(() => {
        let interval: any = null;
        if (isActive) {
            interval = window.setInterval(() => {
                if (activeSlide !== null) {
                    let no = activeSlide + 1;
                    if (no === sslides.length) {
                        setIsActive(false);
                        setActiveSlide(0);
                    } else {
                        setActiveSlide(activeSlide + 1);
                    }
                }
            }, timer);
        } else if (!isActive) {
            window.clearInterval(interval);
        }
        if (activeSlide !== null && sslides[activeSlide]) {
            const { name = "" }: any = sslides[activeSlide];
            if (name === "Image Slide")
                localMenuSlideEditValueUpdate(sslides[activeSlide], 'background_image')
            setCurrent(sslides[activeSlide])
        }
        return () => window.clearInterval(interval);
    }, [isActive, activeSlide, sslides]);
    useLayoutEffect(() => {
        props.fonts.map((ff: any) => {
            if (ff.font_family === presentationFont) {
                loadFonts({ ...ff, baseUrl });
            }
            return null;
        });
    }, [presentationFont, props.fonts, baseUrl]);
    useEffect(() => {
        if (activeSlide) {
            if (sslides[activeSlide]) {
                // changeCurrent(sslides[activeSlide], true);
                setCurrent(sslides[activeSlide]);
            } else {
                const active = activeSlide - 1;
                setActiveSlide(active);
                changeCurrent(sslides[active], true);
                // setCurrent(sslides[active]);
            }
        }
    }, [activeSlide, sslides, changeCurrent]);

    /* 
     * * SLIDE DELETE
     * ==============================
     * \__ Confirm defore deleting slide
     *   |__ Confirm alert message yes / no option
     *   |__ Only slide in presention can't prevent from deleting
     */
    function onDeleteItem(id: any) {
        if (sslides.length > 1) {
            if (!window.confirm("Are you sure, you want to delete this slide?")) return null;
            fetchDeleteSlideRemove(id);
        } else alert(`You can't delete all slide from presentation.`);
    }

    //* ADD SLIDE BELOW ACTIVE SLIDE
    function onAddImageSlide() {
        fetchAddSlide({
            presentation: presentationId,
            presentation_type: props.type,
            name: `Image Slide`,
            order_no: !activeSlide ? 2 : 2 + activeSlide,
        });
    }

    //* SLIDE VISIBLITY
    function onVisibleItem(id: any, visible: Boolean) {
        fetchSlideVisibleChange(id, visible);
    }

    //* LOGO PRESENTATION
    function onPresentationLogoChange(data: any) {
        fetchPresentationLogoUpdate(data);
    }

    //* LOGO CUSTOMER
    function onCustomerLogoChange(data: any) {
        fetchCustomerLogoUpdate(data);
    }

    //* LOOK AND FEEL
    function onLookAndFeelChange(look_and_feel: any) {
        fetchLookAndFeelUpdate(look_and_feel);
    }

    //* SLIDE UPDATE
    function onSlideUpdate(slide_update: any) {
        fetchSlidePropertyUpdate(slide_update);
    }

    //* SLIDE UPDATE
    function onBackgroundChange() {
        fetchBackgroundImages('/');
    }

    //* PRODUCT PRODUCT IMAGE CHANGE
    function onProductImageChange(obj: any) {
        setLocalEdit(obj);
        setBackgroundview(true);
        let displayImage: any = "";
        productImages.map((pi: any) => {
            if (pi.product_id === obj.product_id) {
                if (obj.group === "decorator_product_image" && pi.artwork_design) {
                    displayImage = [...pi.images, pi.artwork_design];
                } else {
                    displayImage = pi.images;
                }
            }
            return null;
        });
        const pimg: any = displayImage.map((img: any) => {
            return {
                name: img,
                thumb: img,
                url: img,
            };
        });
        // setProductImages(pimg);
        setBackgroundImageList(pimg);
    }

    function onTitleChange(title: string) {
        fetchUpdateTitle({
            presentation: presentationId,
            title,
        });
    }

    function onTemplateChange(product_template: any, slide_id: any = null) {
        fetchPostTemplateChange({
            presentation: presentationId,
            product_template,
            presentation_type: props.type,
            slide: slide_id
        });
    }

    function onElementChangge(element_change: any) {
        if (element_change.element_id === "colors") {
            fetchpostProductElementInfo({
                pillow: true,
                element_change,
                presentation: presentationId,
                id: element_change._id,
                value: element_change.value,
            });
        } else if (
            element_change.element_id === 'style' ||
            element_change.element_id === 'product_highlight'
        ) {
            if (element_change.name === "status") {
                fetchpostProductElementInfo({
                    pillow: true,
                    element_change,
                    presentation: presentationId,
                    id: element_change._id,
                    value: element_change.status,
                });
            } else {
                fetchpostProductElementInfo({
                    pillow: true,
                    element_change,
                    presentation: presentationId,
                    id: element_change._id,
                    value: element_change.value,
                });
            }
        } else
            fetchpostProductElementInfo({
                presentation: presentationId,
                id: element_change._id,
                value: !element_change.value,
            });
    }
    function onDesignChangge(element_change: any) {
        fetchpostProductDesignInfo({
            presentation: presentationId,
            id: element_change._id,
            value: element_change.value,
        });
    }

    function onEcqUpdate(obj: any) {
        const presentation = presentationId;
        const slide = current ? current._id : "";
        const presentation_type = props.type;

        // console.log(templateId, template)
        let productTemplateName = '';
        template.map((tmp: any) => {
            if (tmp._id === templateId) {
                productTemplateName = tmp.name;
            }
            return null;
        })

        if (obj.reset_to_default) {
            const data = {
                presentation,
                slide,
                presentation_type,
                productTemplateName,
                ...obj,
            };
            fetchEcqupdate(data);
            return null;
        }
        const data = {
            presentation,
            slide,
            presentation_type,
            productTemplateName,
            data: { ...obj },
        };
        fetchEcqupdate(data);
    }

    function onResetApi(obj: any) {
        fetchResetApi({
            presentation: presentationId,
            presentation_type: props.type,
            ...obj,
        });
    }

    function onSave() {
        const obj = {
            presentation_background: "ffff0f",
            slides: sslides,
        };
        if (props.clientID) {
            const { baseUrl, clientID, extraKey, accesskey } = props;
            saveDefaultTheme(baseUrl, clientID, extraKey, accesskey, obj).then(
                (res: any) => {
                    console.log(res);
                }
            );
        }
    }

    const settingViewUI = () => {
        if (!settingView) fetchThemes();
        setSettingView(!settingView);
    };

    function colorPickerUI(obj: any) {
        setBgActive(obj);
        setColorpickview(true);
    }

    function colorPickerCompUI() {
        setColorpickview(false);
    }

    function EditView() {
        if (current) {
            if ("product_id" in current) {
                // ** Since older slides doesnot have product_no so using product_id for older slides
                let key = current["product_no"] ? "product_no" : "product_id";
                const productSLide = ecqEditData.filter(
                    (c: any) => c[`${key}`] === current[`${key}`]
                )[0];
                const {
                    data,
                    more_total,
                    price_format,
                    prodict_id,
                    total,
                } = productSLide;

                const defaultValue = ecqDefaultData.filter(
                    (c: any) => c[`${key}`] === current[`${key}`]
                )[0];
                return (
                    <Editable
                        ecqdata={data}
                        defaultdata={defaultValue["data"]}
                        defaultmore={defaultValue["more_total"]}
                        setView={setECQView}
                        currency={price_format} // currency
                        product_id={prodict_id}
                        product_no={current["product_no"]}
                        more={more_total}
                        total={total}
                        onUpdate={onEcqUpdate}
                    />
                );
            }
        }
        return null;
    }

    const localmenuCloseForothereComponent = () => {
        const element = document.getElementById("localani");
        if (element) {
            // element?.classList.remove("active");
            setTimeout(() => {
                setLocalmenu(false);
            }, 600);
        }
    };

    //TODO : improve this PDF start
    const testobj = {
        presentationFont,
        presentationLogo,
        editorLogo,
        look,
        element,
    };
    useEffect(() => {
        if (sslides.length) {
            thumbGenerator(thumbload, false, 200);
            setthumbload(true);
        }
    }, [sslides, testobj, thumbload]);
    useEffect(() => {
        const selection: any = document.querySelector(".main.board");
        const pdfTarget: any = selection.cloneNode(true);
        if (pdfCreating) {
            const pdfReq: any = [];
            setLoading(1);
            setTimeout(() => {
                let hoverButton: any = pdfTarget.querySelectorAll(".editbackgroundimage");
                /* let threeD: any = pdfTarget.querySelectorAll(".threeDmodelwrap");
                if (threeD.length) {
                    for (let zz = 0; zz < threeD.length; zz++) {
                        if (threeD[zz]) {
                            threeD[zz]?.parentNode?.removeChild(threeD[zz]);
                        }
                    }
                }
                */
                if (hoverButton.length) {
                    for (let zz = 0; zz < hoverButton.length; zz++) {
                        if (hoverButton[zz]) {
                            hoverButton[zz]?.parentNode?.removeChild(
                                hoverButton[zz]
                            );
                        }
                    }
                }
                let gene: any = pdfTarget.querySelectorAll(
                    ".main.board > div > div"
                );
                const geneArray = Array.prototype.slice.call(gene);

                if (geneArray && geneArray.length) {
                    geneArray.forEach((g: any, index: any) => {
                        const type =
                            (g?.firstChild).getAttribute("data-title") ||
                            "No title fetched";
                        const obj = {
                            order: index + 1,
                            type,
                            html: g && g.innerHTML, // && ((g).innerHTML).replace(/"/g, "\\")
                        };
                        // console.log(g.innerHTML);
                        document.getElementById("testpdf")?.append(g);

                        if (sslides[index]) {
                            const { visible = true } = sslides[index];
                            if (visible) pdfReq.push(obj);
                        }
                        return null;
                    });
                }
                // setLoading(1);
                // return null;
                // TODO: pdf generating API request goes here
                // onDownloadPDF(pdfReq);
                if (pdfReq.length) {
                    const accessToken: any = validationToken(props.type);
                    accessToken.then(({ accesskey }: any) => {
                        postPDF(baseUrl, clientID, extraKey, accesskey, {
                            presentation: presentationId,
                            contents: pdfReq,
                        })
                            .then(() => {
                                setLoading(0);
                            })
                            .catch((err: any) => {
                                setLoading(0);
                                console.log(err);
                            });
                    })
                } else {
                    setLoading(0);
                    alert(pdferrormsg)
                }
                setPdfCreating(false);
                // pdfTarget?.remove();
                // const rm = document.getElementById('testID');
                // rm?.parentNode?.removeChild(rm);
            }, 300);
        }
        return () => {
        };
    }, [
        pdfCreating,
        sslides,
        editorLogo,
        element,
        look,
        presentationBg,
        presentationFont,
        presentationLogo,
        baseUrl,
        clientID,
        extraKey,
        accesskey,
        presentationId,
        props.type,
        validationToken
    ]);

    //TODO : improve this PDF END
    function athumbGenerator(active: any, time: number = 500) {
        setTimeout(function () {
            const target: any = document.querySelector(
                `#slide-${active} .ground`
            );
            const subt: any = document.getElementById(`strip_${active + 1}`);
            const removeDiv: any = document.querySelector(
                `#stripitem_${active + 1} .testThumb`
            );
            const thumbWrap = document.createElement("div");
            if (target) {
                const cl = target.cloneNode(true);
                thumbWrap.classList.add("testThumb");
                thumbWrap.appendChild(cl);
                removeDiv.parentNode.removeChild(removeDiv);
                if (subt)
                    subt.parentNode.insertBefore(thumbWrap, subt.nextSibling);
            }
        }, time);
    }

    function thumbGenerator(flag = false, force = false, time: number = 200) {
        if (!flag) {
            setTimeout(function () {
                let w = "146px";
                let h = "81px";
                const del: any = document.querySelectorAll(".testThumb"); //.forEach(e => e.remove());
                for (let ll = 0; ll < del.length; ll++) {
                    if (del[ll]) {
                        // del[ll].remove();
                        del[ll].parentNode.removeChild(del[ll]);
                    }
                }

                let tar: any = document.querySelectorAll(".ground");
                for (let kk = 0; kk <= tar.length; kk++) {
                    if (tar[kk]) {
                        const thumbWrap = document.createElement("div");
                        const cl = tar[kk].cloneNode(true);
                        thumbWrap.classList.add("testThumb");
                        thumbWrap.appendChild(cl);

                        const dd2 = document.createElement("div");
                        dd2.style.width = w;
                        dd2.style.height = h;
                        dd2.classList.add("one");
                        thumbWrap.appendChild(dd2);

                        const subt: any = document.getElementById(
                            `strip_${kk + 1}`
                        );
                        if (subt)
                            subt.parentNode.insertBefore(
                                thumbWrap,
                                subt.nextSibling
                            );
                    }
                }
            }, time);
        }
    }

    function onPptFunction() {
        // const data: any = [];
        setLoading(1);
        const data: any[] = sslides.map((ss: any) => {
            // const { _id: _sid } = ss;
            const { elements } = ss;
            const ele: any[] = elements.map((ele: any) => {
                const { _id: _iid, element_id } = ele;
                const logos: any[] = ["presentation_logo", "end_customer_logo"];
                const lists: any[] = [
                    "decorator_product_image",
                    "product_image_1",
                    "product_image_2",
                    "product_image_3",
                    "mailbox_image", // contact slide
                ];
                let options: any = {};
                if (lists.includes(element_id)) {
                    const product: any = document.querySelector(
                        `.img-${_iid} img`
                    );
                    options = {
                        width: product?.offsetWidth || 0,
                        height: product?.offsetHeight || 0,
                    };
                    return { ...ele, options };
                } else if (logos.includes(element_id)) {
                    let logo: any = document.querySelector(
                        `.logo-${_iid} img`
                    );
                    options = {
                        width: logo?.offsetWidth || 0,
                        height: logo?.offsetHeight || 0,
                    };
                    return { ...ele, options };
                }
                return ele;
            });
            return { ...ss, ...{ elements: ele } };
        });

        const pptObject = {
            _id: presentationId,
            presentation_display_name: presentationTitle,
            presentation_original_name: presentationoriginalname,
            presentation_type_id: props.type,
            reference_id: referencedid,
            product_template_id: templateId,
            look_and_feel: look,
            end_customer_logo: editorLogo,
            presentation_logo: presentationLogo,
            presentation_format: preentationformat,
            slides: data,
        };
        // console.info(pptObject);
        postPTT(baseUrl, clientID, extraKey, accesskey, pptObject)
            .then(() => {
                setLoading(0);
            })
            .catch((err: any) => {
                setLoading(0);
                console.log(err);
            });
    }

    function emailCopyLink() {
        const pdfReq: any = [];
        setLoading(1);
        const selection: any = document.querySelector(".main.board");
        const pdfTarget: any = selection.cloneNode(true);
        setTimeout(() => {
            let hoverButton: any = pdfTarget.querySelectorAll(
                ".editbackgroundimage"
            );
            /* let threeD: any = pdfTarget.querySelectorAll(".threeDmodelwrap");
            if (threeD.length) {
                for (let zz = 0; zz < threeD.length; zz++) {
                    if (threeD[zz]) {
                        threeD[zz]?.parentNode?.removeChild(threeD[zz]);
                    }
                }
            } */

            if (hoverButton.length) {
                for (let zz = 0; zz < hoverButton.length; zz++) {
                    if (hoverButton[zz]) {
                        hoverButton[zz]?.parentNode?.removeChild(
                            hoverButton[zz]
                        );
                    }
                }
            }
            let gene: any = pdfTarget.querySelectorAll(
                ".main.board > div > div"
            );
            const geneArray = Array.prototype.slice.call(gene);
            if (geneArray && geneArray.length) {
                geneArray.forEach((g: any, index: any) => {
                    const type =
                        (g?.firstChild).getAttribute("data-title") ||
                        "No title fetched";
                    const obj = {
                        order: index + 1,
                        type,
                        html: g && g.innerHTML, // && ((g).innerHTML).replace(/"/g, "\\")
                    };
                    // console.log(g.innerHTML);
                    // document.getElementById("testpdf")?.append(g);
                    if (sslides[index]) {
                        const { visible = true } = sslides[index];
                        if (visible) pdfReq.push(obj);
                    }
                    return null;
                });
            }
            if (pdfReq.length) {
                const accessToken: any = validationToken(props.type);
                accessToken.then(({ accesskey }: any) => {
                    postPDF(baseUrl, clientID, extraKey, accesskey, {
                        presentation: presentationId,
                        contents: pdfReq,
                        type: "link"
                    }, false)
                        .then(({ link }: any) => {
                            setpdfcopylink(link)
                            setLoading(0);
                            setemailShareFlag(true)
                        })
                        .catch((err: any) => {
                            setLoading(0);
                            console.log(err);
                        });
                })
            } else {
                setLoading(0);
                alert(pdferrormsg)
            }
        }, 300);
    }

    function onImageUpload(data: any, obj: any) {
        const accessToken: any = validationToken(props.type);
        setLoading(1);
        accessToken.then(({ accesskey }: any) => {
            postImageUpload(baseUrl, clientID, extraKey, accesskey, {
                images: data,
            }, contactID)
                .then(({ status, background }: any) => {
                    if (status === 200) {
                        if (background && background[0]) {
                            const backgroundObj = { ...obj, value: background[0].url }
                            onSlideUpdate(backgroundObj);
                        }
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    console.log(err);
                });
        })
    }

    return (
        <React.Fragment>
            <languageContext.Provider value={language}>
                <Title
                    display={display}
                    title={presentationTitle}
                    setDisplayOverview={(flag: any) => {
                        const accessToken: any = validationToken(props.type);
                        accessToken.then(() => {
                            setDisplayOverview(flag);
                        });
                    }}
                    setTitle={onTitleChange}
                    setSettingView={settingViewUI}
                    onSave={onSave}
                    onPdfFunction={setPdfCreating}
                    onPptFunction={onPptFunction}
                    onPdfFlag={pdfCreating}
                    onEmailShareflag={emailCopyLink}
                    presentationType={props.presentationType}
                />
                <div className="draw flex" data-ref={presentationId}>
                    <Story
                        baseUrl={baseUrl}
                        clientID={clientID}
                        extraKey={extraKey}
                        acckey={accesskey}
                        setLoading={setLoading}
                        setError={setError}
                        presentation={presentationId}
                        index={activeSlide}
                        sliders={sslides}
                        change={(target: any) => {
                            if (target !== activeSlide) {
                                setLocalEdit({})
                            }
                            setActiveSlide(target);
                            changeCurrent(sslides[target]);
                            // setLocalmenu(false);
                        }}
                        onPlay={setIsActive}
                        onPlayFlag={isActive}
                        onDeleteItem={onDeleteItem}
                        onVisibleItem={onVisibleItem}
                        onAddImageSlide={onAddImageSlide}
                        setSlides={setSlides}
                        setActiveSlide={setActiveSlide}
                        setCurrent={setCurrent}
                        thumbAction={() => validationToken(props.type)}
                        thumbGenerator={thumbGenerator}
                        setImageSlide2={setImageSlide2}
                    />
                    <div className="main board">
                        <boardContext.Provider
                            value={{
                                presentationFont,
                                presentationLogo,
                                editorLogo,
                                look,
                                element,
                                design,
                                margin,
                                localEdit,
                                tips,
                                colorpickview: colorPickerUI,
                                currentSlide: activeSlide !== null ? sslides[activeSlide] : null
                            }}
                        >
                            {(
                                <LocalMenu
                                    current={current}
                                    elements={current?.elements || []}
                                    localEdit={localEdit}
                                    colorpickview={colorPickerUI}
                                    onBackgroundChange={() => {
                                        fetchBackgroundImages('/Your Images', false);
                                        setBackgroundUpload(true);
                                    }}
                                    onSlideUpdate={onSlideUpdate}
                                    onResetApi={onResetApi}
                                    layout={template}
                                    templateId={templateId}
                                    onTemplateChange={onTemplateChange}
                                    setTemplateId={setTemplateId}
                                    typeName={typeName}
                                />
                            )}
                            <LayoutContext.Provider value={{ templateId, template }}>
                                {sslides.length > 0 &&
                                    sslides.map((ss: any, index: number) => {
                                        const styl: any =
                                            index === activeSlide
                                                ? ""
                                                : { display: "none" };
                                        // console.log(typeName, " = ", ss)
                                        // WIP PILLOW CHANGES
                                        // || ss.type === "product_slide"
                                        if (typeName === "pillow") {
                                            // TODO: IMAGE SLIDE
                                            // console.log(ss.type);

                                            //* PRODUCT SLIDE
                                            // if (ss.type === "title_slide")
                                            // return (
                                            //     <div
                                            //         data-slides={index}
                                            //         id={`slide-${index}`}
                                            //         style={{ ...styl }}
                                            //         key={ss._id}
                                            //     >
                                            //         <div className="ground">
                                            //             <div className={`cc slide-${ss._id}`} data-title={ss.name || ""}>
                                            //                 <TitleSlide
                                            //                     {...ss}
                                            //                     id={`slide-${ss._id}`}
                                            //                     name={ss.name || ""}
                                            //                     elements={ss.elements || []}
                                            //                     presentationLogo={presentationLogo}
                                            //                     editorLogo={editorLogo}
                                            //                 />
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            // )
                                            if (ss.type === "product_slide")
                                                return (
                                                    <div
                                                        data-slides={index}
                                                        id={`slide-${index}`}
                                                        style={{ ...styl }}
                                                        key={ss._id}
                                                    >
                                                        <div className="ground">
                                                            <div className={`cc slide-${ss._id}`} data-title={ss.name || ""}>
                                                                <PillowSlide
                                                                    {...ss}
                                                                    id={`slide-${ss._id}`}
                                                                    name={ss.name || ""}
                                                                    elements={ss.elements || []}
                                                                    onSlideUpdate={onSlideUpdate}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            if (ss.type === "contact_slide") {
                                                return (
                                                    <div
                                                        data-slides={index}
                                                        id={`slide-${index}`}
                                                        style={{ ...styl }}
                                                        key={ss._id}
                                                    >
                                                        <div className="ground">
                                                            <div className={`cc slide-${ss._id}`} data-title={ss.name || ""}>
                                                                <ContactSlide
                                                                    {...ss}
                                                                    id={`slide-${ss._id}`}
                                                                    name={ss.name || ""}
                                                                    elements={ss.elements || []}
                                                                    presentationLogo={presentationLogo}
                                                                    editorLogo={editorLogo}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        // console.log(ss);
                                        return (
                                            <div
                                                data-slides={index}
                                                id={`slide-${index}`}
                                                style={{ ...styl }}
                                                key={ss._id}
                                            >
                                                <Board
                                                    {...ss}
                                                    id={`slide-${ss._id}`}
                                                    name={ss.name || ""}
                                                    elements={ss.elements || []}
                                                    presentationLogo={presentationLogo}
                                                    editorLogo={editorLogo}
                                                    onSlideUpdate={onSlideUpdate}
                                                    setECQView={setECQView}
                                                    onProductImageChange={
                                                        onProductImageChange
                                                    }
                                                    localmenuView={(mode: any) => {
                                                        setLocalEdit(mode);
                                                        // setLocalmenu(true);
                                                    }}
                                                    currentid={current?._id || null}
                                                    currenttype={current?.type || null}
                                                    productTempType={current?.product_template_type || null}
                                                />
                                            </div>
                                        );
                                    })}

                            </LayoutContext.Provider>
                        </boardContext.Provider>
                    </div>
                </div>

                {settingView ? (
                    <settingContext.Provider
                        value={{
                            ...localsettingcopy,
                            typeName
                        }}
                    >
                        <lookandFeelContext.Provider
                            value={{
                                templateColor: props.templateColor,
                                brandColor: props.brandColor,
                                fonts,
                                lookFeel: look,
                                template,
                                onTemplateChange,
                                templateId,
                                setTemplateId,
                                setElement,
                                element,
                                design,
                                setDesign,
                                onElementChangge,
                                onDesignChangge,
                                baseUrl,
                            }}
                        >
                            <lookContext.Provider
                                value={{
                                    presentationLogo,
                                    editorLogo,
                                }}
                            >
                                <ThemeContext.Provider value={{
                                    themes,
                                    activetheme,
                                    setactivetheme,
                                    localsettingcopy,
                                    fetchThemeUpdate,
                                    fetchPresentationUpdate,
                                    margin
                                }}>
                                    <Panel
                                        settingView={settingView}
                                        setSettingView={settingViewUI}
                                        colorpickview={colorPickerUI}
                                        onPresentationLogoChange={
                                            onPresentationLogoChange
                                        }
                                        onCustomerLogoChange={onCustomerLogoChange}
                                        onLookAndFeelChange={onLookAndFeelChange}
                                    />
                                </ThemeContext.Provider>
                            </lookContext.Provider>
                        </lookandFeelContext.Provider>
                    </settingContext.Provider>
                ) : null}

                {colorpickview && (
                    <ColorPicker
                        data={bgActive}
                        localEdit={{}}
                        view={colorPickerCompUI}
                        onPresentationLogoChange={onPresentationLogoChange}
                        onCustomerLogoChange={onCustomerLogoChange}
                        onLookAndFeelChange={onLookAndFeelChange}
                        onSlideUpdate={onSlideUpdate}
                        elements={current && (current.elements || [])}
                        localmenuClose={() => localmenuCloseForothereComponent()}
                        settingColor={{
                            element,
                            setElement,
                            onElementChangge
                        }}
                    />
                )}
                {backgroundView && (
                    <BackgroundImage
                        localEdit={localEdit}
                        view={setBackgroundview}
                        backgroundImageList={backgroundImageList}
                        onSlideUpdate={onSlideUpdate}
                        elements={current && (current?.elements || [])}
                        localmenuClose={() => localmenuCloseForothereComponent()}
                        slideType={current?.type || ''}
                        breadcrumb={{
                            list: bread,
                            setbread,
                            fetch: fetchBackgroundImages
                        }}
                    />

                )}

                {backgroundUpload && (
                    <BackgroundSelect
                        localEdit={localEdit}
                        view={setBackgroundUpload}
                        onBackgroundSelect={onBackgroundChange}
                        backgroundImageList={backgroundImageList.length || 0}
                        onSlideUpdate={onSlideUpdate}
                        elements={current && (current?.elements || [])}
                        localmenuClose={() => localmenuCloseForothereComponent()}
                        slideType={current?.type || ''}
                        imageUpload={onImageUpload}
                    />
                )}

                {isLoading !== 0 && (
                    <div className="loaderWrapper">
                        <div className="loader"></div>
                    </div>
                )}
                {ecqView && (
                    <EditEcqContext.Provider
                        value={{
                            ...look,
                        }}
                    >
                        {EditView()}
                    </EditEcqContext.Provider>
                )}
                {emailshareflag && (
                    <EmailShare
                        pid={presentationId}
                        onPdfFunction={setPdfCreating}

                        closeEmailShare={() => {
                            setemailShareFlag(false)
                        }}
                        pdfurl={pdfcopylink}
                    />
                )}
                {error && false && (
                    <ErrorBox error={error} close={() => setError("")} />
                )}
            </languageContext.Provider>
        </React.Fragment>
    );
};
export default Presentation;
