import React, { useContext, useState, useEffect } from 'react';

import boardContext from '../../context/BoardContext';
import languageContext from '../../context/languageContext';
import TextLine from './TextLine';
import iconEdit from '../../assets/icons/icon-pencil.png';
import { TextsProp } from './shape';

const Text = (props: TextsProp) => {
  const { _id, draw_value, color, visible, element_id, font_size, height, width,
    x_pos, y_pos, onSlideUpdate, text_align, slideType, font_weight = 'normal',
  } = props;
  let { allow_translation = true } = props;
  const context: any = useContext(boardContext);
  const { limit_29, limit_58, limit_90, ...translate }: any = useContext(languageContext);

  const [mode, setMode] = useState(false);
  const [textValue, setTextValue] = useState(draw_value || '');
  const [validSingleLine, validTextLength, ecqproductnamelimit] = [58, 29, 90];

  const translationvalue = translate[`${element_id}`] || '';

  useEffect(() => {
    // if (props.hasOwnProperty('allow_translation')) {
    if (!allow_translation) {
      setTextValue(draw_value);
    } else {
      const vv = translationvalue || draw_value;
      setTextValue(vv);
    }
  }, [draw_value, allow_translation, translationvalue])

  // font_name, 
  let fontFamily = '';
  let globalFontColor = '';
  if (context.presentationFont) {
    fontFamily = context.presentationFont;
    globalFontColor = context.look.font_color || ''
  }

  if (!visible) return null;

  const style = {
    height,
    width,
    fontFamily,
    // fontFamily: font_name, // TODO:: disucssed for now not to use this
    fontSize: `${font_size}px`,
    color: color || globalFontColor,
    left: x_pos,
    top: y_pos,
    text_align: text_align,
    fontWeight: font_weight,
  };
  const style2 = { width: 'auto' };

  const noLocal = () => {
    setMode(true);
    setTextValue(draw_value);
    invalid();
  };

  const doubleClick = () => {
    if (
      element_id !== "textbox_left" &&
      element_id !== "textbox_left_zip" &&
      element_id !== "textbox_left_city" &&
      element_id !== "textbox_left_country" &&
      element_id !== "textbox_left_tel" &&
      element_id !== 'textbox_right' &&

      element_id !== 'country_of_origin_label' &&
      element_id !== 'brand_label' &&
      element_id !== 'material_label' &&
      element_id !== 'weight_label' &&
      element_id !== 'diameter_label' &&
      element_id !== 'height_label' &&
      element_id !== 'width_label' &&
      element_id !== 'length_label' &&
      element_id !== 'product_detail_title' &&
      element_id !== 'available_colors_title' &&
      element_id !== 'company_name' &&
      true
    ) {
      textClick();
    }
    setMode(true);
    hoverCsseffectremove();
    if (props.hasOwnProperty('allow_translation'))
      setTextValue(textValue);
    else
      setTextValue(draw_value);
    setTimeout(function () {
      const ele = document.getElementById('texttext');
      ele?.focus();
    }, 200)
  }
  const focushere = (e: any) => {
    if (textValue === undefined && draw_value === undefined) return null;
    const len = textValue.length || draw_value.length;
    e.target.selectionStart = len;
    e.target.selectionEnd = `${len}`;
  }
  const textClick = () => {
    // const newlist = [
    //   'date_value',
    //   'product_name',
    // ];
    // if (!newlist.includes(element_id)) {
    //   localmenuView({ _id, element_id, color, mode: true, slideType })
    // }
  };
  const onKeyDown = (e: any) => {
    const { keyCode, ctrlKey } = e;

    if (
      element_id === "textbox_left" ||
      element_id === "textbox_left_zip" ||
      element_id === "textbox_left_city" ||
      element_id === "textbox_left_country" ||
      element_id === "textbox_left_tel" ||
      element_id === 'textbox_right'
    ) {
      return null;
    }

    const lines = textValue.split('\n').length;
    if (keyCode === 13 && lines > 1) {
      e.preventDefault()
      return false;
    }
    if (ctrlKey && keyCode === 13) {
      submitfunction();
    }
  };
  function onLeave() {
    submitfunction();
  }
  function submitfunction() {
    if (textValue === undefined) return null;
    const lines = textValue.split('\n');
    let valid = false;
    let errMsg = limit_58; // `You are only allowed to enter ${validSingleLine} letters.`;

    if (element_id === 'product_name' && slideType === 'product_slide') {

      if (textValue.length <= ecqproductnamelimit) {
        setTextValue(textValue)
        valid = true;
      } else {
        errMsg = limit_90; // `You are only allowed to enter ${ecqproductnamelimit} letters in a line.`;
        setTextValue(draw_value)
      }
    } else {
      if (lines.length === 1) {
        valid = lines[0].length <= validSingleLine;
      } else {
        valid = lines.every((l: any) => l.length <= validTextLength);
        if (!valid)
          errMsg = limit_29; // `You are only allowed to enter ${validTextLength} letters in a line.`;
      }
    }

    if (valid) {
      if (props.hasOwnProperty('allow_translation')) {
        onSlideUpdate([
          { id: _id, type: 'draw_value', value: textValue },
          { id: _id, type: 'allow_translation', value: false }
        ]);
      } else {
        if (draw_value !== textValue)
          onSlideUpdate({ id: _id, type: 'draw_value', value: textValue });
      }
      setMode(false);
    } else {
      /**
       * * setTextValue is called and set old value
       * * to avoid alert loop message
       */
      setTextValue(draw_value);
      invalid(errMsg);
    }
  }
  function invalid(text?: any) {
    setTimeout(function () {
      const ele = document.getElementById('texttext');
      if (text)
        alert(`${text}`)
      ele?.focus();
    }, 200)
  }
  const handleChange = (e: any) => {
    if (
      element_id === "textbox_left" ||
      element_id === "textbox_left_zip" ||
      element_id === "textbox_left_city" ||
      element_id === "textbox_left_country" ||
      element_id === "textbox_left_tel" ||
      element_id === 'textbox_right'
    ) {
      setTextValue(e.target.value);
      return null;
    }
    const lines = textValue.split('\n').length;
    if (lines < 3) {
      setTextValue(e.target.value);
    }
  };

  // const flagSlideType = slideType === 'image_slide';
  // const eleTitleTextBg = element_id === 'title_text_background';
  const compareSlideValues = [
    'test'
  ];
  // 'brand_value',
  // 'material_value',
  // 'color_label',
  // 'length_value',
  // 'width_value',
  // 'height_value',
  // 'weight_value',
  // 'country_of_origin_value',
  // 'recommended_decoration_option_value',
  // 'default_print_location_value',
  if (compareSlideValues.includes(element_id)) {
    return (
      <div className="titletext absolute" style={style}>
        <div className="text Wrap" id="textContainer1" style={{ ...style2, width: '100%' }}>
          <TextLine text={draw_value} align={text_align} slideType={slideType} />
        </div>
      </div>
    );
  }
  const newlist = [
    'date_value',
    'product_name',
    'article_value',
    'method_value',
    'location_value',
    'size_value',
    'pms_value',
    'color_value',
  ];
  if (newlist.includes(element_id)) {
    let textcss = {}, textWrapCss = {};
    if (element_id === 'product_name' && slideType === 'product_slide') {
      textcss = { whiteSpace: 'nowrap' };
      textWrapCss = { overflow: 'hidden' };
    }
    return (
      <div className="titletext absolute nofocus" style={style}>
        {!mode ? (

          <div className="textWrap" id="textContainer1" style={{ ...style2, width: '100%', ...textWrapCss }}
            onClick={noLocal}
          >
            <TextLine text={draw_value} align={text_align} noWrapstyle={{ ...textcss }} slideType={slideType} />
          </div>
        ) : (
          <input
            type="text"
            name="textarea"
            id="texttext"
            defaultValue={textValue}
            style={{
              ...style,
              width: '100%',
              height: '13px',
            }}
            onBlur={onLeave}
            onFocus={focushere}
            onChange={handleChange}
          />
        )}
      </div>
    )
  }
  function hoverCsseffect() {
    if (slideType === 'title_slide') {
      let tt: any = document.getElementsByClassName('textfix');
      if (tt.length) {
        tt[0].classList.add('finalAction');
      }
    }
    if (slideType === 'image_slide') {
      let tt: any = document.getElementsByClassName('imageTextFix');
      if (tt.length) {
        tt[0].classList.add('ImagefinalAction');
      }
    }
  }
  function hoverCsseffectremove() {
    if (slideType === 'title_slide') {
      let tt: any = document.getElementsByClassName('textfix');
      if (tt.length) {
        tt[0].classList.remove('finalAction');
      }
    }
    if (slideType === 'image_slide') {
      let tt: any = document.getElementsByClassName('imageTextFix');
      if (tt.length) {
        tt[0].classList.remove('ImagefinalAction');
      }
    }
  }

  const checkeleIdIstextbox_left = element_id === 'textbox_left';

  let newchangedTest = translate[`${element_id}`] || textValue;
  if (props.hasOwnProperty('allow_translation')) {
    if (!allow_translation)
      newchangedTest = textValue;
  }

  let newfontchangecss = '';
  if (
    element_id === 'title_text'
  ) {
    newfontchangecss = '' // font-gotham-black
  }
  // if (
  //   element_id === 'title_text' && slideType === 'title_slide'
  // ) {
  //   newfontchangecss = 'font-gotham-black '
  // }
  if (
    element_id === 'total_price_bar' ||
    element_id === 'unit_price_bar' ||
    element_id === 'quantity_bar' ||
    element_id === 'description_bar' ||
    element_id === 'price_per_product_label_text'
  ) {
    newfontchangecss = 'font-gotham-book'
  }

  return (
    <div className={`titletext absolute ${newfontchangecss}`} style={style}>
      {!mode ? (<>
        <div className="textWrap" id="textContainer" onClick={doubleClick} style={style2}
          onMouseEnter={hoverCsseffect}
          onMouseLeave={hoverCsseffectremove}
        >
          <TextLine text={newchangedTest} align={text_align} slideType={slideType} />
        </div>{(slideType === "title_slide" || slideType === "image_slide") && (
          <button className="icon_pencil_title_text" onClick={() => {
            context.colorpickview({
              color,
              id: _id,
              name: element_id
            })
          }}>
            <img src={`${iconEdit}`} alt="edit" />
          </button>
        )}
      </>
      ) : (
        <div className="textWrap">
          <textarea
            name="textare"
            id="texttext"
            className={checkeleIdIstextbox_left ? 'fullHeight' : ''}
            onFocus={focushere}
            onKeyDown={onKeyDown}
            onBlur={onLeave}
            onChange={handleChange}
            defaultValue={newchangedTest}
          />
          {/* <span style={{ fontSize: '11px', color: '#fff' }}>
            ctrl + enter 
          </span> */}
        </div>
      )}
    </div>
  );
};
export default Text;
