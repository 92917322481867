// export const base = "api/notifications";
// export const base = 'http://10.0.3.149:1320/api/notifications';
// export const base = "http://10.0.2.63:30081";
// export const base = window.CommApp ? window.CommApp.config.baseUrl : '';
const baseUrl = "api/presentationTool/";

export default {
    //* GET ACCESS TOKENS
    accesskey: `${baseUrl}getAccesskey`,
    regenerateAccesskey: `${baseUrl}regenerateAccessKey`,
    //* OVERVIEW
    getGetAll: `${baseUrl}presentationType/getAll`,
    getUserPresentations: `${baseUrl}presentation/getUserPresentations`,
    removePresentation: `${baseUrl}presentation/removePresentation`,

    //* PRESENTATION DETAIL
    getUserPresentationDetail: `${baseUrl}presentation/getUserPresentationDetail`,

    //* TITLE
    postUpdateTitle: `${baseUrl}presentation/updateTitle`,

    //* SLIDE STRIP
    postChangeSlideVisibility: `${baseUrl}presentation/changeSlideVisibility`,
    deleteRemoveSlide: `${baseUrl}presentation/removeSlide`,

    //* LOGO
    postSavePresentationLogoInfo: `${baseUrl}presentation/savePresentationLogoInfo`,
    postSaveCustomerLogoInfo: `${baseUrl}presentation/saveCustomerLogoInfo`,
    postAddImageSlide: `${baseUrl}presentation/addImageSlide`,

    //* LOOK & FEEL
    postLookFeel: `${baseUrl}presentation/saveLookandFeel`,
    //* TEMPLATE
    postLoadProductTemplate: `${baseUrl}defaultTheme/loadproductTemplate`,
    getProductTemplate: `${baseUrl}defaultTheme/getProductTemplates`,
    //* ELEMENTS
    postProductElementInfo: `${baseUrl}presentation/saveProductElementInfo`,
    //* DESIGNs
    postProductDesignInfo: `${baseUrl}presentation/saveProductDesignInfo`,

    //* UPDATE SLIDE
    postUpdateSlideProperty: `${baseUrl}presentation/updateSlideElement`,

    //* BACKGROUND IMAGES
    getBackgroundImages: `${baseUrl}assets/getBackgroundImages`,

    //* ADMIN CALL / NOT REQUIRED
    postLoadDefaultTheme: `${baseUrl}defaultTheme/loadDefaultTheme`,
    getDefaultTheme: `${baseUrl}defaultTheme/getDefaultTheme`,
    saveDefaultTheme: `${baseUrl}defaultTheme/saveDefaultTheme`,

    //* EDITABLE
    postEcqUpdate: `${baseUrl}presentation/updateEcqItems`,

    //* PDF
    postPDF: `${baseUrl}presentation/createpdf`,
    //* PTT
    postPTT: `${baseUrl}presentation/createppt`,

    //* REARRANGE
    postReArrange: `${baseUrl}presentation/rearrangeSlide`,
    //* RESET
    postReset: `${baseUrl}presentation/resetElement`,
    //* THEME
    getThemes: `${baseUrl}presentation/getThemes`,
    postSwitchTheme: `${baseUrl}presentation/switchTheme`,
    getGlobalSetting: `${baseUrl}features/getglobalSettings`,

    //* SHARE
    postEmailShare: `${baseUrl}presentation/emailAndShare`,
    postPresentationUpdate:`${baseUrl}presentation`,

    //* UPLOAD BACKGROUND IMAGE
    postImageUpload: `${baseUrl}file/backgroundImages/upload`,

    // TIPS GET ALL
    getAllTips: `${baseUrl}tips/get-all`
};
