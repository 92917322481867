import React, { useState, useContext, useRef, useEffect } from 'react';
import languageContext from '../../../context/languageContext'

export default function PTInput({ element, onSlideUpdate, cssStyle = '', style = {}, fallbackText = false }: any) {
    
    const { _id = 0, draw_value = '', text_align, element_id, allow_translation = false } = element;
    // font_size = 10, font_name, font_weight, 
    const lang: any = useContext(languageContext);

    const [edit, setEdit] = useState(true);
    const val = allow_translation ? lang[element_id] || draw_value : draw_value;
    const [value, setvalue] = useState(val);
    const inputRef: any = useRef(null);

    useEffect(() => {
        if (!edit)
            inputRef.current.focus()
    }, [edit])

    const inputStyle = {
        // fontSize: `${font_size}px`,
        // fontWeight: `${font_weight}`,
        // fontFamily: font_name || '',
        // lineHeight: '16px',
        // background: 'none',
        // color: '#fff',
        textAlign: text_align,
        ...style
    }

    function toggleEdit(flag: boolean) {
        setEdit(flag);
        setTimeout(function () {
            const ele = document.getElementById('texttext');
            ele?.focus();
        }, 200)
    }

    function onLeave() {
        setTimeout(function () {
            toggleEdit(true);
        }, 200)
        /**
         * Condition that value is different 
         * make API call
         */
        if (draw_value !== value) {
            /*
             * After editing translated label/ text
             * edited text should be displayed 
             * for that allow_translation to false
             */
            if (allow_translation) {
                onSlideUpdate([
                    { id: _id, type: 'allow_translation', value: false },
                    { id: _id, type: 'draw_value', value }
                ]);
            } else {
                onSlideUpdate({ id: _id, type: 'draw_value', value });
            }
        }


    }

    function focushere(e: any) {
        if (draw_value !== null) {
            e.target.selectionStart = value.length;
            e.target.selectionEnd = `${value.length}`;
        }
    }

    function handleChange(e: any) {
        setvalue(e.target.value);
    }

    return (
        <>
            {edit
                ? <>
                    <span role='button' className={`hoverFormELe ${cssStyle}`} style={inputStyle} onClick={() => {
                        toggleEdit(false)
                    }}>{fallbackText === false
                        ? value
                        : value === ""
                            ? "---" : value}</span>
                </>
                : <input
                    ref={inputRef}
                    type='text'
                    id="texttext"
                    name="texttext"
                    className={`${cssStyle} text-line-height hover_Form_ELe`}
                    style={{ ...style, outline: '1px dashed #fff', background: 'none' }}
                    value={value}
                    onBlur={onLeave}
                    onFocus={focushere}
                    onChange={handleChange}
                />
            }
        </>
    )
}