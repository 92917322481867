import React, { useState, useContext, useEffect } from 'react';
import themeContext from '../../context/ThemeContext'

function Themes() {
    const { themes, setactivetheme, activetheme = 'default', fetchThemeUpdate }: any = useContext(themeContext);

    const [value, setvalue] = useState('');
    const [activetab, setactivetab] = useState('general');
    const tablist = [{ label: 'general', group: 'general' }, { label: 'brands', group: 'brand' }];

    useEffect(() => {
        setvalue(activetheme);
        themes.forEach((theme: any) => {
            if (theme._id === activetheme) setactivetab(theme.group)
        })
    }, [themes, activetheme])

    function onChange2(value: any) {
        setactivetheme(value);
        fetchThemeUpdate(value);
    }

    function sort(a: any, b: any) {
        return a.order - b.order;
    }

    function themelist(list: any) {
        return (
            <>
                <div className="theme_select text-black">
                    <ul>
                        {list
                            .filter((z: any) => z.group === activetab)
                            .sort(sort)
                            .map(({ _id, name, background = '' }: any, index: number) => {
                                const style = background !== '' ? {
                                    background: `url(${background}) no-repeat 0 0 #fff`
                                } : {};
                                if (_id === value)
                                    return <li style={style} className="active" value={_id} key={_id}>{name}</li>;
                                return <li style={style} value={_id} key={_id} onClick={() => onChange2(_id)}>{name}</li>;
                            })}
                    </ul>
                </div>
            </>
        )
    }
    const themeTabs = () => (
        <div className="tab_header flex">
            {tablist.map(({ label, group }: any) => {
                const flag = activetab === group;
                return (
                    <div
                        className={flag ? "active" : ''}
                        key={group}
                        role="button"
                        onClick={() => {
                            if (!flag) setactivetab(group);
                        }}
                    >{label}</div>
                );
            })}
        </div>
    );

    return (
        <>
            {themeTabs()}
            <div className="setting__element setting__element__themes">
                <div className="setting__ele ment__wrap">
                    <div>
                        <div className='tab_content'>
                            {themelist(themes)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Themes;