import React, { useEffect, useState } from 'react';

interface LogoProps {
    label: string;
    pass: any;
    color: any;
    checkStatus: Boolean | undefined;
    colorpick: (obj: any) => void;
    onLogoChange?: any;
    display: any;
    background: any;
}

function Logo({
    label,
    pass,
    color,
    checkStatus,
    colorpick,
    onLogoChange,
    display,
    background
}: LogoProps) {
    const [locColor, setLocColor] = useState(color);
    const [locCheck, setLocCheck] = useState(checkStatus);

    useEffect(() => {
        setLocCheck(checkStatus);
    }, [checkStatus])
    useEffect(() => {
        setLocColor(color);
    }, [color])

    const style = {
        background: locColor
    };

    const changeCheck = (e: any) => {
        const { target: { checked } } = e;
        onLogoChange({
            type: 'visible',
            value: checked
        });
    };
    const pickColor = () => {
        colorpick({ color: locColor, name: pass });
    };
    return (
        <div className="logo__element">
            {/* <h3 className="setting__element__title">{label}</h3> */}
            {display.visible && (
                <div className="checkbox__wrapper">
                    <label className="flex setting__element__logo check__container">
                        <input
                            type="checkbox"
                            onChange={(e: any) => {
                                changeCheck(e);
                            }}
                            defaultChecked={locCheck ? true : false}
                        />
                        <span className="checkmark"></span>
                        {label || display.label}
                    </label>
                </div>
            )}
            {background.visible && (
                <div className="flex setting__element__color" onClick={pickColor}>
                    <span className="setting__element__colorpicker" style={style}></span>
                    <span className="setting__element__colorpicker__label"> {background.label} </span>
                </div>
            )}
            <br />
        </div>
    );
}
export default Logo;