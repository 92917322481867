import React, { useContext } from 'react';
import { ProductImageProp } from './shape';

import LayoutContext from '../../context/LayoutContext';

import iconEdit from '../../assets/icons/icon-pencil.png';
import iconEye from '../../assets/icons/icon-eye.png';
import iconEyeOff from '../../assets/icons/icon-eye-off.png';
import { productImagePadding } from '../../helpers';

const ProductImage = ({
  draw_value,
  element_id,
  height,
  width,
  x_pos,
  y_pos,
  visible = true,
  _id,
  group,
  product_id = '',
  onProductImageChange = () => {},
  onSlideUpdate = () => {}
}: ProductImageProp) => {
  // if (!draw_value) return null;

  const { templateId, template }: any = useContext(LayoutContext);

  let layoutType: any = 0;
  template.map(({ _id, template_type }: any) => {
    if (_id === templateId) layoutType = template_type;
    return null;
  })

  let style = {
    display:'flex',
    justifyContent: 'center',
    height,
    width,
    left: x_pos,
    top: y_pos,
  };
  let style2 = {
    height,
    width,
    left: x_pos,
    top: y_pos,
    overflow: 'hidden',
    outline: '1px solid #c3c3c3'
    // width: '100%',
    // height: '100%',
    // margin: 'auto'
    // opacity: '.2'
  };

  let cname = '';
  switch(element_id) {
    case 'end_customer_logo':
      cname = 'slidelogo2';
      break;
    case 'bottom_logo':
      cname = 'product_bottom_logo';
      break;
    case 'item_color':
      return (
        <img title="item_color" src={`${draw_value}`} alt={draw_value} className={`absolute`} style={style2} />
      )
  }
  // <div className={`absolute`} style={style2}></div>
  // let cname = element_id === 'end_customer_logo' ? 'slidelogos2' : ' ';
  /**
   * * Below blocked code is for product without
   * * Action buttons
   */
  // if (element_id === "product_artwork_design") {
  //   return <div className={`slide logos ${cname} absolute`} style={style}>
  //     <img src={`${draw_value}`} alt={draw_value} style={{ height: '100%', maxWidth: 'none' }} />
  //   </div>
  // }
  if (element_id === "product_artwork_background") {
    return <div className={`slide logos ${cname} absolute`} style={style}>
      <img src={`${draw_value}`} alt={draw_value} style={{ height: '100%', maxWidth: 'none' }} />
    </div>
  }
  const flag = element_id === 'stamp_image' || element_id === 'mailbox_image';
  if (flag) {
    return <div className={`slide logos ${cname} absolute`} style={style}>
      <img src={`${draw_value}`} alt={draw_value} style={{ height: '100%', maxWidth: 'none' }} />
    </div>
  }
  const editFlag = element_id !== "end_customer_logo";
  const editFlag2 = element_id !== "product_artwork_design";
  // const logotoolproductimage = element_id === 'decorator_product_image';

  let style3 = productImagePadding(layoutType, element_id);

  if (element_id === 'left_large_image') {
    // console.log(element_id, draw_value);
    if (!draw_value.includes('_cc.')) {
      style = {...style, ...{
        left: '2.155%',
        top: '3.839%',
        width: '57.328%',
        height: '83.5%',
              // background: 'red'
      }}
    }
  }

  return (
    <div className={`slide logos productImages ${cname} absolute img-${_id}`} style={style}>
      {visible ? <img src={`${draw_value}`} alt={draw_value} style={style3} /> : null}
      <div className="logo-action absolute">
        <button onClick={() => onSlideUpdate({ id: _id, type: 'visible', value: !visible, group })}>
          {visible
            ? <img src={`${iconEye}`} alt="visible" />
            : <img src={`${iconEyeOff}`} alt="visible" />
          }
        </button>
        {editFlag && editFlag2 && (
          <button onClick={() => {
            onProductImageChange({ _id, element_id, group, product_id });
          }}>
            <img src={`${iconEdit}`} alt="edit" />
          </button>
        )}
      </div>
    </div>
  )
}
export default ProductImage;