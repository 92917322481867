import React from 'react';
import ReactDOM from 'react-dom';

import App from './container/App';
import { ConfigType } from './type';

import './index.css';
import './globalmagento.css';

const defaultConfig = {
  app: "PF Presentation Tool",
  clientID: "",
  baseUrl: "",
  container: "pq-comm-root",
  pageSizeList: [24, 36, 48, 60, 72],
  pageSize: 24,
  pageSortList: [{ id: 'name_label', label: 'name' }, { id: 'date_label', label: 'date' }],
  pageSort: 'date',
};
export default class CommApp {
  app: any;
  config: ConfigType;
  static INSTANCE: CommApp | null;

  constructor(config: ConfigType) {
    if (CommApp.INSTANCE) {
      throw new Error("Communication plugin is already initialized.");
    }
    CommApp.INSTANCE = this;
    this.config = { ...defaultConfig, ...config };
  }
  init() {
    this.app = this.config.app;
    return this;
  }

  onAccessKey(show:any) {
      show();
  }

  list(extra:any) {
    this.config = { ...this.config, ...{ extra }, overView: true  };
    renderApp(this);
  }

  show(extra: any) {
    this.config = { ...this.config, ...{ extra }, overView: false };
    renderApp(this);
  }
}

function renderApp(context: CommApp) {
  return ReactDOM.render(
    <App ref={(app: App) => (context.app = app)} config={context.config} />,
    document.getElementById(context.config.container)
  );
}

Object.defineProperty(CommApp, "INSTANCE", {
  enumerable: false,
  configurable: false,
  value: null
});

Object.defineProperty(CommApp, "toString", {
  enumerable: false,
  configurable: false,
  value: () => "TEST plugin application."
});


