import React, { useState, useEffect, useContext } from 'react'
import { v4 as uniqueID } from 'uuid';
import { editableCloseIcon } from '../../helpers/IconList';
import languageContext from "../../context/languageContext";

interface EditableFormPropsType {
    title: string;
    mode: any;
    close: any;
    rowAdd: any;
};

const EditableForm = ({
    title,
    mode,
    close,
    rowAdd,
}: EditableFormPropsType) => {
    const init: any = {
        name: '',
        original: '',
        unit_price: '',
        qty: 1
    };

    

    const [obj, setObj] = useState(init);
    const handleChange = (e: any) => {
        const { target: { name, value } } = e;
        setObj({ ...obj, [name]: value });
    };

    const {
        more_than_zero,
        all_field_are_required,
        unit_greater_than_original_price,
        cancel,
        ok,
        article_quantity,
        edit_original_price,
        unit_price,
        article_description
    }: any = useContext(languageContext);

    const label = {
        desc: article_description || 'Article description',
        base: unit_price || 'Original price',
        end: edit_original_price || 'End-customer price',
        qty: article_quantity || 'Article quantity',
    };

    useEffect(() => {
        const ele = document.getElementById('descriptionname');
        ele?.focus();
        return () => { }
    }, [])
    const validation = () => {
        const { unit_price, name, original, qty } = obj;

        // console.log(Number(unit_price).toFixed(2))
        const unitPrice: any = Number(unit_price).toFixed(2);
        const ooriginal: any = Number(original).toFixed(2);
        const commVali = name === '' || unit_price === '';
        if (commVali) {
            alert(all_field_are_required);
            return null;
        }
        const uuid = uniqueID();
        if (mode !== 1) {
            const addObj: any = {
                code: uuid,
                label: name,
                charge: unitPrice,
                price: unitPrice && (unitPrice * qty),
                qty,
                unit_price: unitPrice,
                original: ooriginal,
                sku: uuid,
                margin: (((unitPrice - original) / original) * 100).toFixed(2)
            };
            // console.log(addObj);
            // return null;
            rowAdd(addObj);
        } else {
            if (original <= 0) {
                alert(more_than_zero);
                return null;
            }
            if (unit_price * 1 < original * 1) {
                alert(unit_greater_than_original_price);
                return null;
            }
            if (original === '' || qty === '') {
                alert(all_field_are_required);
                return null;
            }

            const addObj: any = {
                ...obj,
                code: uuid,
                attribute_label: name,
                price: unitPrice && (unitPrice * qty),
                unit_price: unitPrice,
                original: ooriginal,
                sku: uuid,
                editedWith: '',
                margin: (((unitPrice - original) / original) * 100).toFixed(2)
            };
            rowAdd(addObj);
        }
        close();
        setObj(init);

    }
    return (
        <div className="editableformWrap">
            <div className={`editableform`}>
                <div className="backgroundBox__title">
                    <h4>{title}</h4>
                    <button onClick={close}>
                        <img src={editableCloseIcon} alt="close" />
                    </button>
                </div>
                <div className="editableform__body">
                    <div className="formEle">
                        <label>{label.desc}<span>*</span></label>
                        <input id="descriptionname" name="name" onChange={handleChange} type="text" value={obj.name} />
                    </div>
                    <div className="formEle">
                        <label>{label.base}<span>*</span></label>
                        <input name="original" onChange={handleChange} type="number" min="1" value={obj.original} />
                    </div>
                    <div className="formEle">
                        <label>{label.end}<span>*</span></label>
                        <input name="unit_price" onChange={handleChange} type="number" min={obj.original || 1} value={obj.unit_price} />
                    </div>
                    <div className="formEle">
                        <label>{label.qty}<span>*</span></label>
                        <input name="qty" onChange={handleChange} type="number" min="1" value={obj.qty} />
                    </div>
                </div>
                <div className="backgroundBox__footer editBox__footer">
                    <div></div>
                    <div>
                        <button onClick={close}>{cancel}</button>
                        <button className="ok" onClick={validation} disabled={false}>{ok}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default EditableForm;