import React, { useEffect, useState, useRef } from 'react';

export default function PTTextarea({ element, onSlideUpdate, cssStyle = '', editheight = 0, style = {}, limit = 0 }: any) {
    const { _id, draw_value = '', text_align } = element;
    // font_size, font_name, font_weight, 
    const [edit, setEdit] = useState(true);
    const [value, setValue] = useState('');

    const inputRef: any = useRef(null);

    const textareaStyle = {
        // fontSize: `${font_size}px`,
        // fontWeight: `${font_weight}`,
        // fontFamily: font_name || '',
        // lineHeight: '16px',
        // background: 'none',
        color: '#fff',
        textAlign: text_align,
        ...style
    }

    useEffect(() => {
        setValue(draw_value);
    }, [draw_value])

    useEffect(() => {
        if (!edit)
            inputRef.current.focus()

    }, [edit])

    function toggleEdit(flag: boolean) {
        setEdit(flag);
        setTimeout(function () {
            const ele = document.getElementById('texttext');
            ele?.focus();
        }, 200)
    }

    function onLeave() {
        if (draw_value !== value) {
            onSlideUpdate({ id: _id, type: 'draw_value', value });
        }

        setTimeout(function () {
            toggleEdit(true);
        }, 200)
    }

    function focushere(e: any) {
        if (draw_value !== null) {
            e.target.selectionStart = value.length;
            e.target.selectionEnd = `${value.length}`;

            const area = inputRef.current;
            area.scrollTop = area.scrollHeight;
        }
    }

    function handleChange(e: any) {
        setValue(e.target.value);
    }

    function preText(text: any) {
        const subText = limit !== 0 ? text.substr(0, limit) : value;
        const dotIndex = subText.lastIndexOf('.') + 1;

        return dotIndex ? subText.slice(0, dotIndex) : subText;
    }

    return (
        <>
            {edit
                ?
                <>
                    <div
                        role='button'
                        className={`${cssStyle} text-line-height hoverFormELe`}
                        style={textareaStyle}
                        onClick={() => {
                            toggleEdit(false)
                        }}
                    >
                        {preText(value)}
                    </div>
                </>
                :
                <textarea
                    ref={inputRef}
                    id="texttext"
                    name="texttext"
                    className={`${cssStyle} text-line-height hover_Form_ELe`}
                    style={{ ...textareaStyle, outline: '1px dashed #fff', height: `${editheight}px`, background: 'none' }}
                    value={value}
                    onBlur={onLeave}
                    onFocus={focushere}
                    onChange={handleChange}
                />
            }
        </>
    )
}