import React, { useContext } from 'react';
import boardContext from '../../context/BoardContext';
import languageContext from '../../context/languageContext';
import { RectFlatProp } from './shape';

const RectFlat = ({
  element_id,
  fill,
  height,
  width,
  x_pos,
  y_pos,
  group,
  visible,
  setECQView = () => { },
  allow_template_color
}: RectFlatProp) => {
  const context: any = useContext(boardContext);
  const { edit_price_ticket }:any = useContext(languageContext);
  let tempColor: any = '';
  if (context.look) {
    tempColor = context.look.template_color || '';
  }

  let opacity: any = 1; // eleTitleTextBg ? 0.2 : 1;

  // const flagTempColor = element_id === 'price_per_product_label_background';

  let style: any = {
    height,
    width,
    background: allow_template_color ? tempColor : fill,
    left: x_pos,
    top: y_pos,
    opacity,
    color: ''
  };

  switch (group) {
    case 'title_text': {
      // opacity =  ? .2 : 1;
      if (!visible)
        style.background = 'none';
      break;
    }
    case 'presentation_logo': {
      // opacity = !visible ? 0 : 1;
      if (!visible)
        style.background = 'none';
      break;
    }
    case 'end_customer_logo': {
      // opacity = !visible ? 0.2 : 1;
      if (!visible)
        style.background = 'none';
      break;
    }
    default:
      break;
  }

  if (element_id === 'bottom_pan_hover') {
    //  onClick={() => setECQView(true)} role="button"
    // style.width = "98.6%";
    style.width = "16.6%";
    style.left = "48%";
    style.color = tempColor;

    return (
      <div className="rect absolute nohoveryellow" style={style} data-id={element_id}>
        <button onClick={() => setECQView(true)}>
          <i className="icon pfc-edit"></i>
          <span className="edittext">{edit_price_ticket}</span>
        </button>
      </div>
    )  
  }
  if (element_id === 'right_pan_hover') {
    return (
      <div className="rect absolute ecqEditbutton" style={style} data-id={element_id}>
        <button onClick={() => setECQView(true)}>
          {edit_price_ticket}
        </button>
      </div>
    )  
  }

  // const flagSlideType = slideType === 'product_slide';
  if (element_id === 'vertical_line_bg') {
    style["maxWidth"] = '1px';
  }
  if (element_id === 'horizontal_line_bg') {
    style["maxHeight"] = '1px';
  }

  return (
    <div className="r ect absolute" style={style} data-id={element_id}>
    </div>
  )
}
export default RectFlat;