import React, { ReactNode } from 'react'

// export interface ShapeTypeProps {
//     allow_template_color: boolean;
//     draw_value: string;
//     element_id: string;
//     fill: string;
//     group: string;
//     height: string;
//     order_no: number;
//     retain_values: any;
//     type: string;
//     visible: boolean;
//     width: string;
//     x_pos: string;
//     y_pos: string;
//     _id: string;
//     children?: React.ReactNode,
//     cssStyle?: any
// } 
export interface shapeTs {
    fill?: string;
    cssStyle?: any;
    cssname?: string;
    children?: React.ReactNode
}
const PTShape = (props: shapeTs) => {
    const { fill, cssStyle, cssname } = props;
    const style = { background: fill }
    return <div className={cssname} style={{ ...cssStyle, ...style }}>{props.children}</div>;
}
export default PTShape
