/**
 * component : BACKGROUND IMAGE CHANGE
 * uses : Background Image change, Product Image change
 *
 */

import React, { useState, useContext, Fragment } from 'react';
import languageContext from '../../context/languageContext';

import FolderDisplay from './FolderDisplay';

// Icons
import { popCloseIcons } from '../../helpers/IconList';
// STYLE
import { BreadDiv } from './backgroud';
import './backgroundimage.css';
import { ReactComponent as Icon } from '../../assets/folder.svg';

type BackgroundImagesProps = {
    localEdit: any;
    view: any;
    backgroundImageList: any;
    onSlideUpdate: (a: any) => void;
    elements: any;
    localmenuClose: () => void;
    breadcrumb: any;
    slideType: any;
}

const BackgroundImage: React.FC<BackgroundImagesProps> = ({
    localEdit, view, localmenuClose, backgroundImageList, onSlideUpdate, elements,
    breadcrumb,
    slideType,
}) => {

    const [value, setValue] = useState('');
    const [ini, setIni] = useState(true);

    const { select_product, title_slide_backgorund_image }: any = useContext(languageContext);
    let title: any = title_slide_backgorund_image;
    let itemCss = 'imageBtn';
    let activeValue = '';
    if (localEdit && localEdit.element_id) {
        activeValue = elements.filter((ele: any) => ele._id === localEdit._id)[0].draw_value || '';
    }

    function sendChang() {
        const obj = {
            id: localEdit._id,
            type: 'draw_value',
            group: localEdit.group,
            value
        };
        onSlideUpdate(obj);
        close();
        localmenuClose();
    }

    if (localEdit && localEdit.group) {
        if (localEdit.group.includes('product_image') || localEdit.group.includes('left_large_image')) {
            title = select_product;
            itemCss = "productBtn";
        }
    }

    function close() {
        view(false);
        setbread([]);
    }

    const { list, setbread, fetch } = breadcrumb;

    function breadclick(index: any) {
        const { path, name } = list[index];
        const newlist = list.slice(0, index + 1);
        setbread(newlist);

        const slash = path[path.length - 1];
        const req: any = slash.includes('/') ? `${path}${name}` : `${path}/${name}`;
        fetch(req)
    }
    function makepath(index: any) {
        const { path, name } = backgroundImageList[index];
        setbread([...list, {
            name,
            path
        }]);

        const slash = path[path.length - 1];
        const req: any = slash.includes('/') ? `${path}${name}` : `${path}/${name}`;
        fetch(req)
    }

    const SelectImageBackground = () => {
        const breadcrumFlag = slideType === 'title_slide' || slideType === 'image_slide' || slideType === 'image_slide2';
        
        function sortByName(a: any, b: any) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }
        return (
            <Fragment>
                {breadcrumFlag && (
                    <BreadDiv>
                        <span className="foldername" role="button" onClick={() => {
                            setbread([]);
                            fetch('/');
                        }}>
                            <span className='home'>
                                <Icon className='home__icon' />
                            </span>

                            Library
                        </span>
                        {list.map(({ name, path }: any, index: any) => {
                            return (
                                <React.Fragment key={name}>
                                    <span className="noteffect">&gt;</span>
                                    <span className="foldername" role="button" title={name} onClick={() => breadclick(index)}>
                                        {name}
                                    </span>
                                </React.Fragment>
                            )
                        })}
                    </BreadDiv>
                )}
                <div className="backgroundBox__body">
                    <div className="backgroundScrollWrapline"></div>

                    <div className="backgroundScrollWrap">
                        {backgroundImageList
                            .sort(sortByName)
                            .sort((x: any, y: any) => x.name === 'Your Images' ? -1 : y.name === 'Your Images' ? 1 : 0)
                            .map(({ name, type = "image", path, ...other }: any, index: any) => {
                                // const flag = ini && (url).toLocaleLowerCase() === (activeValue).toLocaleLowerCase();
                                // const flag2 = url === value;
                                if (type === "image") {
                                    const { url, thumb } = other;
                                    const flag = ini && (url).toLocaleLowerCase() === (activeValue).toLocaleLowerCase();
                                    const flag2 = url === value;
                                    return (
                                        <button key={name} onClick={() => {
                                            setIni(false);
                                            setValue(url);
                                        }} className={flag ? `${itemCss} active` : flag2 ? `${itemCss} active` : itemCss}>
                                            <img src={thumb} alt={name} />
                                        </button>
                                    );
                                }
                                return <FolderDisplay key={name} index={index} name={name} makepath={makepath} list={list.length} />;
                            })}
                    </div>
                </div>
            </Fragment>
        );
    }
    const breadcrumFlag = slideType === 'title_slide' || slideType === 'image_slide' || slideType === 'image_slide2';
    return (
        <div className="backgroundBoxWrap" >
            <div className={!breadcrumFlag ? "backgroundBox" : "backgroundBox backgroundSelectModal"}>
                <div className="backgroundBox__title">
                    <h4>{title}</h4>
                    <button onClick={close}>
                        <img src={popCloseIcons} alt="close" />
                    </button>
                </div>

                {SelectImageBackground()}

                <div className="backgroundBox__footer">
                    <button onClick={close}>Cancel</button>
                    <button className="ok" onClick={sendChang} disabled={!value}>Ok</button>
                </div>
            </div>
        </div>
    );
};

export default BackgroundImage;
