import React, { useContext } from 'react';
import lookAndFeelContext from '../../context/LookAndFeelContext';
import languageContext from '../../context/languageContext';

export default function SlideTemplate(props: any) {
    const { template, onTemplateChange, templateId, setTemplateId }: any = useContext(lookAndFeelContext);
    const { select_layout, ...translation }: any = useContext(languageContext);
    // const { compare = "", default: layoutDefault = "", pattern =""  }: any = useContext(languageContext);


    function templateChange(_id: any, name: any) {
        setTemplateId(_id);
        onTemplateChange(name);
    }

    function ThumbDraw(_id: any, template_type: any, name: any, thumb: any, flag: boolean) {
        const { active = '', inactive = '' } = thumb || {};
 
        return (
            <div key={_id} className="slideTemplate_list" onClick={flag ? () => { } : () => templateChange(_id, name)}>
                <label className="">
                    <img src={flag ? active : inactive} alt="" />
                    &laquo;{translation[`${template_type}`]}&raquo;
                </label>
            </div>
        );
    }

    return (
        <div className="setting__element slideTemplate">
            <div className="setting__element__wrap">
                <h4 className="slideElement__h4">{select_layout}</h4>
                {template.map(({ _id, name, thumb,template_type }: any, ind: any) => {
                    // const translatedName = ind === 0 ? layoutDefault : ind === 1 ? compare : pattern || name;
                    return ThumbDraw(_id, template_type, name, thumb, _id === templateId)
                })}
            </div>
        </div>
    )
}