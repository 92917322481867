import React from 'react'
import PtImage from '../atom/PT_Image'
import PtShape from '../atom/PT_Shape'

import '../contact.css'

export function getElement(data: any, key: string) {
    const result = data.filter((d: any) => d.element_id === key);
    return result.length > 0 ? result[0] : null;
}

export default function ContactLogo({ logo, value }: any) {
    // const background: any = getElement(logo, "presentation_logo_background");

    // function visiblity() {
    //     alert("toggle");
    // }
    // function backgroundColorPick() {
    //     alert("color picker");
    // }
    return (
        <PtShape cssname={"contact_logo_warpper flex justify-center m-b_60"} fill={value.background_color}>
            <PtImage value={value.source} cssStyle={{ objectFit: 'contain' }}/>
        </PtShape>
    )
}