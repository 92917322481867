import React from 'react';

import { ItemPropType, ActionPropType } from '../../type';
import { datFormat } from '../../helpers';
import { overviewDeleteIcon } from '../../helpers/IconList';

function Action({ edit, deleted }: ActionPropType) {
  return (
    <div className="list-action" style={{ display: "no ne" }}>
      {/* <button onClick={edit}>
        <span className="btn-text">Edit</span>
        <svg fill="none" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path xmlns="http://www.w3.org/2000/svg" d="M16.2929 3.29289C16.6834 2.90237 17.3166 2.90237 17.7071 3.29289L20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L11.7071 16.7071C11.5196 16.8946 11.2652 17 11 17H8C7.44772 17 7 16.5523 7 16V13C7 12.7348 7.10536 12.4804 7.29289 12.2929L16.2929 3.29289ZM9 13.4142V15H10.5858L18.5858 7L17 5.41421L9 13.4142ZM3 7C3 5.89543 3.89543 5 5 5H10C10.5523 5 11 5.44772 11 6C11 6.55228 10.5523 7 10 7H5V19H17V14C17 13.4477 17.4477 13 18 13C18.5523 13 19 13.4477 19 14V19C19 20.1046 18.1046 21 17 21H5C3.89543 21 3 20.1046 3 19V7Z"></path>
        </svg>
      </button> */}
      <button onClick={(e:any) => {
        e.stopPropagation();
        deleted();
      }}>
        <span className="btn-text">Delete</span>
        <img alt="" src={overviewDeleteIcon} />
      </button>
    </div>
  );
}

const Item = ({
  name = '',
  date = '',
  edit,
  deleted,
  imgtype = '',
  imgvalue = ''
}: ItemPropType) => {
  // const textLimit = 30;

  
  let style: any = {};
  if (imgtype === 'color') {
    style.background = imgvalue;
  }

  return (
    <div className="list-wrap" role="button" onClick={e => {
      e.preventDefault();
      edit();
    }} >
      <div>
        <div
          className="list-image"
          style={style}
          role="button"
        >
          {(imgtype === 'image') && imgvalue !== '' &&
            <img src={`${imgvalue}`} alt="file" />
          }
          {/* img {imgname} */}
          {/* <svg fill="none" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path xmlns="http://www.w3.org/2000/svg" d="M2 6C2 4.89543 2.89543 4 4 4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289L11.4142 6H20C21.1046 6 22 6.89543 22 8V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM8.58579 6L4 6V18H20V8H11C10.7348 8 10.4804 7.89464 10.2929 7.70711L8.58579 6Z"></path>
        </svg> */}
        </div>
        <div className="list-info">
          <p>{name}</p>
          <span>{datFormat(date)}</span>
        </div>
        <Action edit={edit} deleted={deleted} />
      </div>
    </div>
  )
};
export default Item;
