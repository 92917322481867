import React from 'react';
const obj = {
  themes: {},
  logo: {},
  presentation_logo: {},
  presentation_logo_check: {},
  presentation_logo_background: {},
  end_customer_logo: {},
  end_customer_logo_check: {},
  end_customer_logo_background: {},

  look_and_feel: {},
  template_color: {},
  band_color: {},
  font: {},

  product_template: {}
};
const settingContext = React.createContext(obj);
export default settingContext;