import styled from "styled-components";

export const BreadDiv = styled.div`
    padding: 10px 0 0 !important;
    margin: 0 20px 0;
    // border: 1px solid red;
    span {
        height: 20px;
        border-radius: 30px;
        padding: 0 0 0 10px;
        // margin: 0 3px 3px 0;
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        color: #19497a;
        cursor: pointer;
        font: 400 14px Arial;
    }

    span:first-child {
        padding: 0;
    }
    span.foldername:hover {
        text-decoration: underline;
    }
    span:hover:after {
        text-decoration: none;
    }
    span.noteffect::hover {
        text-decoration: none !important;
    }
    /* span:not(first-child):before {
        content: " > ";

    } */
    // span:first-child:before {
    //     content: url(folder.svg);
    //     width: 20px;
    //     display: inline-block;
    // }
    /* span:not(:last-child):after {
        content: ">";
        position: relative;
        left: 5px;
    }
    span:not(:last-child):after:hover {
        text-decoration: none;
    }
    span:hover:not(:last-child):after {
        content: ">";
        position: relative;
        left: 5px;
        text-decoration: none;
    } */
    span:last-child {
        color: #666666;
    }
    span:last-child svg {
        fill: #666666;
    }
    span:not(:last-child) svg {
        fill: #19497a;
    }
`;
