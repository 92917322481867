import React from 'react'
import { ProductImageProp } from './shape';

const FlatImage = ({
    draw_value,
    element_id,
    height,
    width,
    x_pos,
    y_pos,
    visible = true,
    _id,
    group,
    product_id = '',
    onProductImageChange = () => { },
    onSlideUpdate = () => { }
}: ProductImageProp) => {
    if (!draw_value) return null;
    const style = {
        display: 'flex',
        justifyContent: 'center',
        height,
        width,
        left: x_pos,
        top: y_pos,
        opacity: 0.8
    };
    
    return (
        <div className={`slide logos absolute`} style={style}>
            {visible ? <img src={`${draw_value}`} alt={draw_value} /> : null}
        </div>
    )
}
export default FlatImage;