import React, { useState, useEffect } from "react";
import { upIcon, downIcon } from "../../helpers/IconList";

const EditableQuantity = ({
    sku = "",
    name = "",
    children = "",
    trail = "",
    change,
    editedWith,
    style,
}: any) => {
    const [child, setChild] = useState(children);
    const [qtyfocus, setQtyFocus] = useState(false);

    useEffect(() => {
        setChild(children);
    }, [children]);

    function cellChange(e: any) {
        const {
            target: { value },
        } = e;

        const stripValue = value;
        let finalValue = stripValue;
        if (stripValue === "" || stripValue === 0 || stripValue === "0") {
            finalValue = 1;
            setChild(1);
        }
        change(sku, finalValue, name);
        return null;
    }

    let dis = true;
    if (name !== "qty") {
        if (editedWith === "") dis = true;
        else {
            if (editedWith === name) dis = true;
            else dis = false;
        }
    }

    function up() {
        const newChild = Number(child) + 1;
        setChild(newChild);
        change(sku, newChild, name);
    }
    function down() {
        if (child >= 2) {
            const newChild = Number(child) - 1;
            setChild(newChild);
            change(sku, newChild, name);
        }
    }

    function onQtyFocus(e: any) {
        setQtyFocus(true);
    }
    function onQtyBlur() {
        setQtyFocus(false);
    }

    return (
        <div className="flex editablebox">
            <input
                type={qtyfocus ? "number" : "text"}
                className="editablebox_input"
                name="qty"
                value={qtyfocus ? child : `${child} ${trail}`}
                onFocus={onQtyFocus}
                onBlur={onQtyBlur}
                onChange={cellChange}
                min="1"
                disabled={!dis}
                style={style}
            />
            <div className="flex editablebox_action">
                <img src={upIcon} onClick={up} alt="up" />
                <img src={downIcon} onClick={down} alt="down" />
            </div>
        </div>
    );
};
export default EditableQuantity;
