import React, { useState, useContext, useEffect } from 'react'
//import { cloneDeep } from 'lodash'
// import circle from '../../../public/shape-circle.svg'
import lookAndFeelContext from '../../context/LookAndFeelContext';
import languageContext from '../../context/languageContext';
import StyleIcon from '../PillowSlide/atom/PT_SVG'
interface SlideEleentsProps {
    colorpick: any
}

export function SlideElements(props: SlideEleentsProps) {
    const { element, onElementChangge }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    const { style = {
        value: []
    }, colors = {
        value: []
    }, product_highlight = {
        value: {
            highlight: [],
            status: true
        }
    } } = element;
    const { colorpick } = props;

    const initialvalue: any = {
        applied: null,
        colors: [],
        status: true
    };

    const [sty, setsty] = useState(style?.value || [])
    const [mai, setmai] = useState(initialvalue);
    const [accent1, setaccent1] = useState(initialvalue);
    const [accent2, setaccent2] = useState(initialvalue);
    const [hlight, sethlight] = useState(product_highlight);

    useEffect(() => {
        setsty(style.value);
    }, [style])

    useEffect(() => {
        sethlight(product_highlight);
    }, [product_highlight])

    useEffect(() => {
        if (colors.value[style.applied]) {
            setmai(colors.value[style.applied]?.main);
            setaccent1(colors.value[style.applied]?.accent_1);
            setaccent2(colors.value[style.applied]?.accent_2);
        }
    }, [colors, style.applied]);

    const onChange = (label: string, list: any, set: any, type?: any) => {
        const newSty = list.map((s: any) => {
            if (s.name === label) {
                return { ...s, status: true }
            }
            return { ...s, status: false };
        });
        if (type === "style") {
            onElementChangge({ ...element.style, value: newSty, applied: label })
            // setElement({ ...element, style: { ...element.style, value: newSty, applied: label } })
        }
        if (type === "highlight") {
            const highlightObj = {
                ...element.product_highlight, value: {
                    ...element.product_highlight.value,
                    highlight: newSty
                }, applied: label
            }
            onElementChangge(highlightObj);
        }
        // setElement({ ...element, product_highlight: { ...element.style, value: newSty, applied: label } })

        // set(newSty);
    }

    function colorAccent(rung: any, set: (obj: any) => void, target: string, act = '') {
        return <div>
            {rung?.colors.map((r: string) => {
                let selected = ''
                if (act === r) selected = 'colorboxpillow';
                return (
                    <span
                        className={`relative colorbox ${selected}`}
                        style={{ background: r }}
                        role="button"
                        key={Math.random()}
                        onClick={() => {
                            const update = { ...rung, applied: r };
                            const colorobj = {
                                ...element,
                                colors: {
                                    ...element.colors,
                                    value: {
                                        ...element.colors.value,
                                        [style.applied]: {
                                            ...element.colors.value[style.applied],
                                            [target]: update
                                        }
                                    }
                                }
                            };
                            onElementChangge(colorobj.colors);
                        }}
                    />
                )
            })}
        </div>
    }

    function localColoPicker(color: any, name: any, list: any) {
        if (color === null) return;
        let selected = '';
        if (!list.includes(color))
            selected = 'colorpickerpillow'
        return (
            <div className={`flex setting__e lement__color ${selected}`} onClick={() => colorpick({ color, name, list })}>
                <span className="setting__element__colorpicker" style={{ background: color }}></span>
            </div>
        )
    }

    const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>, obj: any, set: any, target: string) => {
        const colorobj = {
            ...element,
            colors: {
                ...element.colors,
                value: {
                    ...element.colors.value,
                    [style.applied]: {
                        ...element.colors.value[style.applied],
                        [target]: {
                            ...element.colors.value[style.applied][target],
                            status: e.target.checked
                        }
                    }
                }
            }
        };
        onElementChangge(colorobj.colors);
    }

    return (
        <div className="setting__element__wrap">
            <div>
                <h3 className="setting__element__title text-bold_default m-b_15">
                    {language["style"] ? language["style"] : "Style"}
                </h3>
                <div className="style_wrap flex">
                    {sty.map(({ name, status, thumb }: any) => {
                        return (
                            <div
                                className={status ? "active m-b_20" : 'm-b_20'}
                                key={name}
                                role="button"
                                onClick={() => onChange(name, sty, setsty, 'style')}
                            >
                                {name === 'style_1' && <StyleIcon.style_1 />}
                                {name === 'style_2' && <StyleIcon.style_2 />}
                                {name === 'style_3' && <StyleIcon.style_3 />}
                            </div>
                        )
                    })}
                </div>

                <h3 className="setting__element__title fw-normal text-regular_default m-b_15">Colors</h3>
                <ul className='list-style-none'>
                    <li className='mb20'>
                        <div className={"flex justify-space-between m-b_10"}>
                            <label className='fz12 text-regular_small text-black'>
                                {language["main"] ? language["main"] : "Main"}
                            </label>
                        </div>
                        {/* {mai && mai?.applied && ( */}
                        <div className={"flex justify-space-between m-b_25"}>
                            {localColoPicker(mai?.applied, 'main', mai?.colors)}
                            {colorAccent(mai, setmai, 'main', mai?.applied)}
                        </div>
                        {/* // )} */}
                    </li>

                    <li className='mb20'>
                        <div className={"flex justify-space-between mb10"}>
                            <label className='fz12 text-regular_small text-black m-b_15'>
                                {language["accent_1"] ? language["accent_1"] : "Accent 1"}
                            </label>
                            <label className="switch">
                                <input type="checkbox" checked={accent1?.status} name="accent1" onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheck(e, accent1, setaccent1, 'accent_1')} />
                                <span className="slider_pillow round"></span>
                            </label>
                        </div>
                        <div className={"flex justify-space-between"}>
                            {localColoPicker(accent1?.applied, 'accent_1', accent1?.colors)}
                            {colorAccent(accent1, setaccent1, 'accent_1', accent1.applied)}
                        </div>
                    </li>
                    <li>
                        <div className={"flex justify-space-between mb10"}>
                            <label className='fz12 text-black'>
                                {language["accent_2"] ? language["accent_2"] : "Accent 2"}
                            </label>
                            {/* {accent2.status ? 'yes' : 'no'} */}
                            <label className="switch">
                                <input type="checkbox" checked={accent2?.status} name="accent2" onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheck(e, accent2, setaccent2, 'accent_2')} />
                                <span className="slider_pillow round"></span>
                            </label>
                        </div>
                        <div className={"flex justify-space-between"}>
                            {localColoPicker(accent2?.applied, 'accent_2', accent2?.colors)}
                            {colorAccent(accent2, setaccent2, 'accent_2', accent2.applied)}
                        </div>
                    </li>
                </ul>
            </div>

            <div className='setting__divider'>
                <div className={"flex justify-space-between"}>
                    <h3 className="setting__element__title mb15">
                        {language["product_highlight"] ? language["product_highlight"] : "Product highlight"}
                    </h3>
                    <label className="switch">
                        <input type="checkbox" checked={hlight.value.status} name="highlight" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const highlightObj = {
                                ...element.product_highlight, value: {
                                    ...element.product_highlight.value,
                                    status: e.target.checked, name: 'status'
                                }
                            }
                            onElementChangge(highlightObj)
                            // onElementChangge({ ...hlight, status: e.target.checked, name: 'status' })
                        }} />
                        <span className="slider_pillow round"></span>
                    </label>
                </div>

                <div className="highlight_wrap flex">
                    {hlight.value.highlight && hlight.value.highlight.map(({ name, status, thumb }: any) => {
                        return (
                            <div
                                className={status ? "active" : ''}
                                key={name}
                                role="button"
                                onClick={() => onChange(name, hlight.value.highlight, sethlight, 'highlight')}
                            >
                                {/* {name} */}
                                {/* <img src={thumb} title={name} alt={name} /> */}
                                {name === 'round' && <StyleIcon.circle />}
                                {name === 'square' && <StyleIcon.squery />}
                                {name === 'diamond' && <StyleIcon.diamon />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default function SlideDesing(props: any) {
    const { element, design, onDesignChangge, lookFeel }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    const { font_color } = lookFeel;
    const { colorpick } = props;

    const [ele, setele] = useState(design);

    useEffect(() => {
        setele(design);
    }, [design])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        onDesignChangge({ _id: name, value: checked })
    }
    const pickColor2 = () => {
        if (element.colors.value.style_1.main.colors)
            colorpick({ color: font_color, name: 'font_color', list: element.colors.value.style_1.main.colors });
        else
            colorpick({ color: font_color, name: 'font_color' });
    };

    return (
        <div className="setting__element">
            <div className="setting__element__wrap">
                <h3 className="setting__element__title mb15">{language["Content"] ? language["Content"] : "Content"}</h3>
                <ul className='list-style-none'>
                    {ele.filter((d: any) => {
                        return d.group === "content";
                    }).map((d: any) => {
                        return (
                            <li key={d._id} className='mb10' style={{
                                fontSize: '14px'
                            }}>
                                <div className={"flex justify-space-between text-black"}>
                                    {language[d.element_id] ? language[d.element_id] : d.label}

                                    <label className="switch">
                                        <input type="checkbox" checked={d.value} name={d._id} onChange={onChange} />
                                        <span className="slider_pillow round"></span>
                                    </label>
                                </div>
                            </li>
                        )
                    })}
                </ul>

                <div className='setting__divider'>
                    <h3 className="setting__element__title">{language["pillow_font"] ? language["pillow_font"] : "Font"}</h3>
                </div>
                <div className="flex setting__element__color_pillow" onClick={pickColor2}>
                    <span className="setting__element__colorpicker" style={{ background: font_color }}></span>
                    <span className='text-black' style={{ flex: 1 }}>{language["font_color"] ? language["font_color"] : "Font color"}</span>
                </div>
            </div>

        </div>
    );
}