import React, { useContext } from 'react';
import boardContext from '../../context/BoardContext';
import iconEdit from '../../assets/icons/icon-pencil.png';
import iconEye from '../../assets/icons/icon-eye.png';
import iconEyeOff from '../../assets/icons/icon-eye-off.png';
import { hexToRgb } from '../../helpers/index';
import { RectProp } from './shape';

const Rect = ({
  _id,
  element_id,
  fill,
  background_color,
  height,
  width,
  x_pos,
  y_pos,
  group,
  visible,
  slideType,
  allow_template_color = false,
  onSlideUpdate = () => { },
  localmenuView = () => { }
}: RectProp) => {
  const context: any = useContext(boardContext);
  let tempColor: any = '';

  if (context.look) {
    tempColor = context.look.template_color || '';
  }
  let opacity: any = 1; // eleTitleTextBg ? 0.2 : 1;

  let style: any = {
    height,
    width,
    left: x_pos,
    top: y_pos,
    opacity
  };

  switch (group) {
    case 'title_text': {
      if (!visible) style.background = 'none';
      break;
    }
    case 'presentation_logo': {
      if (!visible) style.background = 'none';
      break;
    }
    default:
      break;
  }

  /**
   * * Removing background rectangel form End Customer Logo
   * * Title text background should be gradient, rectangle width increased
   * *  to whold logo and text background
   */

  /* if (false && element_id === "end_customer_logo_background" && slideType === 'title_slide') {
    style = {
      ...style,
      background: `none`
    };
  } */
  // if (element_id === "title_text_background" && slideType === 'title_slide') {
  if (element_id === "title_text_background") {
    const colorvalue = fill ? fill : background_color;
    // TODO check below condition
    const background = !visible ? '' : allow_template_color ? tempColor : colorvalue;
    const hexvalue: string = background.substring(1, background.length);
    if (visible) {
      const [r, g, b] = hexToRgb(hexvalue);
      style = {
        ...style,
        "backgroundImage": `linear-gradient(to right, rgba(${r},${g},${b},1), rgba(${r},${g},${b},.75))`
      };
    }
  }
  else {
    const colorvalue = fill ? fill : background_color;
    style = {
      ...style,
      background: !visible ? '' : allow_template_color ? tempColor : colorvalue
    };
  }

  function onVisibility() {
    onSlideUpdate({
      id: _id,
      type: 'visible',
      value: !visible,
      group
    });
  }
  // if (element_id !== 'background_color') return null;
  const flagTitleSlide = slideType === 'title_slide';
  const flagImageSlide = slideType === 'image_slide' || slideType === 'image_slide2';
  const flagProductSLide = slideType === 'product_slide';
  const slideFlage = flagTitleSlide || flagImageSlide;

  const textbgFlag = element_id === 'title_text_background';
  const flag = textbgFlag && slideFlage;
  const fixCss = textbgFlag && flagTitleSlide ? 'textfix' : '';
  const imgslidecss = group === 'title_text' ? 'imageTextFix' : '';
  // const flageProductSlide = slideType === 'product_slide';

  const titleELementbackground = element_id === 'title_text_background' && flagTitleSlide;
  const rectCss = element_id !== 'vertical_line' ? 'rect' : '';
  const eclbFlag = element_id === 'end_customer_logo_background' ? 'buttonview' : '';

  return (
    <div className={`${rectCss} ${imgslidecss} ${fixCss} absolute`} style={style} data-id={element_id} id="csshover">
      {flag && (
        <div className="rect-action absolute">
          {!titleELementbackground && (
            <button onClick={onVisibility}>
              {visible
                ? <img src={`${iconEye}`} alt="visible" />
                : <img src={`${iconEyeOff}`} alt="visible" />
              }
            </button>
          )}
          {(
            <button onClick={() => {
              context.colorpickview({
                color: fill,
                id: _id,
                name: element_id,
                group
              })
              // localmenuView({ _id, element_id, group, slideType })
            }}>
              <img src={`${iconEdit}`} alt="edit" />
            </button>
          )}
        </div>
      )}

      {/* {!visible && element_id === 'end_customer_logo_background' && <div className="opacitonwrap"></div>} */}
      {eclbFlag && (
        <div className="rect-action absolute">
          <button onClick={() => onSlideUpdate({
            id: _id, type: 'visible', value: !visible, group
          })}>
            {visible
              ? <img src={`${iconEye}`} alt="visible" />
              : <img src={`${iconEyeOff}`} alt="visible" />
            }
          </button>

          {flagProductSLide && (
            <button onClick={() => {
              // localmenuView({ _id, element_id, group })
              if (context.currentSlide.elements) {
                const target = context.currentSlide.elements.find((cce: any) => cce.element_id !== element_id && cce.group === group);
                const obj = {
                  color: background_color,
                  id: target._id,
                  name: target.element_id,
                }
                context.colorpickview(obj);
              }
            }}>
              <img src={`${iconEdit}`} alt="edit" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
export default Rect;