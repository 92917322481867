import React, { useContext, useState, useEffect } from 'react';

import Themes from './Themes';
import LogoPanel from './LogoPanel';
import LookAndFeel from './LookAndFeel';
import SlideElement from './ProductSlideElements';
import SlideTemplate from './ProductSlideTemplates';
import SlideDesign, { SlideElements } from './ProductSlideDesign';
import Margin from "./Margin";

import iconClose from '../../assets/icons/icon-global-close.png';
import iconLeft from '../../assets/icons/icon-left.png';
import iconRigth from '../../assets/icons/icon-right.png';

import settingContext from '../../context/SettingContext';
import languageContext from '../../context/languageContext';
import ThemeContext from "../../context/ThemeContext";

interface PannelPropsType {
    setSettingView: () => void;
    colorpickview: (obj: any) => void;
    logoSetting?: any;
    onPresentationLogoChange?: any;
    onCustomerLogoChange?: any;
    onLookAndFeelChange: any;
    settingView: any;
}

function Panel(props: PannelPropsType) {
    const { setSettingView, settingView } = props;
    // theme,
    // logo,
    // presentation_logo, 
    // presentation_logo_check, 
    // presentation_logo_background, 
    // end_customer_logo, 
    // end_customer_logo_check, 
    // end_customer_logo_background, 
    const settingFlag: any = useContext(settingContext);
    const language: any = useContext(languageContext);
    const { margin }: any = useContext(ThemeContext);


    const [active, setActive] = useState<null | string>(null);
    const [flag, setFlag] = useState<any>(false);
    const globalMenuList = [
        'themes',
        'look_and_feel',
        'logo',
        'product_template',
        'product_element',

        'product_element_pillow',
        'product_design',

        'price_margin',
    ]

    useEffect(() => {
        if (settingView) {
            const element = document.getElementById('settingani');
            const element2 = document.getElementById('setting_over');
            setTimeout(() => {
                if (element) element.classList.add("active");
                if (element2) element2.classList.add("active");
            }, 30);
        }
        return () => { }
    }, [settingView]);

    function action(item: any) {
        if (item === active) {
            setFlag(true);
            setActive(item);
        } else {
            setFlag(true);
            setActive(item);
        }
    }
    let comp = null;
    switch (active) {
        case "themes": {
            comp = (
                <Themes />
            );
            break;
        }
        case "logo": {
            comp = (
                <LogoPanel
                    colorpick={props.colorpickview}
                    onPresentationLogoChange1={(obj: any) => props.onPresentationLogoChange(obj)}
                    onCustomerLogoChange1={(obj: any) => props.onCustomerLogoChange(obj)}
                />
            );
            break;
        }
        case "look_and_feel": {
            comp = (
                <LookAndFeel
                    colorpick={props.colorpickview}
                    onLookAndFeelChange={(obj: any) => props.onLookAndFeelChange(obj)}
                />
            );
            break;
        }
        case "product_template": {
            comp = <SlideTemplate />;
            break;
        }
        case "product_element": {
            comp = <SlideElement />;
            break;
        }
        case "price_margin": {
            comp = <Margin />;
            break;
        }
        case "product_element_pillow": {
            comp = <SlideElements colorpick={props.colorpickview} />
            break;
        }
        case "product_design": {
            comp = <SlideDesign
                colorpick={props.colorpickview}
            />
            break;
        }
        default:
    }

    function close() {
        const element = document.getElementById('settingani');
        const element2 = document.getElementById('setting_over');
        if (element) {
            element?.classList.remove('active');
            element2?.classList.remove('active');
            setTimeout(() => {
                setSettingView();
            }, 300);
        }
    }

    const selectFlg = flag ? 'active' : '';
    return (
        <div className="setting__wrap" onClick={close}>
            <div className="setting_inside_wrap" onClick={close}>
                <aside id="settingani" className="setting" onClick={(e: any) => {
                    e.stopPropagation();
                }}>
                    <div className="setting_inner_cont">
                        <h2 className="setting__title">
                            {/* <img src={stopSVG} alt="stop"/> */}
                            <span className='text-regular_default'>
                                {settingFlag.typeName === "pillow" ? language.pillow_presentation_settings : language.presentation_settings}
                            </span>
                            <button onClick={close}>
                                <img src={`${iconClose}`} alt="close" className="setting__title__img" />
                            </button>
                        </h2>
                        <div className={`setting__list__outer ${selectFlg}`}>
                            <ul className="setting__list setting__list__1">
                                {
                                    globalMenuList.map((l: any) => {
                                        if (settingFlag[l] === undefined) return null;
                                        if (l === "price_margin" && !margin) return null
                                        const { name, root, visible, label } = settingFlag[l];
                                        // console.log(name, root, visible);
                                        if (!root || !visible) return null;
                                        const languageTranslated = language[name] || label || name;
                                        return (
                                            <li key={name} className='text-regular_medium'>
                                                <button id={name} onClick={() => action(name)}>
                                                    {languageTranslated}
                                                    <img src={`${iconRigth}`} alt="" />
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <ul className="setting__list setting__list__2">
                                {active !== null && (
                                    <li className='text-regular_medium'>
                                        <button className={`active inner_menu_icon`} onClick={() => setFlag(false)}>
                                            <img src={`${iconLeft}`} alt="" />
                                            <span className="menu_title">
                                                {settingFlag[active] ? language[settingFlag[active].name] || settingFlag[active].label : ''}
                                            </span>
                                            <div />
                                        </button>
                                        {comp}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </aside>
            </div>

            <div className="setting__overlay" id="setting_over"></div>
        </div>
    );
}
export default Panel;
