import { cloneDeep } from 'lodash'

export function publicPathLocal() {
    return process.env.PUBLIC_URL || "";
}

export function catcchErrorHandling(err: any) {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (err?.response?.data) {
            const { data, status } = err.response;
            const { error, message } = data;
            if (error) console.log(status, message);
        }
        // console.error(err);
        // dev code
    } else {
        console.log('Something went wrong');
    }
    return err.message || "Something went wrong";
}

export function backgroundSortFolderAndImage(data: any[]) {
    const newdata = cloneDeep(data);
    return newdata
        .sort((a: any, b: any) => a.name < b.name ? 1 : -1)
        .sort((a: any, b: any) => a.type > b.type ? 1 : -1);
}

export function ecqFormatting(prodDefault: any, text: any = "") {
    let ecqDataManipulate: any = [];
    try {
        prodDefault.map(
            ({
                decoration,
                items,
                product_id,
                price_format,
                total,
                more_total,
                product_no
            }: any) => {
                if (decoration && items) {
                    const ECQdataList = items.concat(decoration);
                    const maniData = ECQdataList.map((ec: any) => {
                        // console.log(ec);
                        ec.editedWith = "";
                        if (!ec.sku) {
                            if (!ec.type) ec.type = 'other';
                            // ec.id = ec.id ? ec.id : ec.code;
                            ec.sku = ec.code;
                        } else {
                            if (!ec.type) ec.type = 'items';
                            // ec.id = ec.id ? ec.id : ec.sku;
                            (ec.attribute_label = ec.attribute_label
                                ? ec.attribute_label
                                : ec.sku);
                            ec.image = ec.color?.image || "";
                        }
                        ec.original = ec.original || ec.unit_price;
                        ec.margin = ec.margin || 0;
                        return ec;
                    });
                    ecqDataManipulate.push({
                        product_id,
                        price_format,
                        total,
                        more_total,
                        data: maniData,
                        product_no
                    });
                }
                return ecqDataManipulate;
            }
        );
    } catch (e) {
        console.log(e);
    }
    return ecqDataManipulate;
}

export function errorLogger(err: any) {
    console.log("Error occured: ", err);
}

export function datFormat(str: any) {
    const tarik = new Date(str);
    const d = tarik.getDate();
    const m = tarik.getMonth() + 1;
    const yy = tarik.getFullYear();
    return `${d}-${m}-${yy}` || "";
}

export function hexToRgb(hex: string = '#000000') {
    let aRgbHex:any = hex.replace("#", '');
    aRgbHex = aRgbHex.match(/.{1,2}/g);
    const aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16),
    ];
    return aRgb;
}
function rgbToCMYKhelp(r:any, k:any, round:any) {
    const b = round((100 * (1 - r - k)) / (1 - k));
    return b || 0;
}
export function rgbToCMYK(R: number, G: number, B: number) {
    const Rc = R / 255;
    const Gc = G / 255;
    const Bc = B / 255;
    const Kc = 1 - Math.max(Rc, Gc, Bc);

    const C = rgbToCMYKhelp(Rc, Kc, Math.round);
    const M = rgbToCMYKhelp(Gc, Kc, Math.round);
    const Y = rgbToCMYKhelp(Bc, Kc, Math.round);
    let K = Math.round(100 * Kc);
    return { C, M, Y, K };
}

//converts cmyk to hex
export function cmykToHex(c:number,m:number,y:number,k:number) {
    console.log(c, m, y, k);

    let r = 255 * (1 - c/100) * (1 - k/100),
        g = 255 * (1 - m/100) * (1 - k/100),
        b = 255 * (1 - y/100) * (1 - k/100);

        r = componentToHex(r);
        g = componentToHex(g);
        b = componentToHex(b);
        // console.log(r, g, b);
        return `#${r}${g}${b}`;
}
function componentToHex(c:any) {
    let hex = c.toString(16);
        hex = hex.split('.')[0];
    return hex.length === 1 ? '0' + hex : hex;
}


export function trimName(str: String, limit: number) {
    // const limit = 40;
    if (str.length > limit) return str.substring(0, limit).concat("...");
    return str;
}

export function geneP(ele = "testID", hide = true) {
    if (!document.getElementById(ele)) {
        var dom = document.createElement("div");
        dom.setAttribute("id", ele);
        if (hide) {
            dom.style.opacity = "0";
            dom.style.zIndex = "-1";
            dom.style.position = "fixed";
        }
        // document.body.append(dom);
        document.body.parentNode?.insertBefore(
            dom,
            document.getElementsByName("body")[0]
        );
        return dom;
    }
    return document.getElementById(ele);
}

export function paginate(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10,
    maxPages: number = 10
) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
    };
}

export function productImagePadding(layoutType: any, element_id: string) {
    if (layoutType === '4_products') {
        if (element_id === 'decorator_product_image') {
            return {
            paddingBottom: '10px',
            paddingRight: '10px',
            };
        }
        if (element_id === 'product_image_1') {
            return {
            paddingBottom: '10px',
            paddingLeft: '10px',
            };
        }
        if (element_id === 'product_image_3') {
            return {
            paddingTop: '10px',
            paddingLeft: '10px',
            };
        }
        if (element_id === 'product_image_2') {
            return {
            paddingTop: '10px',
            paddingRight: '10px',
            };
        }
    }
    if (layoutType === '2_products') {
        if (element_id === 'decorator_product_image') {
            return { paddingBottom: '10px' };
        }
        if (element_id === 'product_image_1') {
            return { paddingTop: '10px' };
        }
    }
    return {}
}

export function displayFilename(name: string, length:number = 10, join: string = '~') {
    if (!name || !join) return null;
    if (name.length < length) return name;

    return name.substr(0, length) + join + name.substr(name.lastIndexOf('.'));
}

export function getStoreViewFromURL(ind:number = 1) {
    const pathname = window.location.pathname;
    if (pathname) {
        const storeView = pathname?.split('/')[ind];
        return storeView;
    }
    return "";
}
export function currentChangeAndLocalMenu(obj: {
    target: any;
    localEdit: any;
    setCurrent: any;
    setLocalEdit: any;
}) {
    const { target, setCurrent, setLocalEdit } = obj;
    const { type = '', elements = [] } = target;
    switch (type) {
        case 'title_slide':
        case 'image_slide':
        case 'image_slide2': {
            const look = 'background_image';
            elements.map((ele: any) => {
                const { _id, element_id, group } = ele;
                if (element_id === look)
                    setLocalEdit({ _id, element_id, group, slideType: type });
                return null;
            });
            break;
        }
        default: {
        }
    }
    setCurrent(target);
}