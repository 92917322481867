import React from 'react';
const style_1 = () => (
    <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.618 0H0V48H32L32 6.52873L25.618 0Z" fill="#7888A4" />
    </svg>
);
const style_2 = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8.5" y="0.5" width="31" height="47" stroke="#A5B0C3" strokeDasharray="2 2" />
        <rect x="8" width="4" height="48" fill="#7888A4" />
    </svg>
);
const style_3 = () => (
    <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H37.544C37.544 0 39.0401 0.787845 36.6124 5.65517C33.6312 11.6322 36.4727 13.4253 34.1436 20.4598C31.8146 27.4943 33.9107 30.5287 32.0475 34.6667C28.9041 41.6476 31.5817 43.2184 24.5946 48C13.5084 48 0 48 0 48V0Z" fill="#7888A4" />
    </svg>
);
const Style1 = ({ fill = '#000', accent1 = '#000', accent2 = '#000' }: any) => {
    return (
        <svg width="359.42" height="525" viewBox="0 0 356 520" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_113_3546" style={{ maskType: "alpha", }} maskUnits="userSpaceOnUse" x="0" y="0" width="360" height="530">
                <rect width="360" height="530" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_113_3546)">
                <path fillRule="evenodd" clipRule="evenodd" d="M285 0H0V522H356L356 70.9999L285 0Z" fill={fill} />
                <circle opacity="0.1" cx="178" cy="680" r="238" fill="white" />
                <rect width="10" height="520" fill={accent1} />
                <path d="M10 0H285L295 10H10V0Z" fill={accent2} />
            </g>
        </svg>
    )
}
const Style2 = ({ fill = '#000', accent1 = '#000', accent2 = '#000' }: any) => {
    return (
        <svg width="356" height="525" viewBox="0 0 356 525" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="356" height="525" fill={accent2} />
            <rect width="10" height="525" fill={fill} />
            <rect x="10" width="10" height="525" fill={accent1} />
        </svg>
    )
}
const Style3 = ({ fill = "#000", accent1 = '#000', accent2 = '#000' }: any) => {
    return (
        <svg width="423.41" height="525" viewBox="0 0 421 522" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H415.5C415.5 0 431.23 16.3831 407 68.5C380.5 125.5 415.5 148 390.5 223.5C365.5 299 398.417 318.5 371.5 386.5C343 458.5 371.5 463.5 326 522C207 522 0 522 0 522V0Z" fill="white" />
            <path opacity="0.5" d="M0 0H415.5C415.5 0 431.23 16.3831 407 68.5C380.5 125.5 415.5 148 390.5 223.5C365.5 299 398.417 318.5 371.5 386.5C343 458.5 371.5 463.5 326 522C207 522 0 522 0 522V0Z" fill={fill} />
            <path opacity="0.7" d="M0 0H411.5C411.5 0 424.5 19.5 400.5 66C369.716 125.644 403.5 150 378.5 223.5C353.5 297 384.5 324 356.5 389C330.242 449.955 354.5 468.5 295 522C176 522 0 522 0 522V0Z" fill={fill} />
            <path d="M0 0H403C403 0 419.059 8.56781 393 61.5C361 126.5 391.5 146 366.5 222.5C341.5 299 364 332 344 377C310.259 452.918 339 470 264 522C145 522 0 522 0 522V0Z" fill={fill} />
            <mask id="mask0_521_2056" style={{ maskType: "alpha", }} maskUnits="userSpaceOnUse" x="0" y="0" width="421" height="522">
                <path d="M0 0H415.5C415.5 0 431.23 16.3831 407 68.5C380.5 125.5 415.5 148 390.5 223.5C365.5 299 398.417 318.5 371.5 386.5C343 458.5 371.5 463.5 326 522C207 522 0 522 0 522V0Z" fill="#038C7F" />
            </mask>
            <g mask="url(#mask0_521_2056)">
                <mask id="mask1_521_2056" style={{ maskType: "alpha", }} maskUnits="userSpaceOnUse" x="0" y="0" width="408" height="522">
                    <path d="M0 0H403C403 0 419.059 8.56781 393 61.5C361 126.5 391.5 146 366.5 222.5C341.5 299 364 332 344 377C310.259 452.918 339 470 264 522C145 522 0 522 0 522V0Z" fill="#038C7F" />
                </mask>
                <g mask="url(#mask1_521_2056)">
                    <circle opacity="0.06" cx="178" cy="680" r="238" fill="black" />
                </g>
                <rect x="532" width="10" height="522" transform="rotate(90 532 0)" fill={accent2} />
                <rect width="10" height="522" fill={accent1} />
            </g>
        </svg>
    )
}

const squery = ({ fill = "#E5EAEC" }) => (
    <svg style={{ height: '525px', width: '100%' }} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="85" height="60" fill={fill} />
    </svg>
);
const diamon = ({ fill = "#E5EAEC" }) => (
    <svg width="400" height="400" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.4376 1.88979C27.9574 -0.629934 32.0426 -0.629927 34.5624 1.8898L58.1102 25.4376C60.6299 27.9574 60.6299 32.0426 58.1102 34.5624L34.5624 58.1102C32.0426 60.6299 27.9574 60.6299 25.4376 58.1102L1.88979 34.5624C-0.629934 32.0426 -0.629927 27.9574 1.8898 25.4376L25.4376 1.88979Z" fill={fill} />
    </svg>
);
const circle = ({ fill = "#E5EAEC" }) => (
    <svg width="400" height="400" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill={fill} />
    </svg>
);

export default {
    style_1,
    style_2,
    style_3,
    Style1,
    Style2,
    Style3,
    squery,
    diamon,
    circle
}
