import "babel-polyfill";
import 'promise-polyfill/src/polyfill';
import "es6-promise";
import React from 'react';
import 'react-app-polyfill/ie11';

import Overview from './Overview';
import Presentation from './Presentation';
import globalContext from '../context/globalContext';
import overContext from '../context/OverContext';
import { AppPropsType, AppStateType } from '../type';
import './container.css';
import './dyson.css';

import { getAccessKey, regenerateAccessKey } from '../api/calls';

class App extends React.Component<AppPropsType, AppStateType> {
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: AppPropsType) {
    super(props);
    
    this.state = {
      presentationID: props.config?.presentationID||null,
      activeSlide: 0,
      history: [],
      displayOverview: false,
      clientID: '',
      old_customer_id: props.config?.old_customer_id,
      accesskey: '',
      extraKey: null,
      fonts: [],
      templateColor: [],
      brandColor: [
        { hex_value: '#3C69E7'},
        { hex_value: '#ffffff'},
        { hex_value: '#79443B'},
        { hex_value: '#006A4E'},
        { hex_value: '#D891EF'},
        { hex_value: '#FFAA1D'},
        { hex_value: '#BD33A4'}
      ],
      error: false,
      baseUrl: '',
      type: '',
      setting: {},
      extra: {},
      expirytime: '',
      presentationType: ''
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { config: { baseUrl, clientID, customer_id, contact_id, presentation_type, overView, extra, } } = this.props;
    const parm = JSON.stringify({ customer_id, contact_id });
    getAccessKey(baseUrl, clientID, parm, presentation_type)
      .then(({
        accesskey,
        extraKey,
        fonts,
        templateColor,
        features: { global_menu },
        presentationType,
        expirytime
      }: any) => {
        const setting: any = {};

        global_menu && global_menu.map((res: any) => {
          const { name } = res;
          setting[name] = res;
          return null;
        });

        const localDate = new Date(expirytime);
        const localTimeStamp = localDate.getTime();

        this.setState({
          displayOverview: overView,
          baseUrl,
          clientID,
          expirytime: localTimeStamp,
          extra,
          accesskey,
          extraKey,
          fonts,
          templateColor,
          setting,
          type: presentationType?._id || presentation_type,
          presentationType
        })
      });
  }

  regenerateToken = async (_id: any, flag:boolean = false) => {
    
    const _this = this;
    const { baseUrl, clientID = '', accesskey, extraKey, expirytime } = this.state;
    let params = { grant_type: 'access_key', auth: 'basic' };
    
    let nowdate: any = Date.now();
    const check = expirytime <= nowdate; // currentdate;
    
    if (check || flag) {
      if (accesskey && extraKey) {
        return await regenerateAccessKey(baseUrl, clientID, params, _id, accesskey, extraKey)
          .then(({ accesskey, expirytime, presentationType, ...resp }: any) => {
            _this.setState({ accesskey, expirytime, presentationType });
            return { accesskey, ...resp };
          })
      }
    }
    return { accesskey };
  };
  
  setActiveSlide = (activeSlide: any) => {
    this.setState({ activeSlide });
  };

  setDisplayOverview = (displayOverview: boolean) => {
    this.setState({ displayOverview })
  };

  editPresentationID = (id: any) => {
    window.localStorage.setItem('presentionID', id);
    this.setState({ 
      presentationID: id,
      displayOverview: !this.state.displayOverview
    });
  };

  setType = (typeid: string) => {    
    this.setState({ type: typeid });
  };
  setFeatures = (features: string) => {    
    this.setState({ setting: features });
  };

  render() {
    const { displayOverview, accesskey, extraKey, error } = this.state;

    if (error) {
      return <div className="unauthorized">Unauthorized</div>
    } 

    if (extraKey === null || accesskey === null) {
      return null;
    }
    return (
      <globalContext.Provider value={this.props.config.baseUrl || ''}>
        <div id="app_wrapper" ref={this.wrapperRef}>
          {displayOverview ? (
            <overContext.Provider value={{
              sorts: this.props.config.pageSortList,
              sizes: this.props.config.pageSizeList,
              sort: this.props.config.pageSort,
              size: this.props.config.pageSize
            }}>
              <Overview
                {...this.state}
                onEditPresentationID={this.editPresentationID}
                setDisplayOverview={this.setDisplayOverview}
                setType={this.setType}
                setFeatures={this.setFeatures}
                type={this.state.type}
                container={this.props.config.container || ''}
                validationToken={this.regenerateToken}
              />
            </overContext.Provider>
          ) : (
              <Presentation
                {...this.state}
                display={displayOverview}
                setDisplayOverview={this.setDisplayOverview}
                sliders={[]}
                change={this.setActiveSlide}
                current={null}
                validationToken={this.regenerateToken}
              />
            )}
        </div>
      </globalContext.Provider>
    )
  }
}
export default App;