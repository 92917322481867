import React, { useContext } from 'react'
import { isArray } from 'lodash';

import boardContext from '../../context/BoardContext';

import ContactLogo, { getElement } from './component/ContactLogo';
import Icons from './icons'
import PtShape from './atom/PT_Shape'

function group_elements(elements: any, fetching: any) {
    let grouping: any = {};

    elements.map((e: any) => {
        if (grouping[e.group] === undefined) {
            grouping[e.group] = [e]
        } else {
            if (isArray(grouping[e.group])) {
                grouping[e.group].push(e);
            } else {
                grouping[e.group] = e;
            }
        }
        return null;
    })

    // console.log(grouping)
    let obj: any = {};
    fetching.map((text: string) => {
        obj = { ...obj, [text]: grouping[text] };
        return null;
    })
    // console.log(obj);  
    return obj;
    // return {
    //     grouping,
    //     availableColors,
    //     products,
    // }
}

export default function ContactSlide(props: any) {
    const { elements, editorLogo } = props;
    let { contact_text, presentation_logo = [] } = group_elements(elements, ['contact_text', 'presentation_logo']);

    /**
     * #
     * GETTING APPLIED COLOR FROM SELECTED STYLES MAIN COLOR
     */
    const { element: { style, colors } }: any = useContext(boardContext);
    // console.log(style.applied, colors.value[style.applied]['main']['applied']);

    const email = getElement(contact_text, 'textbox_right');
    const company = getElement(contact_text, "textbox_left");
    const zip = getElement(contact_text, "textbox_left_zip");
    const city = getElement(contact_text, "textbox_left_city");
    const country = getElement(contact_text, "textbox_left_country");
    const tel = getElement(contact_text, "textbox_left_tel");
    const country_name = getElement(contact_text, "company_name");

    return (
        <div className='contact_wrapper pt110'>
            <ContactLogo value={editorLogo} logo={presentation_logo} />

            <div className='contact_name text-regular_default m-b_15'>
                {country_name.draw_value}
            </div>

            <div className='flex justify-center text-regular_medium text-line-height'>
                <PtShape cssname="flex contact_section">
                    <Icons.address />
                    <div className='ml10'>
                        <p>{company.draw_value}</p>
                        <p>{zip.draw_value}</p>
                        <p>{city.draw_value}</p>
                        <p>{country.draw_value}</p>
                        <p>{tel.draw_value}</p>
                    </div>
                </PtShape>
                <PtShape cssStyle={{
                    width: '2px',
                    height: '82px',
                    display: 'block'
                }} fill={colors?.value[style?.applied]['main']['applied'] || `#666666`} />
                <PtShape cssname="flex contact_section pl30">
                    <Icons.email />
                    <div className='ml10'>
                        {email.draw_value}
                    </div>
                </PtShape>
            </div>
        </div>
    )
}