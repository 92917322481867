
import React, { useEffect, useState, useContext } from 'react';
import boardContext from '../../context/BoardContext';

import { 
  Rect,
  BackgroundImage,
  Logos,
  Text,
  ProductImage,
  Textonly,
  TextEditable,
  AvailableColor,
  RectFlat,
  FlatImage,
  FlatLogo,
  Svg
} from '../Shape';
import { boardProps } from './typesBoard';
import ThreeDModel from "../ThreeDModel";

// const Board = (props: boardProps) => {
const Board = ({
  id,
  type: propstype,
  name: propsname, 
  elements: propselements,
  presentationLogo,
  editorLogo,
  localmenuView,
  onSlideUpdate,
  setECQView ,
  onProductImageChange,
  product_id,
  currentid,
  currenttype,
  productTempType,
}: boardProps) => {
  const {element, margin}: any = useContext(boardContext);

  let settingELementFlag:any = {};
  if (element && element.length) {
    element.map(({ group, value}:any) => {
      settingELementFlag[`${group}`]= value;    
      return null;
    });
  }

  const [name, setName] = useState('Empty');
  const [elements, setElements] = useState([]);

  useEffect(() => {
    setName(propsname);
    setElements(propselements);
  }, [propsname, propselements, propstype]);

  if (!propselements.length || !true) {
    return (
      <div className="ground">
        <div className="cc" data-title={name} id={id}>
          <div className="text blank" key={Math.random()}>{name}</div>
        </div>
      </div>
    );
  }

  function sorter(a:any, b:any) {
    if (a.order_no > b.order_no) {
      return 1;
    }
    return -1;
  }

  const slidesArray = elements.sort(sorter).map((e: any) => {
    const { _id, type, element_id, group, draw_value } = e;
    
    switch(type) {

      case 'shape': {
        if (
          group.startsWith("item_row_") ||
          group === 'right-pan' ||
          group === 'left-pan' ||
          element_id === 'bottom_pan_hover' ||
          element_id === 'horizontal_line_bg' ||
          element_id === 'vertical_line_bg'
        ) {
          return <RectFlat
            slideType={propstype}
            setECQView={setECQView}
            key={_id}
            {...e}
          />
        }
        if (group === "presentation_logo") {
          const newVisible = e.visible === null ? presentationLogo.visible : e.visible;
          const newprops = { ...presentationLogo, ...e, ...{ visible: newVisible } };

          return (
            <Rect
              slideType={propstype}
              key={_id}
              {...newprops}
              onSlideUpdate={onSlideUpdate}
              localmenuView={localmenuView}
            />
          );
        }
        if (group === "end_customer_logo") {
          const newVisible = e.visible === null ? editorLogo.visible : e.visible;
          const newprops = { ...editorLogo, ...e, ...{ visible: newVisible}};

          return (
            <Rect
              slideType={propstype}
              key={_id}
              {...newprops}
              onSlideUpdate={onSlideUpdate}
              localmenuView={localmenuView}
            />  
          );
        }
        return (
          <Rect
            slideType={propstype}
            key={_id}
            {...e}
            onSlideUpdate={onSlideUpdate}
            localmenuView={localmenuView}
          />
        );
        }
      case 'image': {
        if (element_id === "scene_3d" && draw_value) {
          return <ThreeDModel key={_id} {...e} />
        }
        const ll = [
          'decorator_product_image',
          'product_image_1',
          'product_image_2',
          'product_image_3',
          'available_colors',
          'product_artwork_background',
          'product_image',
        ];
        if (ll.includes(group)) {
          if (settingELementFlag[group] !== undefined && settingELementFlag[group] === false) {
            return null;
          }
        }

        if (element_id === "presentation_logo") {
          const newVisible = e.visible === null ? presentationLogo.visible : e.visible;
          const newprops = { ...presentationLogo, ...e, ...{ visible: newVisible } };
          return (
            <Logos
              slideType={propstype}
              key={_id}
              {...newprops}
              onSlideUpdate={onSlideUpdate}
              localmenuView={localmenuView}
            />
          );
        }
        if (element_id === "end_customer_logo") {
          let newVisible = e.visible === null ? editorLogo.visible : e.visible;
          const newprops = { ...editorLogo, ...e, ...{ visible: newVisible } };

          return (
            <Logos
              slideType={propstype}
              key={_id}
              {...newprops}
              onSlideUpdate={onSlideUpdate}
              localmenuView={localmenuView}
              productTempType={productTempType}
            />
          );
        }
        if (element_id === "bottom_logo") {
          const newprops = { ...presentationLogo, ...e, ...{ visible: editorLogo.visible } };

          return (
            <FlatLogo
              slideType={propstype}
              key={_id}
              {...newprops}
            />
          );
        }
        /**
         * * End Customer Quotes (ECA)
         * * center buttom logo wrapper hover effect removing
         */
        if (element_id === "bottom_logo_background") {

          return <FlatImage
            slideType={propstype}
            key={_id}
            {...editorLogo}
            {...e}
            onSlideUpdate={onSlideUpdate}
            localmenuView={localmenuView}
          />;
        }
        
        if (
          element_id === "product_artwork_design" ||
          element_id === "item_color" ||
          element_id === "bottom_logo" ||
          element_id === "column_1" ||
          
          element_id === "mailbox_image" ||
          element_id === "stamp_image" ||
          element_id === "product_artwork_background"
        ) {
          return (
            <ProductImage
              slideType={propstype}
              key={_id}
              {...editorLogo}
              {...e}
              onSlideUpdate={onSlideUpdate}
              localmenuView={localmenuView}
            />
          );
        }
        
        if (
          element_id === "decorator_product_image" ||
          element_id === "product_image_1" ||
          element_id === "product_image_2" ||
          element_id === "product_image_3" ||
          element_id === "left_large_image"
        ) {
          return (
            <ProductImage
              slideType={propstype}
              key={_id}
              {...editorLogo}
              {...e}
              onSlideUpdate={onSlideUpdate}
              onProductImageChange={onProductImageChange}
              product_id={product_id}
            />
          );
        }
        if (element_id === 'available_colors') {
          return <AvailableColor slideType={propstype} key={_id} {...e} localmenuView={localmenuView} />;
        }
        return (
          <BackgroundImage
            slideType={propstype}
            key={_id}
            {...e}
            currentid={currentid}
            currenttype={currenttype}
            localmenuView={localmenuView}
          />
        );
      }
      case 'text': {

        const ll = [
          'sku_info',
          'short_description',
          'long_description',
          'available_colors',
          'price_text',

          'product-name',
          "product_details",
          'date',
          'product_detail'
        ];
        if (ll.includes(group)) {
          if (settingELementFlag[group] !== undefined && settingELementFlag[group] === false) {
            return null;
          }
        }

        // product-slide-element removing hover effect
        if (
          group.startsWith("more_cost_row") ||
          group.startsWith("item_row") ||
          // conact
          // element_id === 'textbox_left' ||
          // element_id === 'textbox_right' ||
          // ecq
          element_id === 'price_per_product_label_text' ||
          element_id === 'price_per_product_value_text' ||
          element_id === 'total_exclusive_vat_label_text' ||
          element_id === 'total_exclusive_vat_value_text' ||

          element_id === 'recommended_decoration_option_label' ||
          element_id === 'default_print_location_label' ||
          
          element_id === 'recommended_decoration_option_value' ||
          element_id === 'default_print_location_value' ||
          element_id === 'ink_color_label' ||
          element_id === 'ink_color_value' ||

          element_id === 'bottom_price_per_product_value_text' ||
          element_id === 'bottom_total_exclusive_vat_value_text'
        ) {

          let price_value = '';
          if (element_id === "price_label") {
            elements.map((a: any) => {
              if (a.element_id === 'price_text')
                price_value = a.draw_value;
              return null;
            });
          } 

          return <Textonly
            slideType={propstype}
            key={_id}
            {...e}
            price_value={price_value}
          />
        }

        const editablElementids = [
          'sku_info_text',
          'short_description_text',
          'long_description_text',
          'price_text',
          'product_name',
          'brand_label',
          'material_label',
          'length_label',
          'width_label',
          'height_label',
          'weight_label',
          'country_of_origin_label',
          'diameter_label',
          'available_colors_title',
          'product_detail_title',
          
          'article_label',
          'color_label',
          'method_label',
          'location_label',
          'size_label',
          'pms_label',
          'price_label',
          'bottom_price_label',
          'bottom_total_label',

          'length_value',
          'width_value',
          'height_value',
          'diameter_value',
          'weight_value',
          'material_value',
          'brand_value',
          'country_of_origin_value',

          'article_value',
          'color_value',
          'method_value',
          'location_value',
          'size_value',
          'pms_value',

          'date_label',
          'date_value',
        ]
        if (editablElementids.includes(element_id)) {
          let price_value = '';
          if (element_id === "price_label") {
            elements.map((a: any) => {
              if (a.element_id === 'price_text')
                price_value = a.draw_value;
              return null;
            });
          }
          return <TextEditable
              slideType={propstype}
              key={_id}
              {...e}
              onSlideUpdate={onSlideUpdate}
              margin={margin}
              price_value={price_value}
              productTempType={productTempType}
            />
        }
        return <Text slideType={propstype} key={_id} {...e}
          localmenuView={localmenuView}
          onSlideUpdate={onSlideUpdate}
        />;
      }
      case 'svg': {
        return (
          <Svg
            slideType={propstype}
            key={_id}
            {...e}
          />  
        )
      }
    }

    return null; 
  }).filter(i => i !== null);

  return (
    <div className="ground">
      <div className={`cc ${id}`} data-title={name}>{slidesArray}</div>
    </div>
  )
};
export default Board;
