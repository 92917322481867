export function changeRow(
    sku: string,
    val: number,
    name: string,
    newArray: any
) {
    // const nn: any = [];
    return newArray.map((ld: any) => {
        if (ld.sku === sku) {
            if (name === "attribute_label" || name === "label") {
                return {
                    ...ld,
                    [name]: val
                };
            }
            // if ()
            if (name === "qty") {
                ld[name] = +val;
                /**
                 * * COMPUTING TOTAL
                 * ? PRICE = ROW TOTAL (PRICE * NUMBER OF ITEMS)
                 */
                const convertedprice = Number(replaceCommaWithDecimal(ld.unit_price));
                const price = convertedprice * val;
                return {
                    ...ld,
                    qty: val,
                    price,
                    charge: price
                }
            }

            if (name === "unit_price") {
                // console.log("111");
                // if (ld.original <= val) {
                // const val2: any = Number(val).toFixed(2);
                const val2 = Number(replaceCommaWithDecimal(val));
                // console.log("--", name, val, val2);
                //* IF UNIT PRICE IS CHANGE COMPUTION OF ROW TOTAL
                const price = ld.qty * val2;
                //* COMPUTING CHANGE IN MARGIN (ORIGINAL PRICE AND UNIT PRICE)
                let margin: any = 0;
                if (val2 !== ld.original) {
                    // console.log(ld.original, ' - - - ');
                    const newOriginal = Number(replaceCommaWithDecimal(ld.original));

                    margin = (1 - (newOriginal/val2)) * 100;
                    margin = margin.toFixed(2);
                    // margin = Number(
                    //     ((val2 - newOriginal) / newOriginal) * 100
                    // ).toFixed(2);
                }

                /**
                 * * PLACING EDIT BY VALUE SO NEXT TIME YOU WON'T BE ABLE TO EDIT
                 * * FOR ANOTHER FIELDS
                 */
                // ld.editedWith = name;
                //* ASSIGNING VALUE TO CHANGE KEY
                // }
                // charge is added to match more item field
                return {
                    ...ld,
                    unit_price: val2,
                    price,
                    margin,
                    charge: price
                };
            }
            if (name === "margin") {
                // console.log(name, ' === margin =>', val);
                //* IF MARGIN IS CHANGE COMPUTION OF UNIT PRICE
                const original = Number(replaceCommaWithDecimal(ld.original));
                // const unit_price = (
                //     (Number(val) * original) / 100 +
                //     original
                // ).toFixed(2);

                const unit_price = (original / (1 - Number(val) / 100)).toFixed(2);
                // ld.unit_price = Number((((val * ld.original)/100) + ld.original)).toFixed(2);
                //* COMPUTING CHANGE IN ROW TOTAL PRICE
                const price = Number(ld.qty) * Number(unit_price);
                /**
                 * * PLACING EDIT BY VALUE SO NEXT TIME YOU WON'T BE ABLE TO EDIT
                 * * FOR ANOTHER FIELDS
                 */
                // ld.editedWith = name;
                //* ASSIGNING VALUE TO CHANGE KEY
                return {
                    ...ld,
                    unit_price,
                    price,
                    margin: val,
                    charge: price
                };
            }
        }
        return ld;
    })|| [];
}

export function ecqSubmit(state: any, localmore: any, total: any, product_no:any) {
    const items: any = [];
    const decoration: any = [];
    /**
     * * PRE ARRANGING DATA FOR REQUEST UPDATE ECQ
     */
    state.map((row: any) => {
        if (row.code) {
            decoration.push({
                attribute_label: row.attribute_label,
                code: row.code,
                price: row.price,
                qty: row.qty,
                unit_price: row.unit_price,
                original: row.original,
                margin: row.margin,
            });
        } else {
            items.push({
                attribute_label: row.attribute_label,
                sku: row.sku,
                price: row.price,
                qty: row.qty,
                unit_price: row.unit_price,
                color: { ...row.color },
                original: row.original,
                margin: row.margin,
            });
        }
        return null;
    });
    // const newTotal = total.map((tot: any) => {
    //     if (tot.code === "total_excl_vat") {
    //         tot.charge = requesTot;
    //     }
    //     return tot;
    // });
    return { more_total: localmore, total, items, decoration, product_no } || {};
}

/**
 * * Number display with decimal
 * @param value numberical value
 * @param decimal number of decimal display
 * @returns number with given decimal number
 */
export function decimalPlacing(value: any, decimal: number = 2) {
    return Number(value).toFixed(decimal);
}

/**
 * * Placing currency symbol in value
 * @param value to be manuplated
 * @param cur currency symbol
 * @returns new value with currency symbol
 */
export function placeCurrency(value: any, cur: any) {
    return cur.replace("%s", value);
}

/**
 * * Replace decimal with comma
 * @param value float value
 * @returns decimal replacw with comma value
 */
export function replaceDecimalWithComma(value: any) {
    const val = value.toString();
    // console.log(typeof val, val);
    return val.replace(".", ",");
}

/**
 * * Replacw comma with decimal
 * @param value string with comma value
 * @returns comma replace with decimal value
 */
export function replaceCommaWithDecimal(value: any) {
    // console.log(' -- - - -- ', value);
    const val = value.toString();
    return Number(val.replace(",", "."));
}

export function decimalAndCurrency(value: any, cur: any) {
    const val = replaceCommaWithDecimal(value.toString());
    return placeCurrency(replaceDecimalWithComma(decimalPlacing(val)), cur);
}
